/** @jsxImportSource @emotion/react */
import React, { useContext, useState } from "react";
import tw from "twin.macro";
import {
  Form,
  FormStateValues,
  Page,
  PageActions,
  PageTitle,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import {
  aletheiaApi,
  DataPoolId,
  TimeSeriesBackingResourceType,
} from "data/Aletheia";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { Path } from "Routes";
import { AuthorityContext, EnodiaOrgContext } from "App";
import { OrgId } from "data/Enodia";
import { ADMIN_AUTHORITY_ID } from "../helpers";
import { AdminFormDetails } from "./AdminFormDetails";
import { DataPoolFormDetails } from "./DataPoolFormDetails";
import { CreateOrEditDataPoolFormValues, FormMode } from "./common";

export const CreateDataPool = () => {
  const navigate = useNavigate();
  const { orgId } = useContext(EnodiaOrgContext);
  const { assignedAuthorityId } = useContext(AuthorityContext);
  const [uiStatus, setUiStatus] = useState(new UIStatus());
  const [formState, setFormState] =
    useState<FormStateValues<CreateOrEditDataPoolFormValues>>();

  const handleSubmit = (formValues: CreateOrEditDataPoolFormValues) => {
    const userRequest = {
      id: formValues.id as DataPoolId,
      organisationId: orgId as OrgId,
      name: formValues.name ? formValues.name.trim() : undefined,
      description: formValues.description,
      license: formValues.licenseURL,
      timeseriesImmutable: formValues.timeseriesImmutable,
    };
    const adminRequest = {
      ...userRequest,
      timeseriesTracking: {
        backingResourceId: formValues.backingResourceId,
        backingResourceType: formValues.backingResourceType,
      },
    };
    setUiStatus((p) => p.setIndeterminate(true));

    const determineRequest = () => {
      if (assignedAuthorityId && assignedAuthorityId === ADMIN_AUTHORITY_ID) {
        if (
          formValues.backingResourceId !== "" &&
          formValues.backingResourceId !== undefined
        ) {
          return adminRequest;
        }
        // exclude timeseries tracking if backing resource is not set when submitted
        return userRequest;
      }
      return userRequest;
    };

    aletheiaApi
      .postDatapool({ discover_ts: formValues.syncTimeseries })(
        determineRequest(),
      )
      .then((res) => {
        setUiStatus((p) => p.setSuccessful(true));
        navigate(
          generatePath(Path.ViewDataPool, {
            orgId: orgId as string,
            id: res.id,
          }),
        );
      })
      .catch((e) => setUiStatus((p) => p.setError(e.message)));
  };

  return (
    <Page hasGoBack backTo="Data Pools" backPath={Path.DataPools}>
      <PageTitle
        primaryHeader="Data Pools"
        pageAction={PageActions.Create}
        heroText={
          <span>
            Create a new Data Pool to represent your organisation’s timeseries
            data stored in DCH. Use this interface if you have data already
            ingested into DCH and require it to be made accessible as Points
            within DCH. <br />
            If you require instead to connect your data to DCH,{" "}
            <Link to={Path.NewDataSource}>create a New Data Source</Link>.
          </span>
        }
      />
      <UIStatusWrapper status={uiStatus}>
        <div css={tw`rounded bg-core-grey-dark p-4`}>
          <Form<CreateOrEditDataPoolFormValues>
            onSubmit={handleSubmit}
            formId="CreateDataSource"
            setFormState={setFormState}
            defaultValues={{
              timeseriesImmutable: false,
              backingResourceType: TimeSeriesBackingResourceType.Senaps,
            }}
          >
            <DataPoolFormDetails formMode={FormMode.create} />
            {assignedAuthorityId &&
              assignedAuthorityId === ADMIN_AUTHORITY_ID && (
                <AdminFormDetails formMode={FormMode.create} />
              )}
            <div>
              <Form.Button
                submit
                label="Save Data Pool"
                icon="save"
                disabled={!formState?.isDirty || !formState?.isValid}
                loading={uiStatus.indeterminate}
              />
              <Form.Button
                submit={false}
                onClick={() => navigate(`/datapool`)}
                icon="close"
                label={"Cancel"}
              />
            </div>
          </Form>
        </div>
      </UIStatusWrapper>
    </Page>
  );
};
