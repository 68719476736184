import { chronosEnv } from "reducers/env";
import { ClientEnv } from "data/utils";
import * as O from "fp-ts/lib/Option";
import {
  DownloadPostParams,
  DownloadPostRequest,
  DownloadPostResponse,
  UploadPostRequest,
} from "./chronosTypes";
import { httpPost } from "data/httpUtil";

/**
 *  Point data API
 * AKA. Chronos
 * @link https://develop.dataclearinghouse.org/api/chronos/v1/swagger#/
 */
type ChronosApi = {
  postDownload: (
    params: DownloadPostParams,
  ) => (request: DownloadPostRequest) => Promise<DownloadPostResponse | null>;
  postDownloadCsv: (
    params: DownloadPostParams,
  ) => (request: DownloadPostRequest) => Promise<string>;
  postUpload: (params: UploadPostRequest) => Promise<void>;
};

const mkChronosApi = (env: ClientEnv): ChronosApi => ({
  postDownload: (params) => (request) =>
    httpPost(request)(O.none)(env)(params)("/observations/download").then(
      (r) => r as DownloadPostResponse | null,
    ),
  postDownloadCsv: (params) => (request) =>
    httpPost(request)(O.none)({
      ...env,
      headers: { ...env.headers, Accept: "text/csv" },
    })(params)("/observations/download").then((r) => r as string),
  postUpload: (request) =>
    httpPost(request)(O.none)(env)({})("/observations/upload").then(
      (_) => undefined,
    ),
});

export const chronosApi = mkChronosApi(chronosEnv);
