/** @jsxImportSource @emotion/react */

import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import tw from "twin.macro";
import { DownloadPostResponseMetadata, ObservationData } from "data/Chronos";
import { UIStatus, UIStatusWrapper } from "components/shared";
import { Chart, fetchPointsData, PointData } from ".";
import { ChartData, DateRange, Datum } from "./ChartUtils";

const lookupPointName = (points: PointData[], pointId: string) => {
  const point = points.find((p) => p.uid === pointId);
  return point?.id || pointId;
};

const handleNumericData = (
  pointName: string,
  pointData: ObservationData[],
  pointMetadata: DownloadPostResponseMetadata,
) => ({
  name: pointName,
  data: pointData.map(
    (d: ObservationData) => ({ x: new Date(d.t), y: d.n }) as Datum<number>,
  ),
  metadata: pointMetadata,
});

const handleVectorData = (
  pointName: string,
  pointData: ObservationData[],
  pointMetadata: DownloadPostResponseMetadata,
) =>
  // DCH-4585: Assume vectors are always the same length
  Array(pointData[0].v!!.length)
    .fill(0)
    .map((_, idx) => ({
      name: `${pointName} (${idx.toString()})`,
      data: pointData.map(
        (d: ObservationData) =>
          ({ x: new Date(d.t), y: d.v!![idx] }) as Datum<number>,
      ),
      metadata: pointMetadata,
    }));

export const getDataForRange =
  (
    points: PointData[],
    setUIStatus?: React.Dispatch<React.SetStateAction<UIStatus>>,
  ) =>
  (limit: number) =>
  (start?: Date, end?: Date): Promise<ChartData<number>> => {
    return fetchPointsData(points, {
      start: start ? start.toISOString() : undefined,
      end: end ? end.toISOString() : undefined,
      limit,
    }).then(
      (r) =>
        r === null
          ? []
          : Object.entries(r?.metadata.points)
              .map(([pointEnum, pointId]) => {
                const pointData = r.data
                  .reverse()
                  .filter((d: ObservationData) => d.p === pointEnum);
                const pointName = lookupPointName(points, pointId);
                if (pointData.some((d: ObservationData) => d.n !== undefined)) {
                  return handleNumericData(pointName, pointData, r.metadata);
                } else if (
                  pointData.some((d: ObservationData) => d.v !== undefined)
                ) {
                  return handleVectorData(pointName, pointData, r.metadata);
                } else {
                  return [];
                }
              })
              .flatMap((d) => d),
      (error) => {
        setUIStatus &&
          setUIStatus((prevStatus) => prevStatus.setError(error.message));
        throw new Error(error);
      },
    );
  };

export type PointChartProps = {
  points?: PointData[];
  mountNodeId?: string;
  debouncedLimit?: number;
  setDebouncedLimit?: Dispatch<SetStateAction<number>>;
  setParentDateRange?: Dispatch<SetStateAction<DateRange | undefined>>;
};

/**
 * "streamIds" will trigger backend requests to get streams.
 * If streams' data are already retrieved, use "streams" instead.
 *
 * Limitation: when using this component in a semantic-ui Modal, do not use `dimmer="blurring"`
 * since it will blur the calendar popup. The calendar popup (created by DateTimeInput from https://www.npmjs.com/package/semantic-ui-calendar-react)
 * is placed inside a div tag with no classes to target custom CSS rules and is outside our control.
 *
 * @param props
 * @constructor
 */
export const PointChart: React.FunctionComponent<PointChartProps> = ({
  points = [],
  mountNodeId,
  debouncedLimit,
  setDebouncedLimit,
  setParentDateRange,
}) => {
  const [status, setStatus] = useState<UIStatus>(new UIStatus());
  const [pointData, setPointData] = useState<PointData[]>([]);

  useEffect(() => {
    // DCH-4585: Only update pointData if points have actually changed.
    // Can't just compare the arrays as this will compare the objects themselves
    if (!pointData.every((element, index) => element === points[index])) {
      setPointData(points);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [points]);

  const memoizedChart = useMemo(
    () => {
      const divId = points.reduce((sid, a) => `${a?.uid}_${sid}`, "chart");
      return (
        <Chart
          id={divId}
          css={tw`h-400`}
          getDataForRange={getDataForRange(points, setStatus)}
          mountNodeId={mountNodeId}
          setDebouncedLimit={setDebouncedLimit}
          setParentDateRange={setParentDateRange}
        />
      );
    },
    // to avoid unnecessary reload when comparing raw arrays
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pointData, debouncedLimit],
  );

  return (
    <UIStatusWrapper
      status={status}
      errorRenderer={(text) =>
        text
          .split("\n")
          .map((s: string, i: number) => <p key={`error-${i}`}>{s}</p>)
      }
      clearable={false}
    >
      {memoizedChart}
    </UIStatusWrapper>
  );
};
