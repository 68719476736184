import React from "react";
import { DataSourceType } from "data/Aletheia";
import { Button, Icon } from "semantic-ui-react";

// default decoder is BMS json
export const DEFAULT_DECODER_ID = "c3656769-d55a-4abf-bbef-4fbe2e61b643";
export const DEFAULT_TRANSPORT_CONFIG_TYPE = DataSourceType.senaps_mqtt_client;

export const displayType = (type: DataSourceType | string) => {
  switch (type) {
    case DataSourceType.senaps_mqtt_client:
      return "MQTT Client";

    case DataSourceType.dch_rest_api:
      return "DCH Rest Api";
    default:
      return type;
  }
};

export const InlineCopyButton = ({
  onClick,
  isCopied,
}: {
  onClick: () => void;
  isCopied: boolean;
}) => {
  return (
    <Button basic inverted size="mini" onClick={onClick}>
      {isCopied ? (
        <>
          <Icon name="check" />
          Copied
        </>
      ) : (
        "Copy"
      )}
    </Button>
  );
};
