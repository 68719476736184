/** @jsxImportSource @emotion/react */
import { useMemo, useState } from "react";
import tw from "twin.macro";
import { Button, Icon, Image, Loader, Modal } from "semantic-ui-react";
import { ApiError } from "data/http";
import {
  FileInputProps,
  getLabelProps,
  LabelledInput,
  UIStatus,
} from "components/shared";

export const ImageViewer = ({
  imageSources,
  isReadOnly,
  handleSubmit,
  handleDelete,
  loading,
  maxImages = 0,
  onSuccess,
}: {
  imageSources: string[];
  isReadOnly: boolean;
  handleSubmit?: (_: File) => Promise<void>;
  handleDelete?: (_: number) => void;
  loading?: boolean;
  maxImages?: number;
  onSuccess: () => void;
}) => {
  const [fullscreenImageIndex, setFullscreenImageIndex] = useState<
    number | undefined
  >();
  return (
    <>
      <Modal
        open={fullscreenImageIndex !== undefined}
        header={undefined}
        onClose={() => {
          setFullscreenImageIndex(undefined);
        }}
        dimmer="blurring"
        closeIcon
        style={{
          width: "95vw",
          maxHeight: "86vh",
          containerType: "inline-size",
        }}
        data-test-id="fullscreen-image"
      >
        <Modal.Content style={{ paddingBottom: ".5rem" }}>
          {fullscreenImageIndex !== undefined ? (
            <Image
              src={imageSources[fullscreenImageIndex]}
              style={{ maxWidth: "90cqw", maxHeight: "calc(80cqh - 5rem)" }}
              centered
              verticalAlign="middle"
            />
          ) : (
            <></>
          )}
        </Modal.Content>
        <Modal.Actions>
          {!isReadOnly && (
            <Button
              basic
              inverted
              onClick={() => {
                fullscreenImageIndex !== undefined &&
                  handleDelete?.(fullscreenImageIndex);
                setFullscreenImageIndex(undefined);
              }}
            >
              <Icon name="trash" />
              <span>Delete Image</span>
            </Button>
          )}
          <Button
            basic
            inverted
            onClick={() => setFullscreenImageIndex(undefined)}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
      <div
        css={tw`flex flex-wrap w-full min-h-20`}
        className="flex-cards"
        data-test-id="image-viewer"
      >
        {!isReadOnly && !loading && (
          <div className="flex-width-card" css={tw`p-2`}>
            {maxImages <= imageSources.length ? (
              <div
                css={tw`flex items-center text-center h-60 border border-dashed border-white rounded`}
              >
                {`Maximum number of images (${maxImages}) has been reached. Delete unwanted images to add more.`}
              </div>
            ) : (
              <CustomImageUpload
                handleSubmit={handleSubmit}
                onSuccess={onSuccess}
              />
            )}
          </div>
        )}
        {imageSources.map((image, index) => (
          <ThumbnailImage
            key={`${image}-card`}
            imageSources={imageSources}
            imageIndex={index}
            setFullscreenImageIndex={setFullscreenImageIndex}
          />
        ))}
      </div>
    </>
  );
};

const ThumbnailImage = ({
  imageSources,
  imageIndex,
  setFullscreenImageIndex,
}: {
  imageSources: string[];
  imageIndex: number;
  setFullscreenImageIndex: (_: number | undefined) => void;
}) => {
  return (
    <div className="flex-width-card" css={tw`p-2`}>
      <div
        css={tw`h-60 bg-black rounded relative overflow-hidden cursor-pointer hover:opacity-40`}
        onClick={() => setFullscreenImageIndex(imageIndex)}
      >
        <img
          alt="screenshot thumbnail"
          src={imageSources[imageIndex]}
          css={tw`h-60 absolute left-1/2 top-1/2`}
          style={{ transform: "translate(-50%, -50%)" }}
        />
      </div>
    </div>
  );
};

const validateFileType = (fileName: string, accept: string) => {
  const splitFileName = fileName.split(".");
  const fileExtension = splitFileName[splitFileName.length - 1];
  return accept.includes(`.${fileExtension}`);
};

const CustomImageUpload = ({
  handleSubmit,
  onSuccess,
  ...inputProps
}: FileInputProps & {
  handleSubmit?: (_: File) => Promise<void>;
  onSuccess: () => void;
}) => {
  const acceptedFileTypes = ".png, .jpeg, .jpg";
  const [dragActive, setDragActive] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<File | undefined>();
  const [uiStatus, setUiStatus] = useState(new UIStatus());

  const processDrop = (event: React.DragEvent) => {
    let items = event.dataTransfer.files;
    if (validateFileType(items[0].name, acceptedFileTypes)) {
      setFileToUpload(items[0]);
      setUiStatus((prev) => prev.setIndeterminate(false));
    } else {
      setUiStatus((prev) => prev.setError("This file type is not supported."));
      setFileToUpload(undefined);
    }
  };

  useMemo(() => {
    if (fileToUpload) {
      if (validateFileType(fileToUpload.name, acceptedFileTypes)) {
        setUiStatus((prev) => prev.setIndeterminate(true));
        if (handleSubmit) {
          handleSubmit(fileToUpload).then(
            () => {
              setUiStatus((prev) => prev.setSuccessful(true));
              setFileToUpload(undefined);
              onSuccess?.();
            },
            (error: ApiError) => {
              setUiStatus((prev) =>
                prev.setError(
                  error.message ?? "This file could not be uploaded",
                ),
              );
              setFileToUpload(undefined);
            },
          );
        }
      } else {
        setUiStatus((prev) =>
          prev.setError("This file type is not supported."),
        );
        setFileToUpload(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileToUpload]);

  const labelProps = getLabelProps(inputProps);
  const input = (
    <div
      css={tw`flex flex-col gap-4 justify-center items-center text-center h-60 border border-dashed border-primary rounded`}
    >
      {uiStatus.indeterminate ? (
        <Loader active inline content="Uploading image..." />
      ) : (
        <div
          css={dragActive && tw`bg-primary opacity-75`}
          onDrop={(e: React.DragEvent) => {
            e.preventDefault();
            setDragActive(false);
            processDrop(e);
          }}
          onDragOver={(e: React.DragEvent) => {
            e.preventDefault();
            setDragActive(true);
          }}
        >
          <div css={tw`flex flex-col items-center mb-4`}>
            <label
              htmlFor="image_upload"
              css={tw`border border-solid border-white rounded px-4 py-2 cursor-pointer`}
            >
              Choose image to upload
            </label>
            <input
              id="image_upload"
              type="file"
              accept={acceptedFileTypes}
              css={tw`opacity-0 h-0`}
              onChange={(e) => {
                const selectedFile = e.target.files?.[0];
                if (
                  validateFileType(selectedFile?.name ?? "", acceptedFileTypes)
                )
                  setFileToUpload(selectedFile);
                else {
                  setUiStatus((prev) =>
                    prev.setError("This file type is not supported."),
                  );
                }
              }}
            />
            <span css={tw`text-red-error h-4 pb-4 pt-1`}>
              {uiStatus.error
                ? (uiStatus.error?.toString() ?? "This file cannot be uploaded")
                : null}
            </span>
          </div>
          <Icon name="upload" size="huge" />
          <div css={tw`mt-1`}>or drag file to this window</div>
        </div>
      )}
    </div>
  );
  return inputProps.label ? (
    <LabelledInput {...labelProps} input={input} />
  ) : (
    input
  );
};
