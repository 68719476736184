/** @jsxImportSource @emotion/react */
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Divider, Icon, Loader } from "semantic-ui-react";
import tw from "twin.macro";
import { Path } from "Routes";
import { ApiError } from "data/http";
import {
  ApplicationId,
  ApplicationRecipeDetailResponse,
  ApplicationVersionFullResponse,
  metisApi,
} from "data/Metis";
import { useApplicationCategories } from "data/Metis/useApplicationCategories";
import {
  DchModal,
  Page,
  PageTitle,
  PrimaryTitle,
  UIStatus,
  UIStatusWrapper,
  Header,
  Tabs,
} from "components/shared";
import {
  ApplicationImages,
  getIconByCategory,
  getLatestApplicationVersion,
} from "components/Applications/shared";
import { PublishStateLabel } from "../PublishStateLabel";
import { ApplicationConfiguration } from "./ApplicationConfiguration";
import { ApplicationDetails } from "./ApplicationDetails";
import { ApplicationVersions } from "./ApplicationVersions";

type Params = { applicationId: string };

enum AppDetailsTab {
  Details = "details",
  Versions = "versions",
  Configuration = "configuration",
  Images = "images",
}

export const ManageApplication = () => {
  const params = useParams<keyof Params>() as Params;
  const location = useLocation();
  const applicationId = params.applicationId as ApplicationId;

  const [app, setApp] = useState<ApplicationRecipeDetailResponse>();
  const [appVersions, setAppVersions] = useState<
    ApplicationVersionFullResponse[]
  >([]);
  const [selectedTab, setSelectedTab] = useState<AppDetailsTab>(
    AppDetailsTab.Details,
  );
  const [uiStatus, setUiStatus] = useState(new UIStatus());

  //on load, fetch application and version details
  useEffect(() => {
    const handleError = (error: ApiError) => {
      setUiStatus((prev) =>
        prev.setError(
          error.message ??
            "An error occured retrieving the application details. Please try again.",
        ),
      );
    };

    setUiStatus((prev) => prev.setIndeterminate(true));
    metisApi.getManageApplicationRecipe(applicationId).then(
      (app) => {
        metisApi
          .getManageApplicationRecipeVersions(applicationId)
          .then(
            (versions) => setAppVersions(versions),
            (error) => handleError(error),
          )
          .finally(() => {
            setApp(app);
            setUiStatus((prev) => prev.setIndeterminate(false));
          });
      },
      (error) => handleError(error),
    );
  }, [applicationId]);

  return (
    <Page hasGoBack backTo={location.state?.["backTo"]}>
      <PageTitle primaryTitle={PrimaryTitle.ManageApps} />
      <UIStatusWrapper status={uiStatus}>
        {app && (
          <div css={tw`flex flex-col gap-4 bg-core-grey-dark rounded p-4`}>
            <ApplicationHeader app={app} setUiStatus={setUiStatus} />
            <Divider />
            <Tabs<AppDetailsTab>
              tabs={Object.values(AppDetailsTab)}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            <ApplicationInformation
              app={app}
              appVersions={appVersions}
              setAppVersions={setAppVersions}
              selectedTab={selectedTab}
              setUiStatus={setUiStatus}
            />
          </div>
        )}
      </UIStatusWrapper>
    </Page>
  );
};

const ApplicationHeader = ({
  app,
  setUiStatus,
}: {
  app: ApplicationRecipeDetailResponse;
  setUiStatus: Dispatch<SetStateAction<UIStatus>>;
}) => {
  const { getCategoriesByUid } = useApplicationCategories();
  const iconSvg = getIconByCategory(getCategoriesByUid(app.categoryUids));

  const [publishStatus, setPublishStatus] = useState(new UIStatus());

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(new UIStatus());

  const navigate = useNavigate();

  const handlePublish = () => {
    setPublishStatus((prev) => prev.setIndeterminate(true));
    metisApi.publishApplication(app.id).then(
      () => {
        setPublishStatus((prev) => prev.setSuccessful(true));
      },
      (error: ApiError) => {
        setUiStatus((prev) =>
          prev.setError(
            error.message ??
              "There was an error publishing this application. Please try again.",
          ),
        );
      },
    );
  };
  const handleDelete = () => {
    setDeleteStatus((prev) => prev.setIndeterminate(true));
    metisApi.deleteApplicationRecipe(app.id).then(
      () => {
        setDeleteStatus((prev) => prev.setSuccessful(true));
        setDeleteModalOpen(false);
        navigate(Path.ManageApplications);
      },
      (error: ApiError) => {
        setUiStatus((prev) =>
          prev.setError(
            error.message ??
              "There was an error deleting this application. Please try again.",
          ),
        );
      },
    );
  };

  return (
    <>
      <DchModal
        open={deleteModalOpen}
        content={
          <UIStatusWrapper status={deleteStatus}>
            Are you sure you want to delete the application{" "}
            <strong>{app.name}</strong>?
          </UIStatusWrapper>
        }
        header="Confirm Delete"
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={() => handleDelete()}
        confirmText="Delete Application"
      />
      <Header
        name={app.name}
        icon={iconSvg}
        subHeader={
          <div css={tw`mt-2`}>
            <PublishStateLabel
              isPublished={app.published || !!publishStatus.successful}
            />
          </div>
        }
        actions={
          <>
            <Button
              primary
              fluid
              onClick={() => handlePublish()}
              disabled={publishStatus.indeterminate}
            >
              <span css={tw`flex gap-2 items-center`}>
                <Icon name="play" />
                {publishStatus.indeterminate
                  ? "Publishing"
                  : "Publish Application"}
                {publishStatus.indeterminate && (
                  <span css={tw`pl-4`}>
                    <Loader active inline size="tiny" />
                  </span>
                )}
              </span>
            </Button>
            <Button
              basic
              inverted
              fluid
              onClick={() => setDeleteModalOpen(true)}
            >
              <span css={tw`flex gap-2 items-center`}>
                <Icon name="trash" />
                {deleteStatus.indeterminate ? "Deleting" : "Delete Application"}
                {deleteStatus.indeterminate && (
                  <span css={tw`pl-4`}>
                    <Loader active inline size="tiny" />
                  </span>
                )}
              </span>
            </Button>
          </>
        }
      />
    </>
  );
};

const ApplicationInformation = ({
  app,
  selectedTab,
  appVersions,
  setAppVersions,
  setUiStatus,
}: {
  app: ApplicationRecipeDetailResponse;
  appVersions: ApplicationVersionFullResponse[];
  setAppVersions: Dispatch<SetStateAction<ApplicationVersionFullResponse[]>>;
  selectedTab: AppDetailsTab;
  setUiStatus: Dispatch<SetStateAction<UIStatus>>;
}) => {
  const latestVersion = getLatestApplicationVersion(
    appVersions,
  ) as ApplicationVersionFullResponse;
  switch (selectedTab) {
    case AppDetailsTab.Details:
      return <ApplicationDetails application={app} appVersions={appVersions} />;
    case AppDetailsTab.Versions:
      return <ApplicationVersions appVersions={appVersions} />;
    case AppDetailsTab.Configuration:
      return (
        <ApplicationConfiguration
          appVersion={latestVersion}
          setAppVersion={(newVersionData: ApplicationVersionFullResponse) =>
            setAppVersions((prev) => [
              ...prev.filter((v) => v.uid !== latestVersion.uid),
              newVersionData,
            ])
          }
          setUiStatus={setUiStatus}
        />
      );
    case AppDetailsTab.Images:
      return (
        <ApplicationImages
          appId={app.id}
          screenshotCount={app.screenshotCount}
          isReadOnly={false}
        />
      );
  }
};
