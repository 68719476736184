/** @jsxImportSource @emotion/react */

import tw from "twin.macro";
import React from "react";

export type InputValidationProps = {
  invalid?: boolean;
  errorMessage?: string;
  testId?: string;
};

export const InputValidation = (validationResult?: InputValidationProps) => {
  return (
    <span
      css={tw`text-red-error block h-0 text-left mb-2`}
      data-test-id={`${validationResult?.testId}-validation`}
    >
      {validationResult?.invalid ? validationResult?.errorMessage : null}
    </span>
  );
};

export type CharacterLimitProps = {
  testId?: string;
  currentLength?: number;
  maxLength?: number;
};

export const CharacterLimit = (props: CharacterLimitProps) => {
  if (props.maxLength !== undefined && props.currentLength !== undefined) {
    const remainder = props.maxLength - props.currentLength;

    return (
      <span
        css={tw`block h-0 text-left mb-2 italic`}
        data-test-id={`${props?.testId}-text-limit`}
      >
        {remainder} characters remaining
      </span>
    );
  } else {
    return null;
  }
};
