/** @jsxImportSource @emotion/react */

import React, { useCallback, useContext, useEffect, useState } from "react";
import { List } from "semantic-ui-react";
import tw from "twin.macro";
import { EnodiaOrgContext } from "App";
import { Path } from "Routes";
import { SitesAndBuildingsResponse, masonApi } from "data/Mason";
import {
  DOCUMENTATION_URL,
  externalLinkTo,
  Page,
  PageTitle,
  quickMenuItem,
  UIStatus,
} from "components/shared";
import {
  SiteModel,
  createSingleOrgModel,
} from "components/SitesAndBuildings/Common";
import SitesAndBuildingsList from "components/SitesAndBuildings/SitesAndBuildingsList";
import { LearningHelp } from "./LearningHelp";
import { OrganisationResponse, OrgId } from "data/Enodia";
import { generatePath, Link, useNavigate, useParams } from "react-router-dom";

type CenterBordersProps = React.PropsWithChildren<{ heading: string }>;
const CenterBorders: React.FC<CenterBordersProps> = (props) => (
  <div css={tw`border border-solid border-white rounded m-2 w-1/3`}>
    <h3 css={tw`pl-4 pt-4`}>{props.heading}</h3>
    {props.children}
  </div>
);

const CustomerHome: React.FC = () => {
  const { orgId, setOrgId, orgList } = useContext(EnodiaOrgContext);
  const params = useParams();
  const navigate = useNavigate();

  const [uiStatus, setUiStatus] = useState(new UIStatus());

  const [cache, setCache] = useState(new Map<OrgId, SiteModel[]>());

  //flag is needed to trigger the useEffect state action once
  const [isFirstOrgChangeTriggered, setIsFirstOrgChangeTriggered] =
    useState(false);
  const [isOrgValid, setIsOrgValid] = useState(true);

  const handleOrgIdFromParam = useCallback(
    (orgList: OrganisationResponse[]) => {
      //check if param's orgId is valid
      if (orgList.find((org) => org.id === params.orgId)) {
        setOrgId(params.orgId as OrgId);
        setIsFirstOrgChangeTriggered(true);
      } else {
        setIsOrgValid(false);
      }
      // handle orgID changes subsequently after first load
    },
    [params.orgId, setOrgId],
  );

  // on first load, add param to base /home route
  useEffect(() => {
    if (!params.orgId && orgId) {
      setIsFirstOrgChangeTriggered(true);
      navigate(
        generatePath(Path.HomeOrgContext, {
          orgId: orgId as string,
        }),
      );
    }
  }, [navigate, orgId, params.orgId]);

  // once off org change to URL context when the /home first renders
  // runs for Path.HomeOrgContext
  useEffect(() => {
    if (params.orgId && orgId && (orgId as string) !== params.orgId) {
      // handle on first load with orgId in param
      if (!isFirstOrgChangeTriggered && orgList) {
        handleOrgIdFromParam(orgList);
        // handle orgID changes subsequently after first load
      } else if (isFirstOrgChangeTriggered) {
        navigate(
          generatePath(Path.HomeOrgContext, {
            orgId: orgId as string,
          }),
        );
      }
    } else {
      // if no params, ignore param checks
      setIsOrgValid(true);
    }
  }, [
    handleOrgIdFromParam,
    isFirstOrgChangeTriggered,
    navigate,
    orgId,
    orgList,
    params.orgId,
    setOrgId,
  ]);

  //once we have an orgId, or if the orgId changes, fetch the sites and buildings
  useEffect(() => {
    if (orgId) {
      const cachedOrgSitesAndBuildings = cache.get(orgId);
      if (!cachedOrgSitesAndBuildings) {
        setUiStatus((prev) => prev.setIndeterminate(true));
        masonApi.getSitesAndBuildings({ orgId }).then(
          (res: SitesAndBuildingsResponse[]) => {
            const orgModel = createSingleOrgModel(res, orgId);
            setCache((prev) => prev.set(orgId, orgModel.sites));
            setUiStatus((prev) => prev.setIndeterminate(false));
          },
          (e) => {
            setUiStatus((prev) =>
              prev.setError("Unable to fetch sites. Please try again later."),
            );
          },
        );
      }
    }
  }, [cache, orgId]);

  const sitesBuildingsList = () => {
    if (orgId) {
      const cachedOrgSitesAndBuildings = cache.get(orgId);
      return cachedOrgSitesAndBuildings ?? [];
    }
    return [];
  };

  return isOrgValid ? (
    <Page>
      <div css={tw`flex flex-row h-full`}>
        <div
          css={tw`border border-solid border-white rounded m-2 w-1/3 px-4 flex flex-col`}
        >
          <h3 css={tw`pt-4`}>Sites and Buildings</h3>
          <SitesAndBuildingsList
            sitesUiStatus={uiStatus}
            orgId={orgId}
            sitesAndBuildingsList={sitesBuildingsList()}
          />
        </div>
        <CenterBorders heading="Learning and Help">
          <ul css={tw`list-none p-0 m-0`}>
            <LearningHelp />
            <h3 css={tw`pl-4 pt-4`}>Documentation</h3>
            <List>
              <List.Item css={tw`mx-4`}>
                {externalLinkTo(
                  DOCUMENTATION_URL,
                  "Open User Guide",
                  tw`w-full`,
                )}
              </List.Item>
            </List>
          </ul>
        </CenterBorders>
        <CenterBorders heading="Quick Menu">
          <List>
            {quickMenuItem(Path.NewSite, "Add New Site")}
            {quickMenuItem(Path.NewBuilding, "Add New Building")}
            {quickMenuItem(Path.NewDataSource, "Add New Data Source")}
          </List>
        </CenterBorders>
      </div>
    </Page>
  ) : (
    <Page>
      <PageTitle
        primaryHeader={`${params.orgId} Organisation Not Found`}
        subHeader={`Sorry, ${params.orgId} organisation cannot be found`}
      />
      <p>
        <Link to={Path.Home}>
          <u>Back to Home</u>
        </Link>
      </p>
    </Page>
  );
};

export default CustomerHome;
