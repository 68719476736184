/** @jsxImportSource @emotion/react */
import * as React from "react";
import tw from "twin.macro";
import { Link, useNavigate } from "react-router-dom";
import { Divider } from "semantic-ui-react";
import PageFooter from "./PageFooter";
import { PageHeader } from "./PageHeader";

type Props = React.PropsWithChildren<{
  hasGoBack?: boolean;
  backTo?: string;
  backPath?: string;
}>;

export const Page: React.FC<Props> = (props) => {
  // When the component is first mounted, scroll to the top of the page
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const renderBackButton = () => {
    if (props.hasGoBack) {
      if (props.backPath !== undefined) {
        return (
          <Link to={props.backPath}>
            {`< Back ${props.backTo ? "to " + props.backTo : ""}`}
          </Link>
        );
      } else {
        return (
          <Link
            to="/#"
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
          >
            {`< Back ${props.backTo ? "to " + props.backTo : ""}`}
          </Link>
        );
      }
    }
    return null;
  };

  return (
    <div
      css={tw`overflow-y-auto flex flex-col`}
      style={{ height: "calc(100vh - 2rem)" }}
    >
      <PageHeader />
      <Divider fitted inverted />
      <div css={tw`p-4 grow`}>
        {renderBackButton()}
        {props.children}
      </div>
      <PageFooter />
    </div>
  );
};
