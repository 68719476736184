import {
  chronosApi,
  DownloadPostParams,
  DownloadPostResponse,
  SortOrder,
} from "data/Chronos";

export type PointData = {
  id: string;
  uid: string;
  name?: string;
};

// assume 5min samples, that's 2016 for a week of data
export const DEFAULT_AGG_RESULTS_LIMIT = 2000; // rounded to nearest 1000
export const AGG_LIMIT_CAP = 100000;
export const DOWNLOAD_ALL_LIMIT_CAP = 1000000;

export const fetchPointsDataCsv = (
  points: PointData[],
  params?: DownloadPostParams,
): Promise<string> => {
  return chronosApi.postDownloadCsv({
    limit: params?.limit ?? DEFAULT_AGG_RESULTS_LIMIT,
    start: params?.start,
    end: params?.end,
    si: true,
    ei: true,
  })({
    points: points.map((p) => p.uid),
  });
};

export const fetchPointsData = (
  points: PointData[],
  params?: DownloadPostParams,
): Promise<DownloadPostResponse | null> => {
  return chronosApi.postDownload({
    limit: params?.limit ?? DEFAULT_AGG_RESULTS_LIMIT,
    start: params?.start,
    end: params?.end,
    sort: SortOrder.DESCENDING,
    si: true,
    ei: true,
  })({
    points: points.map((p) => p.uid),
  });
};
