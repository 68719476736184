/** @jsxImportSource @emotion/react */
import { Grid, Icon } from "semantic-ui-react";
import tw from "twin.macro";
import { BuildingMetaResponse, SiteMetaResponse } from "data/Mason";
import {
  BUILDING_ICON_NAME,
  SITE_ICON_NAME,
} from "components/SitesAndBuildings/SiteOrBuildingIcon";

export const ModelsList = ({
  sites,
  buildings,
}: {
  sites: SiteMetaResponse[];
  buildings: BuildingMetaResponse[];
}) => {
  const siteIds = sites.map((site) => site.siteId);
  const buildingsWithoutInstalledSite = buildings.filter(
    (building) => !siteIds.includes(building.siteId),
  );

  return (
    <>
      {sites.map((site) => {
        return (
          <div key={site.siteId} css={tw`mb-2`}>
            <Icon name={SITE_ICON_NAME} />
            <span css={tw`ml-2`}>
              {site.label && site.label !== "" ? site.label : site.siteId}
            </span>
            <div css={tw`pl-8 pt-2`}>
              <BuildingsList
                buildings={buildings.filter(
                  (building) => building.siteId === site.siteId,
                )}
              />
            </div>
          </div>
        );
      })}
      <BuildingsList buildings={buildingsWithoutInstalledSite} />
    </>
  );
};

const BuildingsList = ({
  buildings,
}: {
  buildings: BuildingMetaResponse[];
}) => {
  return (
    <Grid>
      <Grid.Row columns="equal">
        {buildings.map((building) => (
          <Grid.Column
            largeScreen={5}
            computer={5}
            tablet={8}
            mobile={16}
            key={building.buildingId}
          >
            <div>
              <Icon name={BUILDING_ICON_NAME} />
              <span css={tw`ml-2`}>
                {building.label && building.label !== ""
                  ? building.label
                  : building.buildingId}
              </span>
            </div>
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  );
};
