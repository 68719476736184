import { OrgId } from "data/Enodia";

export interface CreateOrUpdateDataSourceFormValues {
  id: string;
  name?: string;
  description?: string;
  type: string;
  enabled: boolean;
  organisationId: OrgId;
  gatewayUsername: string;
  gatewayPassword: string;
  gatewayConfirmPassword: string;
  decoderType: string;
  decoderConfigText?: string;
}

export enum DataSourceFormMode {
  create,
  edit,
}
