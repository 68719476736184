/** @jsxImportSource @emotion/react */
import React, { useContext } from "react";
import { LinkedModel } from "./DataPool";
import { Grid, Icon } from "semantic-ui-react";
import { generatePath, Link } from "react-router-dom";
import tw from "twin.macro";
import { Path } from "Routes";
import { EnodiaOrgContext } from "App";
import {
  BUILDING_ICON_NAME,
  SITE_ICON_NAME,
} from "components/SitesAndBuildings/SiteOrBuildingIcon";
import { BuildingId } from "data/brick";

export const LinkedModelContent = ({
  linkedModels,
  buildingsLength,
  sitesLength,
}: {
  linkedModels: LinkedModel[];
  buildingsLength: number;
  sitesLength: number;
}) => {
  const { orgId } = useContext(EnodiaOrgContext);
  if (linkedModels.length === 0) return <i>No linked models.</i>;

  return (
    <div css={tw`flex flex-col gap-4`}>
      <div>
        There are{" "}
        <b>
          {sitesLength} Site{sitesLength > 1 ? "s" : ""}
        </b>{" "}
        and <b>{buildingsLength} Building Models</b> referencing points in this
        Data Pool.
      </div>
      {linkedModels.map((r) => (
        <div key={r.siteId} css={tw`mb-2`}>
          <Icon name={SITE_ICON_NAME} />
          {r.isLinked ? (
            <Link
              css={tw`underline ml-2`}
              to={generatePath(Path.ViewSite, {
                orgId: orgId as string,
                siteId: r.siteId,
              })}
            >
              {r.siteId}
            </Link>
          ) : (
            <span css={tw`ml-2`}>{r.siteId} </span>
          )}
          <div css={tw`pl-8 pt-2`}>
            <BuildingsList
              orgId={orgId as string}
              siteId={r.siteId}
              buildingIds={r.buildingIds ?? []}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

//** copy of ./InstalledApplications/Details */
const BuildingsList = ({
  orgId,
  siteId,
  buildingIds,
}: {
  orgId: string;
  siteId: string;
  buildingIds: BuildingId[];
}) => {
  return (
    <Grid>
      <Grid.Row columns="equal">
        {buildingIds.map((id: string) => (
          <Grid.Column
            largeScreen={5}
            computer={5}
            tablet={8}
            mobile={16}
            key={id}
          >
            <div>
              <Icon name={BUILDING_ICON_NAME} />
              <Link
                css={tw`underline ml-2`}
                to={generatePath(Path.ViewBuilding, {
                  orgId: orgId,
                  siteId,
                  buildingId: id,
                })}
              >
                {id}
              </Link>
            </div>
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  );
};
