/** @jsxImportSource @emotion/react */

import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  DataSourceCompositeId,
  DataSourceId,
  DataSourceResponse,
  DataSourceUid,
  aletheiaApi,
} from "data/Aletheia";
import { ApiError } from "data/http";
import { EnodiaOrgContext } from "App";
import { Path } from "Routes";
import OrgContextModal from "context/OrgContextModal";
import {
  Page,
  PageTitle,
  UIStatus,
  UIStatusWrapper,
  redirectMessage,
  createCompositeId,
} from "components/shared";
import Form, { FormStateValues } from "components/shared/Forms/ReactHookForm";
import { DEFAULT_TRANSPORT_CONFIG_TYPE } from "../DataSourceUtils";
import {
  CreateOrUpdateDataSourceFormValues,
  DataSourceFormMode,
} from "./FormHelpers";
import { DataSourceDetails } from "./DetailFormSegment";
import { GatewayDetails } from "./GatewayFormSegment";
import { DataParserFormSegment } from "./ParserFormSegment";

type Params = { orgId: string; id: DataSourceId };

const EditDataSource: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { orgId } = React.useContext(EnodiaOrgContext);
  const params = useParams<Params>();

  const [dataSource, setDataSource] = React.useState<
    DataSourceResponse | undefined
  >();
  const [confirmationModal, setConfirmationModal] = React.useState(false);
  const [uiStatus, setUIStatus] = React.useState(new UIStatus());

  const [formState, setFormState] =
    React.useState<FormStateValues<CreateOrUpdateDataSourceFormValues>>();

  const updateDataSource = (formData: CreateOrUpdateDataSourceFormValues) => {
    if (params.id && dataSource) {
      const updateRequest = {
        name: formData.name ?? undefined,
        description: formData.description ?? undefined,
        enabled: formData.enabled,
        // carry over from existing transport config if no new password has been se
        transportConfig:
          formData.gatewayPassword === ""
            ? dataSource?.transportConfig
            : {
                ...dataSource?.transportConfig,
                password: formData.gatewayPassword,
              },
        // decoder ID is hardcoded for now since we only support one decoder id at the moment
        decoderConfig: {
          decoderId: formData.decoderType,
          configContent:
            formData.decoderConfigText !== undefined
              ? JSON.parse(formData.decoderConfigText)
              : undefined,
        },
      };

      setUIStatus((prevState) => prevState.setIndeterminate(true));
      aletheiaApi
        .putDatasource(dataSource.uid as DataSourceUid)(updateRequest)
        .then(() => {
          setUIStatus((prevState) => prevState.setIndeterminate(false));
          navigate(`/datasource/${dataSource.organisationId}/${params.id}`);
        })
        .catch((e: ApiError) => {
          setUIStatus((prevState) => prevState.setError(e.message));
        });
    }
  };

  React.useEffect(() => {
    if (state) setDataSource(state as DataSourceResponse);
    else if (params.id && orgId && !state) {
      setUIStatus((prevState) => prevState.setIndeterminate(true));
      aletheiaApi
        .getDatasource(
          createCompositeId(orgId, params.id) as DataSourceCompositeId,
        )
        .then((ds) => {
          setDataSource(ds);
          setUIStatus((prevState) => prevState.setIndeterminate(false));
        })
        .catch((e: ApiError) => {
          setUIStatus((prevState) =>
            prevState.setError(
              e.message ??
                "Error fetching data source: No data source with requested ID found",
            ),
          );
        });
    }
  }, [params.id, orgId, state]);

  return (
    <Page>
      <PageTitle primaryHeader="Edit a Data Source" />
      <OrgContextModal
        open={confirmationModal}
        openCondition={params.orgId !== orgId}
        onConfirm={() => {
          navigate(Path.DataSources);
        }}
        modalMessage={redirectMessage}
        setModalState={setConfirmationModal}
      />
      <UIStatusWrapper status={uiStatus}>
        {dataSource && (
          <Form
            onSubmit={(ds: CreateOrUpdateDataSourceFormValues) =>
              updateDataSource(ds)
            }
            formId="EditDataSource"
            setFormState={(formState) => setFormState(formState)}
            defaultValues={{
              id: dataSource.id,
              name: dataSource.name,
              description: dataSource.description,
              enabled: dataSource.enabled,
              type: DEFAULT_TRANSPORT_CONFIG_TYPE,
              gatewayUsername: `${dataSource.dataPoolRef.timeseriesTracking.backingResourceId}:${dataSource.transportConfig.userName}`,
              gatewayPassword: "",
              gatewayConfirmPassword: "",
              decoderType: dataSource.decoderConfig.decoderId,
              decoderConfigText:
                dataSource.decoderConfig.configContent !== null
                  ? JSON.stringify(
                      dataSource.decoderConfig.configContent,
                      null,
                      4,
                    )
                  : "{}",
            }}
          >
            <DataSourceDetails formMode={DataSourceFormMode.edit} />
            <GatewayDetails formMode={DataSourceFormMode.edit} />
            <DataParserFormSegment />
            <Form.Button
              loading={uiStatus.indeterminate}
              disabled={
                uiStatus.indeterminate ||
                !formState?.isValid ||
                (formState?.isValid && !formState?.isDirty)
              }
              label="Update Data Source"
              submit={true}
            />

            <Form.Button
              submit={false}
              onClick={() => navigate(`/datasource/${orgId}/${params.id}`)}
              label={"Cancel"}
            />
          </Form>
        )}
      </UIStatusWrapper>
    </Page>
  );
};

export default EditDataSource;
