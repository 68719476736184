/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { Link, generatePath, useLocation, useParams } from "react-router-dom";
import { Button, Divider, Icon } from "semantic-ui-react";
import tw from "twin.macro";
import { Path } from "Routes";
import { ApiError } from "data/http";
import {
  AvailableApplicationDetails,
  ApplicationVersionBriefResponse,
  metisApi,
  ApplicationUid,
} from "data/Metis";
import {
  Page,
  PageTitle,
  PrimaryTitle,
  UIStatus,
  UIStatusWrapper,
  compareSemanticVersions,
  Header,
  Tabs,
} from "components/shared";
import {
  getIconByCategory,
  sortVersionsByLatest,
} from "components/Applications/shared";
import { InstallApplicationModal } from "..";
import {
  ApplicationConfiguration,
  ApplicationOverview,
  ApplicationReleaseNotes,
  ApplicationSpecifications,
} from "./";

enum AppDetailsTab {
  Overview = "overview",
  Specifications = "specifications",
  ReleaseNotes = "release notes",
  Configuration = "configuration",
}

type Params = { applicationId: string };

export const AvailableApplication = () => {
  const params = useParams<keyof Params>() as Params;
  const location = useLocation();
  const [app, setApp] = useState<AvailableApplicationDetails>();
  const [appVersions, setAppVersions] = useState<
    ApplicationVersionBriefResponse[]
  >([]);
  const [selectedTab, setSelectedTab] = useState(AppDetailsTab.Overview);
  const [installModalOpen, setInstallModalOpen] = useState(false);
  const [uiStatus, setUiStatus] = useState(new UIStatus());

  //on load, fetch app details
  useEffect(() => {
    const handleError = (error: ApiError) => {
      setUiStatus((prev) =>
        prev.setError(
          error.message ??
            "An error occured retrieving the application details. Please try again.",
        ),
      );
    };

    setUiStatus((prev) => prev.setIndeterminate(true));
    metisApi.getApplicationRecipe(params.applicationId).then(
      (res) => {
        setApp(res);
        metisApi
          .getApplicationRecipeVersions(params.applicationId as ApplicationUid)
          .then(
            (versions) => {
              setAppVersions(versions);
              setUiStatus((prev) => prev.setIndeterminate(false));
            },
            (error) => handleError(error),
          );
      },
      (error) => handleError(error),
    );
  }, [params.applicationId]);

  return (
    <Page hasGoBack backTo={location.state?.["backTo"]}>
      <PageTitle primaryTitle={PrimaryTitle.AppMarketplace} />
      <UIStatusWrapper status={uiStatus}>
        {app && (
          <div css={tw`flex flex-col gap-4 bg-core-grey-dark rounded p-4`}>
            <Header
              name={app.name}
              icon={getIconByCategory(app.categories)}
              subHeader={
                <>
                  <span>
                    by{" "}
                    <Link
                      to={generatePath(Path.ViewVendorDetails, {
                        vendorUid: app.vendorUid,
                      })}
                      state={{ backTo: app.name }}
                    >
                      {app.vendorName}
                    </Link>
                  </span>
                  <span css={tw`mt-2`}>{app.description}</span>
                </>
              }
              actions={
                <>
                  <div css={tw`flex gap-2`}>
                    <div>
                      <Icon name="download" />
                      {`${app.installations ?? 0} install(s)`}
                    </div>
                    <div>|</div>
                    <div>
                      {appVersions.length > 0 && `v${appVersions[0].version}`}
                    </div>
                  </div>
                  <Button
                    primary
                    floated="right"
                    onClick={() => {
                      setInstallModalOpen(true);
                    }}
                  >
                    <Icon name="download" />
                    Install
                  </Button>
                </>
              }
            />
            <InstallApplicationModal
              appName={app.name}
              open={installModalOpen}
              onClose={() => setInstallModalOpen(false)}
              appVersions={appVersions.sort((a, b) =>
                compareSemanticVersions(b.version, a.version),
              )}
            />
            <Divider />
            <Tabs<AppDetailsTab>
              tabs={Object.values(AppDetailsTab)}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            <ApplicationDetails
              app={app}
              appVersions={
                sortVersionsByLatest(
                  appVersions,
                ) as ApplicationVersionBriefResponse[]
              }
              selectedTab={selectedTab}
            />
          </div>
        )}
      </UIStatusWrapper>
    </Page>
  );
};

const ApplicationDetails = ({
  app,
  selectedTab,
  appVersions,
}: {
  app: AvailableApplicationDetails;
  appVersions: ApplicationVersionBriefResponse[];
  selectedTab: AppDetailsTab;
}) => {
  switch (selectedTab) {
    case AppDetailsTab.Specifications:
      return <ApplicationSpecifications appVersions={appVersions} />;
    case AppDetailsTab.ReleaseNotes:
      return <ApplicationReleaseNotes appVersions={appVersions} />;
    case AppDetailsTab.Configuration:
      return <ApplicationConfiguration appVersions={appVersions} />;
    default:
      return <ApplicationOverview app={app} />;
  }
};
