/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { format, getMonth, getYear, parse } from "date-fns";
import range from "lodash/range";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Input } from "semantic-ui-react";
import tw from "twin.macro";
import {
  DATE_TIME_FORMAT,
  isValidDate,
  SelectInput,
  toOptionTypeBase,
} from "components/shared";
import { InputValidation } from "../InputValidation";
import {
  InputProps,
  getLabelProps,
  getLabelledInputLayoutProps,
} from "./InputUtils";
import { LabelledInput } from "./LabelledInput";

export type DateInputProps = {
  minDate?: Date;
  maxDate?: Date;
  mountNodeId?: string;
  clearable?: boolean;
  validateOnLoad?: boolean;
  onBlur?: () => void;
  onChange?: (date: string | null) => void;
} & InputProps<string>;

const getDateFromString = (stringToParse: string) =>
  parse(stringToParse, DATE_TIME_FORMAT, new Date());
const getStringFromDate = (date: Date) => format(date, DATE_TIME_FORMAT);

export const DateInput = (props: DateInputProps) => {
  //maintain both a stringValue and a dateValue for the input
  //stringValue: user can edit the input manually, so it is acceptable for the stringValue to be an invalid date temporarily while typing
  //dateValue: is used by react-datepicker to represent the actual selected date on the calendar
  const [stringValue, setStringValue] = useState("");
  const [dateValue, setDateValue] = useState<Date | null>(null);

  const labelProps = getLabelProps(props);
  const labelledInputProps = getLabelledInputLayoutProps(props);

  useEffect(() => {
    if (props.value) {
      if (isValidDate(props.value)) {
        setDateValue(getDateFromString(props.value));
      }
      setStringValue(props.value);
    }
  }, [props.value]);

  const updateDate = (date: string | Date | null) => {
    if (!date) {
      setStringValue("");
      setDateValue(null);
      return;
    }
    if (typeof date === "string") {
      setStringValue(date);
      if (isValidDate(date)) setDateValue(getDateFromString(date));
    } else {
      //we have a date
      setDateValue(date);
      setStringValue(getStringFromDate(date));
    }
  };

  const error =
    props.error || !isValidDate(stringValue) || props.inputValidation?.invalid;

  const inputValidation = !props.inputValidation
    ? undefined
    : isValidDate(stringValue)
      ? props.inputValidation
      : {
          invalid: true,
          errorMessage: `Invalid date. Please enter a valid date in the format ${DATE_TIME_FORMAT}.`,
        };

  const input = (
    <>
      <div
        data-test-id={labelProps.label ?? "dateInput"}
        className="datetimeinput-full"
      >
        <DatePicker
          renderCustomHeader={(datePickerProps) => (
            <CustomCalendarHeader
              date={datePickerProps.date}
              changeYear={(year: number) => {
                datePickerProps.changeYear(year);
                datePickerProps.date.setFullYear(year);
                updateDate(datePickerProps.date);
              }}
              changeMonth={(month: number) => {
                datePickerProps.changeMonth(month);
                datePickerProps.date.setMonth(month);
                updateDate(datePickerProps.date);
              }}
              minDate={props.minDate}
              maxDate={props.maxDate}
            />
          )}
          minDate={props.minDate}
          maxDate={props.maxDate}
          showTimeSelect
          selected={dateValue}
          placeholderText={props.placeholder ?? "Select Date"}
          onChange={(date) => {
            updateDate(date);
          }}
          onChangeRaw={(e) => {
            const newStringValue = (e?.target as HTMLInputElement).value;
            updateDate(newStringValue);
          }}
          customInput={
            <Input
              className={`datetimeinput-full ${error ? "error" : ""}`}
              icon="calendar"
              iconPosition="left"
            />
          }
          dateFormat={DATE_TIME_FORMAT}
          timeIntervals={15}
          portalId={props.mountNodeId}
          popperPlacement="top-start"
          showPopperArrow={false}
          onCalendarClose={() => {
            props.onChange?.(dateValue ? getStringFromDate(dateValue) : null);
          }}
        />
      </div>
      {inputValidation && <InputValidation {...inputValidation} />}
    </>
  );
  return props.label ? (
    <LabelledInput {...labelProps} {...labelledInputProps} input={input} />
  ) : (
    input
  );
};

const CustomCalendarHeader = ({
  date,
  changeYear,
  changeMonth,
  minDate,
  maxDate,
}: {
  date: Date;
  changeYear: (year: number) => void;
  changeMonth: (month: number) => void;
  minDate?: Date;
  maxDate?: Date;
}) => {
  const years = range(
    minDate ? getYear(minDate) : 1990,
    maxDate ? getYear(maxDate) : getYear(new Date()) + 10,
    1,
  );
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <div css={tw`flex gap-1 px-2 py-1`}>
      <div css={tw`w-[56%]`}>
        <SelectInput
          placeholder=""
          value={months[getMonth(date)]}
          onChange={(_, { value }) => changeMonth(months.indexOf(value))}
          options={months.map((month) => toOptionTypeBase(month))}
          isClearable={false}
          search
        />
      </div>
      <div css={tw`w-[44%]`}>
        <SelectInput
          placeholder=""
          value={getYear(date)}
          onChange={(_, { value }) => changeYear(Number(value))}
          options={years.map((year) => toOptionTypeBase(year))}
          isClearable={false}
          search
        />
      </div>
    </div>
  );
};
