import {
  format,
  formatDistanceStrict,
  isAfter,
  isBefore,
  isValid,
  parse,
  parseISO,
} from "date-fns";

export const DATE_TIME_FORMAT = "d MMM yyyy, HH:mm";

export const DATE_TIME_FORMAT_WITH_AMPM = "d MMM yyyy, h:mm a";

// solution from https://stackoverflow.com/questions/6525538/convert-utc-date-time-to-local-date-time
export function convertUTCDateToLocalDate(date: Date) {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
}

export const formatDate = (d: string | Date, formatString?: string) => {
  if (d) {
    const obj = typeof d === "string" ? parseISO(d) : d;
    if (!isNaN(obj.getTime())) {
      const localisedDate = convertUTCDateToLocalDate(obj)
      return format(localisedDate, formatString ?? DATE_TIME_FORMAT);
    }
  }
  return "";
};

export const formatDateAsString = (date: Date) => {
  let myDate = "";
  if (!isNaN(date.getTime())) {
    myDate = format(date, DATE_TIME_FORMAT);
  }
  return myDate;
};

export const getDateString =(date: Date | string) => {
  if (typeof date === "string") {
    return date;
  }
  return date.getTime().toString();
};

export const getDataAgeString = (
  earlierDate: Date,
  laterDate: Date
): string => {
  let ageString = "";

  if (!isNaN(earlierDate.getTime()) && !isNaN(laterDate.getTime())) {
    ageString = formatDistanceStrict(earlierDate, laterDate, {
      addSuffix: true,
    });
  }
  return ageString;
};

//Don't want to have to use Moment as no longer being updated,
//but stuck with it as long as we use semantic-ui-calendar-react-XX versions
export const DATE_TIME_FORMAT_MOMENT = "D MMM YYYY, HH:mm";

const isValidDate_DateFns = (dateStr: string): boolean => {
  const parsedDate = parse(dateStr, DATE_TIME_FORMAT, new Date());
  return isValid(parsedDate);
};

export const isValidDate = (value: string) =>
  !value || value === "" || isValidDate_DateFns(value);

export const requiredDateRule = (value: string) =>
  !value || value === "" ? "Required" : undefined;

export const minDateRule = (value: string, comparisonDate?: Date) =>
  value &&
    value !== "" &&
    comparisonDate &&
    isBefore(new Date(value), comparisonDate)
    ? `Date must be later than ${formatDateAsString(comparisonDate)}`
    : undefined;

export const maxDateRule = (value: string, comparisonDate?: Date) =>
  value &&
    value !== "" &&
    comparisonDate &&
    isAfter(new Date(value), comparisonDate)
    ? `Date must be earlier than ${formatDateAsString(comparisonDate)}`
    : undefined;
