/** @jsxImportSource @emotion/react */
import React from "react";
import { FormTextArea } from "semantic-ui-react";
import tw from "twin.macro";
import {
  InputProps,
  FormProps,
  getLabelProps,
  getCommonInputProps,
  getControlledInputProps,
  getLabelledInputLayoutProps,
  LabelledInput,
  UNSPECIFIED_STRING_MSG,
} from "components/shared";
import { InputValidation } from "../InputValidation";

const textAreaStyle = {
  scrollbarColor: "#83218e #979797 !important",
  scrollbarWidth: "thin !important",
  width: "100%",
};

const DEFAULT_LINES = 15;

const ReadOnlyInput = (props: {
  value?: string;
  readOnlyEmptyText?: string;
  verticalLayout?: boolean;
  lines?: number;
}) => {
  const readOnlyValue =
    props.value && props.value !== ""
      ? props.value
      : (props.readOnlyEmptyText ?? UNSPECIFIED_STRING_MSG);
  return (
    <FormTextArea
      style={textAreaStyle}
      className={`text-area-code readonly ${
        props.verticalLayout ? "vertical" : ""
      }  dch-scrollbar`}
      tabIndex={-1}
      value={readOnlyValue}
      rows={props.lines ?? DEFAULT_LINES}
      children={<div css={tw`truncate`}>{readOnlyValue}</div>}
    />
  );
};

export type TextAreaProps = {
  testId?: string;
  rows?: number;
} & InputProps<string>;

export const TextArea = (props: TextAreaProps) => {
  const { inputValidation, ...inputProps } = props;
  const labelProps = getLabelProps(inputProps);
  const labelledInputProps = getLabelledInputLayoutProps(props);
  const { readOnlyEmptyText, ...commonProps } = getCommonInputProps(inputProps);
  const controlledInputProps = getControlledInputProps(inputProps);
  const formProps = (inputProps as FormProps).formProps;

  if (
    (controlledInputProps.value || controlledInputProps.onChange) &&
    formProps &&
    !props.isReadOnly
  )
    console.error(
      "Cannot supply props for both controlled and uncontrolled input to TextInput. Remove either [formProps] or [value,onChange]",
    );
  const input = inputProps.isReadOnly ? (
    <ReadOnlyInput
      value={formProps?.value ?? props.value}
      readOnlyEmptyText={readOnlyEmptyText}
      verticalLayout={labelledInputProps.verticalLayout}
      lines={props.rows}
    />
  ) : (
    <>
      <FormTextArea
        style={textAreaStyle}
        rows={props.rows ?? DEFAULT_LINES}
        fluid
        {...commonProps}
        {...controlledInputProps}
        {...formProps}
        error={commonProps.error ?? inputValidation?.invalid}
        className="dch-scrollbar text-area-code"
        data-test-id={props.testId}
      />
      {inputValidation && (
        <InputValidation {...{ ...inputValidation, testId: props.testId }} />
      )}
    </>
  );

  return props.label ? (
    <LabelledInput {...labelProps} {...labelledInputProps} input={input} />
  ) : (
    input
  );
};
