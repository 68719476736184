import { TimeSeriesBackingResourceType } from "data/Aletheia";

export interface CreateOrEditDataPoolFormValues {
  id: string;
  name?: string;
  description?: string;
  licenseURL?: string;
  timeseriesImmutable: boolean;
  syncTimeseries: boolean;
  backingResourceType: TimeSeriesBackingResourceType;
  backingResourceId: string;
}

export enum FormMode {
  create,
  edit,
}
