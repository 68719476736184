import * as React from "react";
/** @jsxImportSource @emotion/react */
import { generatePath, Link, useNavigate } from "react-router-dom";
import { Grid, Button, Icon } from "semantic-ui-react";
import {
  DataPoolGetParams,
  DataPoolListItem,
  aletheiaApi,
} from "data/Aletheia";
import { getPaginatedFromURL } from "data/httpUtil";
import {
  DEFAULT_PAGINATION_DATA_LIMIT,
  PaginatedResponse,
} from "data/paginationHelper";
import { aletheiaEnv } from "reducers/env";
import { EnodiaOrgContext } from "App";
import { Path } from "Routes";
import {
  EMPTY_NAME,
  FilterInput,
  Page,
  PageActions,
  PageTitle,
  Pagination,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import { Tooltip } from "react-tooltip";
import tw from "twin.macro";
import DeleteDataPoolButton from "./DeleteDataPoolButton";
import { MANAGED_BY_DS_HELPER_TEXT } from "./helpers";

export const renderEnabled = (enabled: boolean) => (enabled ? "Yes" : "No");

const sortByName = (dss: Array<DataPoolListItem>) =>
  dss.sort((a, b) => {
    if (a.name && b.name) {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    }

    return 0;
  });

export const DataPools: React.FunctionComponent = () => {
  const { orgId } = React.useContext(EnodiaOrgContext);
  const navigate = useNavigate();

  const [filter, setFilter] = React.useState<string>();
  const [uiStatus, setUIStatus] = React.useState<UIStatus>(new UIStatus());
  const [datapools, setDatapools] = React.useState<Array<DataPoolListItem>>([]);
  const [links, setLinks] = React.useState<Record<string, string>>();
  const [limit, setLimit] = React.useState<number>(
    DEFAULT_PAGINATION_DATA_LIMIT,
  );

  const fetchDataPoolsFromLink = React.useCallback((link: string) => {
    setUIStatus((prevState) => prevState.setIndeterminate(true));
    processFetchDatapools(
      getPaginatedFromURL(link)(aletheiaEnv).then(
        (r) => r as PaginatedResponse<Array<DataPoolListItem>>,
      ),
    );
  }, []);

  const fetchDataPools = React.useCallback(
    (dataPoolsParams: DataPoolGetParams) => {
      setUIStatus((prevState) => prevState.setIndeterminate(true));
      // we always want to be filtering by org id
      // on first load, orgId may not necessarily be loaded
      if (orgId)
        processFetchDatapools(
          aletheiaApi.getPaginatedDataPools(dataPoolsParams),
        );
    },
    [orgId],
  );

  const processFetchDatapools = (
    promise: Promise<PaginatedResponse<Array<DataPoolListItem>>>,
  ) => {
    return promise
      .then((os) => {
        setLinks(os.links);
        return os.data;
      })
      .then((res) => {
        setDatapools(res);
        setUIStatus((prevState) => prevState.setIndeterminate(false));
      })
      .catch((e) => {
        setUIStatus((prevState) =>
          prevState.setError(e.message ?? "Error fetching data sources"),
        );
      });
  };

  const dataPoolParams = React.useMemo(
    () =>
      filter
        ? {
            organisationid: orgId,
            name: `*${filter}*`,
            limit,
          }
        : {
            organisationid: orgId,
            limit,
          },
    [orgId, filter, limit],
  );

  // on search, fetch the datasources. Only want to track name in params
  React.useEffect(() => {
    if (orgId) fetchDataPools(dataPoolParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, fetchDataPools, filter]);

  const data = datapools ? sortByName(datapools) : [];

  return (
    <Page>
      <PageTitle primaryHeader="Data Pools" pageAction={PageActions.Browse} />
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column>
            <FilterInput
              placeholder="Begin typing to search by name..."
              handleSearch={(term) => {
                setFilter(term);
              }}
            />
          </Grid.Column>

          <Grid.Column textAlign="right">
            <Button primary as={Link} to={Path.NewDataPool}>
              <Icon name="plus" />
              Add Data Pool
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <UIStatusWrapper
        status={
          uiStatus.indeterminate || uiStatus.error
            ? uiStatus
            : uiStatus.setEmpty(data.length === 0)
        }
      >
        <div css={tw` rounded mt-4`}>
          <Grid padded="horizontally" css={tw`gap-y-2`}>
            {data.map((d, index) => {
              const gridStyle =
                index % 2 === 1
                  ? tw`rounded bg-core-grey-light`
                  : tw`rounded bg-core-grey-dark`;
              return (
                <Grid.Row
                  css={gridStyle}
                  key={d.id}
                  data-test-id={"dataPoolCard"}
                >
                  <Grid.Column width={4}>
                    <Link
                      to={generatePath(Path.ViewDataPool, {
                        orgId: orgId as string,
                        id: d.id,
                      })}
                    >
                      {d.name && d.name !== "" ? d.name : <i>{EMPTY_NAME}</i>}
                    </Link>
                  </Grid.Column>
                  <Grid.Column width={7}>
                    {d.compositeId}{" "}
                    <Icon
                      link
                      name="copy"
                      data-tooltip-id={`copy-icon-tooltip`}
                      data-tooltip-content={"Copy to clipboard"}
                      onClick={() => {
                        navigator.clipboard.writeText(d.compositeId);
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column width={3}>
                    {d.dataSourceRef?.dataSourceId && (
                      <Link
                        to={`/datasource/${orgId}/${d.dataSourceRef?.dataSourceId}`}
                      >
                        <Icon name="linkify" />
                        Parent Data Source
                      </Link>
                    )}
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <div css={tw` flex justify-end gap-2`}>
                      <Icon
                        name="pencil"
                        onClick={() =>
                          navigate(`/datapool/${orgId}/${d.id}/edit`)
                        }
                        data-tooltip-id={
                          d.dataSourceRef?.dataSourceId &&
                          `parent-datasource-tooltip`
                        }
                        data-tooltip-content={MANAGED_BY_DS_HELPER_TEXT}
                        link={!d.dataSourceRef?.dataSourceId}
                        disabled={!!d.dataSourceRef?.dataSourceId}
                      />
                      <DeleteDataPoolButton
                        dataPool={d}
                        onRemoved={() => {
                          if (orgId) fetchDataPools(dataPoolParams);
                        }}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              );
            })}

            <Grid.Row>
              <Grid.Column>
                {links && (
                  <Pagination
                    links={links}
                    onPaginationChange={(link) => {
                      fetchDataPoolsFromLink(link);
                    }}
                    setLimit={(newLimit) => setLimit(newLimit)}
                    limit={limit}
                    dataLength={datapools.length}
                    refetch={() => fetchDataPools(dataPoolParams)}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <Tooltip id={`copy-icon-tooltip`} css={tw`z-5`} />
        <Tooltip
          id={`parent-datasource-tooltip`}
          css={tw`z-5`}
          place="top-end"
        />
      </UIStatusWrapper>
    </Page>
  );
};
