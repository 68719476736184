import { useFormContext } from "react-hook-form";
import { DropdownItemProps } from "semantic-ui-react";
import { orderAlphabetically } from "components/shared";
import {
  WingFields,
  FloorFields,
  RoomFields,
  EquipmentFields,
  PointFields,
  ZoneFields,
  ModelDetailsFields,
} from "./EntityForms";
import { ModelMeta } from "data/Mason/ModelDraft/ModelDraftUtils";
import { extractModelId } from "../ModelUtils";

const disabledOptionLabel = (text: string) => ({
  value: text,
  text,
  disabled: true,
});

export const useFormValues = (modelMeta?: ModelMeta) => {
  const { getValues } = useFormContext();

  const rootModelValues = getValues("modelDetails") as ModelDetailsFields;
  const rootModelOption =
    modelMeta || rootModelValues
      ? [
          disabledOptionLabel("Model"),
          {
            value: modelMeta?.modelId ?? rootModelValues.fieldId,
            text:
              modelMeta?.modelName ??
              rootModelValues.name ??
              extractModelId(modelMeta?.modelId ?? rootModelValues.fieldId),
          },
        ]
      : [];

  const availableWings = (getValues("wings") as WingFields[]) ?? [];
  const wingOptions: DropdownItemProps[] = availableWings
    .map((wing) => ({
      value: wing.fieldId,
      text: wing.name ?? wing.fieldId,
    }))
    .sort(orderAlphabetically);

  const availableFloors = (getValues("floors") as FloorFields[]) ?? [];
  const floorOptions: DropdownItemProps[] = availableFloors
    .map((floor) => ({
      value: floor.fieldId,
      text: floor.name ?? floor.fieldId,
    }))
    .sort(orderAlphabetically);

  const availableRooms = (getValues("rooms") as RoomFields[]) ?? [];
  const roomOptions: DropdownItemProps[] = availableRooms
    .map((room) => ({
      value: room.fieldId,
      text: room.name ?? room.fieldId,
    }))
    .sort(orderAlphabetically);

  const availableZones = (getValues("zones") as ZoneFields[]) ?? [];
  const zoneOptions: DropdownItemProps[] = availableZones
    .map((zone) => ({
      value: zone.fieldId,
      text: zone.name ?? zone.fieldId,
    }))
    .sort(orderAlphabetically);

  const availableEquipment =
    (getValues("equipment") as EquipmentFields[]) ?? [];
  const equipmentOptions: DropdownItemProps[] = availableEquipment
    .map((eq) => ({
      value: eq.fieldId,
      text: eq.name ?? eq.fieldId,
    }))
    .sort(orderAlphabetically);

  const availablePoints = (getValues("points") as PointFields[]) ?? [];
  const pointOptions: DropdownItemProps[] = availablePoints
    .map((point) => ({
      value: point.fieldId,
      text: point.name ?? point.fieldId,
    }))
    .sort(orderAlphabetically);

  const locationOptions = [
    ...rootModelOption,
    disabledOptionLabel(availableWings.length > 0 ? "Wings" : "No wings found"),
    ...wingOptions,
    disabledOptionLabel(
      availableFloors.length > 0 ? "Floors" : "No floors found",
    ),
    ...floorOptions,
    disabledOptionLabel(availableRooms.length > 0 ? "Rooms" : "No rooms found"),
    ...roomOptions,
  ];

  const feedsOptions = [
    disabledOptionLabel(
      availableEquipment.length > 0 ? "Equipment" : "No equipment found",
    ),
    ...equipmentOptions,
    ...locationOptions,
    disabledOptionLabel(availableZones.length > 0 ? "Zones" : "No zones found"),
    ...zoneOptions,
  ];

  const pointOfOptions = [
    disabledOptionLabel(
      availableEquipment.length > 0 ? "Equipment" : "No equipment found",
    ),
    ...equipmentOptions,
    ...locationOptions,
  ];

  return {
    wingOptions,
    floorOptions,
    roomOptions,
    locationOptions,
    equipmentOptions,
    pointOptions,
    feedsOptions,
    pointOfOptions,
  };
};
