import { ClientEnv } from "data/utils";
import { masonEnv } from "reducers/env";
import {
  DCHJsonClassResponse,
  DependantsOfDatapoolResponse,
  SchemaTypeRequestParams,
  SchemaUnitRequestParams,
  SchemaUnitsResponse,
} from "./MasonTypes";
import * as O from "fp-ts/lib/Option";
import { httpGet } from "data/httpUtil";
import {
  EntityPropertiesApi,
  entityPropertiesApi,
} from "data/Mason/EntityProperties";
import { QueryApi, queryApi } from "data/QueryApi/queryApi";
import { SiteApi, siteApi } from "./Site/SiteApi";
import { BuildingApi, buildingApi } from "./Building/BuildingApi";
import { DataPoolId } from "data/Aletheia";
import { OrgId } from "data/Enodia";

type MasonApi = {
  getSchemaTypes: (
    params: SchemaTypeRequestParams,
  ) => Promise<Array<DCHJsonClassResponse>>;
  getSchemaUnits: (
    params?: SchemaUnitRequestParams,
  ) => Promise<SchemaUnitsResponse>;
  getDepedantsOfDatapool: (
    orgId: OrgId,
  ) => (dataPoolId: DataPoolId) => Promise<DependantsOfDatapoolResponse>;
} & QueryApi &
  SiteApi &
  BuildingApi &
  EntityPropertiesApi;

export const MASON_ORGANISATION_ROUTE = "org";
export const MASON_SITE_ROUTE = "site";
export const MASON_SITES_ROUTE = "sites";
export const MASON_SITES_AND_BUILDINGS_ROUTE = "sites_and_buildings";
export const MASON_BUILDING_ROUTE = "building";
export const MASON_BUILDINGS_ROUTE = "buildings";
export const MASON_SCHEMA_ROUTE = "schema";
export const MASON_RDF_DRAFT_ROUTE = "draft";
export const MASON_JSON_DRAFT_ROUTE = "json_draft";

/**
 * MODEL VILLAGE API ENDPOINTS
 * AKA. Mason
 * @link https://develop.dataclearinghouse.org/api/mv/v1/swagger#/
 *  */
const mkMasonApi = (env: ClientEnv): MasonApi => ({
  getSchemaTypes: (params) =>
    httpGet(O.none)(env)(params)(`/${MASON_SCHEMA_ROUTE}/types`).then(
      (r) => r as DCHJsonClassResponse[],
    ),
  getSchemaUnits: (params) =>
    httpGet(O.none)(env)(params ?? null)(`/${MASON_SCHEMA_ROUTE}/units`).then(
      (r) => r as SchemaUnitsResponse,
    ),
  getDepedantsOfDatapool: (orgId) => (dataPoolId) =>
    httpGet(O.none)(env)(null)(
      `/org/${orgId}/datapools/${dataPoolId}/linkage`,
    ).then((r) => r as DependantsOfDatapoolResponse),
  ...queryApi,
  ...siteApi,
  ...buildingApi,
  ...entityPropertiesApi,
});

export const masonApi = mkMasonApi(masonEnv);
