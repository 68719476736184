import { aletheiaEnv } from "reducers/env";
import { ClientEnv } from "data/utils";
import {
  DataSourceParams,
  DataSourceListItem,
  DataSourceResponse,
  DataSourceUid,
  DataSourceCompositeId,
  DataPoolResponse,
  DataSourceCreateRequest,
  DataSourceUpdateRequest,
  PointListItem,
  PointGetParams,
  DataPoolListItem,
  DataPoolGetParams,
  PointResponse,
  DataPoolCompositeId,
  PointListCsvGetParams,
  DataDecoderListItem,
  DecoderId,
  DataDecoderResponse,
  DataPoolCreateRequest,
  DataPoolUid,
  DataPoolUpdateRequest,
  DataPoolDeleteParams,
  ResourceDeleted,
  DataPoolPostParams,
} from "./aletheiaTypes";
import * as O from "fp-ts/lib/Option";
import {
  PaginatedResponse,
  handlePaginationResponse,
} from "data/paginationHelper";
import { http, httpDelete, httpGet, httpPost, httpPut } from "data/httpUtil";

/**
 *  Point Management & Classification of Points API ENDPOINTS
 * AKA. Aletheia
 * @link https://develop.dataclearinghouse.org/api/aletheia/v1/swagger#/
 */
type AletheiaApi = {
  getDatasources: (
    params: DataSourceParams,
  ) => Promise<PaginatedResponse<Array<DataSourceListItem>>>;
  getDatasource: (
    dsId: DataSourceCompositeId | DataSourceUid,
  ) => Promise<DataSourceResponse>;
  deleteDatasource: (dsId: DataSourceUid) => Promise<void>;
  getDataPools: (
    dataPoolParams: DataPoolGetParams,
  ) => Promise<Array<DataPoolListItem>>;
  getPaginatedDataPools: (
    dataPoolParams: DataPoolGetParams,
  ) => Promise<PaginatedResponse<Array<DataPoolListItem>>>;
  getDataPoolById: (
    dataPoolId: DataPoolCompositeId,
  ) => Promise<DataPoolResponse>;
  postDatapool: (
    params: DataPoolPostParams,
  ) => (request: DataPoolCreateRequest) => Promise<DataPoolResponse>;
  putDatapool: (
    dataPoolId: DataPoolUid | DataPoolCompositeId,
  ) => (request: DataPoolUpdateRequest) => Promise<DataPoolResponse>;
  deleteDatapool: (
    params: DataPoolDeleteParams,
  ) => (
    dataPoolId: DataPoolUid | DataPoolCompositeId,
  ) => Promise<ResourceDeleted>;
  postDatasource: (
    request: DataSourceCreateRequest,
  ) => Promise<DataSourceResponse>;
  putDatasource: (
    dsId: DataSourceCompositeId | DataSourceUid,
  ) => (request: DataSourceUpdateRequest) => Promise<void>;
  getPoint: (pointId: string) => Promise<PointResponse>;
  getPoints: (
    pointGetParams: PointGetParams,
  ) => Promise<PaginatedResponse<Array<PointListItem>>>;
  getPointListCsv: (
    dataPoolId: string,
    pointListCsvParams: PointListCsvGetParams,
  ) => Promise<string>;
  getDataDecoders: () => Promise<DataDecoderListItem[]>;
  getDataDecoderById: (decoderId: DecoderId) => Promise<DataDecoderResponse>;
};

export const mkAletheiaApi = (env: ClientEnv): AletheiaApi => ({
  getDatasources: (params) =>
    httpGetPaginatedList(O.none)(env)(params)("/datasources").then(
      (r) => r as PaginatedResponse<Array<DataSourceListItem>>,
    ),
  getDatasource: (dsId) =>
    httpGet(O.none)(env)(null)(`/datasources/${dsId}`).then(
      (r) => r as DataSourceResponse,
    ),
  deleteDatasource: (dsId) =>
    httpDelete(O.none)(env)(null)(`/datasources/${dsId}`).then(
      (_) => undefined,
    ),
  getDataPools: (dataPoolParams) =>
    httpGet(O.none)(env)(dataPoolParams)(`/datapools`).then(
      (r) => r as DataPoolListItem[],
    ),
  getPaginatedDataPools: (dataPoolParams) =>
    httpGetPaginatedList(O.none)(env)(dataPoolParams)(`/datapools`).then(
      (r) => r as PaginatedResponse<DataPoolListItem[]>,
    ),
  getDataPoolById: (dataPoolId) =>
    httpGet(O.none)(env)(null)(`/datapools/${dataPoolId}`).then(
      (r) => r as DataPoolResponse,
    ),
  postDatapool: (params) => (request) =>
    httpPost(request)(O.none)(env)(params)("/datapools").then(
      (r) => r as DataPoolResponse,
    ),
  putDatapool: (datapoolId) => (request) =>
    httpPut(request)(O.none)(env)(null)(`/datapools/${datapoolId}`).then(
      (r) => r as DataPoolResponse,
    ),
  deleteDatapool: (params) => (datapoolId) =>
    httpDelete(O.none)(env)(params)(`/datapools/${datapoolId}`).then(
      (r) => r as ResourceDeleted,
    ),
  postDatasource: (request) =>
    httpPost(request)(O.none)(env)(null)("/datasources").then(
      (r) => r as DataSourceResponse,
    ),
  putDatasource: (dsId) => (request) =>
    httpPut(request)(O.none)(env)(null)(`/datasources/${dsId}`).then(
      (_) => undefined,
    ),
  getPoint: (pointId) =>
    httpGet(O.none)(env)(null)(`/points/${pointId}`).then(
      (r) => r as PointResponse,
    ),
  getPoints: (pointGetParams) =>
    httpGetPaginatedList(O.none)(env)(pointGetParams)(`/points`).then(
      (r) => r as PaginatedResponse<Array<PointListItem>>,
    ),
  getPointListCsv: (
    dataPoolId: string,
    pointListCsvParams: PointListCsvGetParams,
  ) =>
    httpGet(O.none)(env)(pointListCsvParams)(
      `/datapools/${dataPoolId}/points_csv`,
    ).then((r) => r as string),
  getDataDecoders: () =>
    httpGet(O.none)(env)(null)(`/datadecoders`).then(
      (r) => r as DataDecoderListItem[],
    ),
  getDataDecoderById: (decoderId: DecoderId) =>
    httpGet(O.none)(env)(null)(`/datadecoders/${decoderId}`).then(
      (r) => r as DataDecoderResponse,
    ),
});

export const aletheiaApi = mkAletheiaApi(aletheiaEnv);

// handle pagination before decoding a response
export const httpGetPaginatedList = http()((res: Response) =>
  handlePaginationResponse(res),
)()("GET")({});

const POINT_COMPOSITE_ID_REGEX =
  /dch:org\/(?<org>[^/]+)\/datapool\/(?<datapool>[^#]+)#(?<id>.*)/;
export const getCompositePointIdFromRdfId = (rdfId: string): string => {
  const matches = POINT_COMPOSITE_ID_REGEX.exec(rdfId);
  return `${matches?.groups?.org!!}:${matches?.groups?.datapool!!}:${matches
    ?.groups?.id!!}`;
};
