import * as O from "fp-ts/lib/Option";
import { httpGet } from "data/httpUtil";
import {
  aletheiaEnv,
  chronosEnv,
  iamEnodiaEnv,
  masonEnv,
  metisEnv,
} from "reducers/env";
import { ClientEnv } from "./utils";

export type Service = { name: string; env: ClientEnv };

export const SERVICES: Service[] = [
  {
    name: "Aletheia",
    env: aletheiaEnv,
  },
  {
    name: "Chronos",
    env: chronosEnv,
  },
  {
    name: "Enodia",
    env: iamEnodiaEnv,
  },
  {
    name: "Mason",
    env: masonEnv,
  },
  {
    name: "Metis",
    env: metisEnv,
  },
];

type ServiceMetadata = {
  version: string;
  buildDate: string;
};

type ServiceApi = {
  getMetadata: () => Promise<ServiceMetadata>;
};

export const mkServiceApi = (env: ClientEnv): ServiceApi => ({
  getMetadata: () =>
    httpGet(O.none)(env)(null)(`/metadata`).then((r) => r as ServiceMetadata),
});
