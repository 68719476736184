/** @jsxImportSource @emotion/react */

import React, { ReactNode } from "react";
import tw from "twin.macro";

type Props = {
  header?: string | ReactNode;
  children?: ReactNode;
  greyBg?: boolean;
  isSubContent?: boolean;
};

export const PageSection = (props: Props) => {
  const greyBgStyle = props.greyBg
    ? tw`absolute px-2 mt-0 text-white bg-core-grey-dark`
    : tw`absolute px-2 mt-0 text-core-grey bg-black`;

  return (
    <div css={tw`mt-6`}>
      <div
        css={tw`border-solid border-2 border-core-grey m-0 mb-4 p-6 pt-8 relative`}
      >
        {props.header &&
          (props.isSubContent ? (
            <h4 css={greyBgStyle} style={{ top: "-1rem" }}>
              {props.header}
            </h4>
          ) : (
            <h3 css={greyBgStyle} style={{ top: "-1rem" }}>
              {props.header}
            </h3>
          ))}
        {props.children}
      </div>
    </div>
  );
};
