/** @jsxImportSource @emotion/react */

import React, { useState } from "react";
import { Input, Icon, Button } from "semantic-ui-react";
import tw from "twin.macro";
import {
  DataSourceListItem,
  DataSourceResponse,
  DataSourceUid,
  aletheiaApi,
} from "data/Aletheia";
import { ApiErrorType } from "data/http";
import { DchModal, UIStatus } from "components/shared";

type Props = {
  dataSource: DataSourceListItem | DataSourceResponse;
  asButton?: boolean;
  onRemoved?: () => void;
};

const RemoveDataSource: React.FC<Props> = (props) => {
  const { dataSource, asButton = false } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const name = dataSource.name ?? "NONAME";

  return (
    <>
      {asButton ? (
        <Button basic inverted onClick={() => setIsModalOpen(true)}>
          <Icon name="trash" />
          Delete Data Source
        </Button>
      ) : (
        <Icon name="trash" onClick={() => setIsModalOpen(true)} link />
      )}
      <DeleteDataSourceModal
        open={isModalOpen}
        dsId={dataSource.uid as DataSourceUid}
        dsName={name}
        setOpen={setIsModalOpen}
        onDelete={props.onRemoved}
      />
    </>
  );
};

type ModalProps = {
  open: boolean;
  dsId: DataSourceUid;
  dsName: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onDelete?: () => void;
};

const DeleteDataSourceModal: React.FunctionComponent<ModalProps> = (props) => {
  const [inputName, setInputName] = useState("");
  const [confirmStatus, setConfirmStatus] = useState(new UIStatus());

  const onConfirm = () => {
    setConfirmStatus((prev) => prev.setIndeterminate(true));
    inputName !== props.dsName
      ? setConfirmStatus((prev) =>
          prev.setError(
            `Input '${inputName}' does not match data source name '${props.dsName}'`,
          ),
        )
      : aletheiaApi.deleteDatasource(props.dsId).then(
          () => {
            props.setOpen(false);
            props.onDelete && props.onDelete();
            setConfirmStatus((prev) => prev.setIndeterminate(false));
          },
          (e) => {
            switch (e.type) {
              case ApiErrorType.AuthenticationRequired:
                setConfirmStatus((prev) =>
                  prev.setError("You are not authenticated."),
                );
                break;
              case ApiErrorType.BadRequest:
                setConfirmStatus((prev) =>
                  prev.setError("An unknown error was present in the request."),
                );
                break;
              case ApiErrorType.ServerError:
                setConfirmStatus((prev) =>
                  prev.setError("A server error occurred."),
                );
                break;
              case ApiErrorType.Unauthorized:
                setConfirmStatus((prev) =>
                  prev.setError(
                    "You are not authorized to delete this data source.",
                  ),
                );
                break;
              default:
                setConfirmStatus((prev) =>
                  prev.setError("An unknown error occurred."),
                );
                break;
            }
          },
        );
  };

  const onClose = () => {
    setInputName("");
    props.setOpen(false);
  };

  return (
    <DchModal
      header={`Remove Data Source: ${props.dsName}`}
      content={
        <React.Fragment>
          <p>
            Deleting a data source will stop any streams belonging to that data
            source from being updated.
          </p>
          <p>
            Streams and any other data created for the data source will not be
            deleted.
          </p>
          <p>
            Associations between the data source's streams and points in any
            building models will also remain.
          </p>
          <div css={tw`my-6`}>
            <Input
              css={tw`w-full`}
              type="text"
              placeholder={`Enter the name '${props.dsName}' to confirm deletion`}
              value={inputName}
              onChange={(e) => setInputName(e.currentTarget.value)}
            />
          </div>
          {confirmStatus.error && (
            <div css={tw`text-red-error`}>
              <>{confirmStatus.error}</>
            </div>
          )}
        </React.Fragment>
      }
      open={props.open}
      onClose={onClose}
      onConfirm={onConfirm}
      disableConfirm={inputName !== props.dsName}
      confirmText="Delete"
      confirmLoading={confirmStatus.indeterminate}
    />
  );
};

export default RemoveDataSource;
