/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { DropdownItemProps, Grid } from "semantic-ui-react";

import { PageSection, UIStatus, UIStatusWrapper } from "components/shared";
import Form from "components/shared/Forms/ReactHookForm";

import {
  aletheiaApi,
  DataDecoderListItem,
  DataDecoderResponse,
  DecoderId,
} from "data/Aletheia";
import { DEFAULT_DECODER_ID } from "../DataSourceUtils";
import parse from "html-react-parser";

const toOptions = function (x: DataDecoderListItem): DropdownItemProps {
  return {
    value: x.id,
    text: x.name ?? x.id,
  };
};

const validateJson = (value: string) => {
  if (!value) return true;
  try {
    JSON.parse(value);
    return true;
  } catch {
    return "Invalid JSON";
  }
};

export const DataParserFormSegment = () => {
  const { setValue, getValues, watch, formState } = useFormContext();

  const [status, setStatus] = useState<UIStatus>(new UIStatus());
  const [options, setOptions] = useState<DropdownItemProps[]>([]);
  const [decoderCache, setDecoderCache] = useState<
    Record<string, DataDecoderResponse>
  >({});

  const decoderType = watch("decoderType");

  useEffect(() => {
    setStatus((p) => p.setIndeterminate(true));
    aletheiaApi
      .getDataDecoders()
      .then((res) => {
        setOptions(res.map(toOptions));
        return res;
      })
      .then((decoders) => {
        setStatus((p) => p.setIndeterminate(false));
        const bmsJsonListItem = decoders.find(
          (decoder) => (decoder.id as string) === DEFAULT_DECODER_ID,
        );
        if (bmsJsonListItem) {
          setValue(
            "decoderType",
            formState.defaultValues?.decoderType ?? DEFAULT_DECODER_ID,
          );
        }
        setStatus((p) => p.setIndeterminate(false));
      })
      .catch((e) => {
        setStatus((p) => p.setError(e.message));
      });
  }, [formState.defaultValues?.decoderType, setValue]);

  // on type change, reset the values the config value to undefined
  useEffect(() => {
    if (decoderType) {
      setValue("decoderConfigText", undefined);
    }
  }, [decoderType, setValue]);

  useEffect(() => {
    if (decoderType && !decoderCache[decoderType as string]) {
      aletheiaApi
        .getDataDecoderById(decoderType as DecoderId)
        .then((r) => {
          setDecoderCache((p) => ({ ...p, [decoderType as string]: r }));
        })
        .catch((e) => {
          setStatus((p) => p.setError(e.message));
        });
    }
  }, [decoderCache, decoderType]);

  return (
    <UIStatusWrapper status={status}>
      <PageSection header="Data Decoder Configuration">
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Grid>
                {options.length > 0 && decoderType !== undefined && (
                  <>
                    <Grid.Row className="collapsed">
                      <Grid.Column width={16}>
                        <Form.SelectInput
                          name="decoderType"
                          label="Decoder Type"
                          required
                          options={options}
                          isClearable={false}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="collapsed">
                      <Grid.Column width={4}></Grid.Column>
                      <Grid.Column width={12}>
                        {parse(
                          decoderCache[getValues("decoderType")]?.description ??
                            "",
                        )}
                        <pre>
                          {parse(
                            decoderCache[getValues("decoderType")]
                              ?.configExample ?? "",
                          )}
                        </pre>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                )}
                {decoderCache[getValues("decoderType")]?.configDefinition && (
                  <>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Form.TextArea
                          name="decoderConfigText"
                          label="Configuration"
                          verticalLayout
                          required
                          validateOnChange
                          rules={{ validate: validateJson }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </>
                )}
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </PageSection>
    </UIStatusWrapper>
  );
};
