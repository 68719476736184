// eslint-disable-next-line react-hooks/exhaustive-deps
import { debounce } from "throttle-debounce";
import { API_CALL_DEBOUNCE_MS } from "data/validation";
import { aletheiaApi, DataSourceCompositeId } from "data/Aletheia";

export const checkDataSourceId = debounce(
  API_CALL_DEBOUNCE_MS,
  async (
    orgId: string,
    datasourceId: string,
    resolve: (_: string | undefined) => void,
  ) => {
    const errorMsg = `A data source with this ID already exists. Please choose another ID.`;
    const error: string | undefined = await aletheiaApi
      .getDatasource(`${orgId}:${datasourceId}` as DataSourceCompositeId)
      .then(
        () => errorMsg,
        () => undefined,
      );
    resolve(error);
  },
);
