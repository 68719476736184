/** @jsxImportSource @emotion/react */

import React, { useState } from "react";
import { Button, Form, Icon, SemanticICONS } from "semantic-ui-react";
import tw from "twin.macro";

const renderIconButton = (
  label: string,
  icon: SemanticICONS,
  onClick: () => void,
) => {
  return (
    <Button basic inverted icon onClick={onClick}>
      {label}
      <Icon name={icon} className="icon-right" />
    </Button>
  );
};

type FileDisplayProps = {
  data: string;
  updateCallback?: Function;
  setExpanded?: boolean;
};
export const FileDisplay: React.FunctionComponent<FileDisplayProps> = ({
  data,
  updateCallback = undefined,
  setExpanded = false,
}) => {
  const [expanded, setToExpand] = useState(setExpanded);
  const showToggler =
    (!setExpanded || !expanded) && data?.split(/\r\n|\r|\n/)?.length > 6;

  const handleClick = () => {
    setToExpand((prev) => !prev);
  };

  const renderExpansionToggler = () => {
    return (
      <div>
        {!expanded && <span css={tw`pl-4`}>. . .</span>}
        <div css={tw`p-4`}>
          {renderIconButton(
            expanded ? "Collapse" : "Expand",
            expanded ? "caret up" : "caret down",
            handleClick,
          )}
        </div>
      </div>
    );
  };

  const renderTextArea = () => (
    <div>
      <Form.TextArea
        style={{
          height: "40vh",
          overflowY: expanded ? "scroll" : "hidden",
          scrollbarColor: "#83218e #979797 !important",
          scrollbarWidth: "thin !important",
          width: "100%",
        }}
        value={data}
        className="dch-scrollbar"
        onChange={(_, d) => {
          if (updateCallback && d.value) {
            updateCallback(d.value);
          }
        }}
      />
      {showToggler && renderExpansionToggler()}
    </div>
  );

  const renderPre = () => {
    const customTw = expanded
      ? tw`max-h-80 overflow-y-scroll`
      : tw`max-h-40 overflow-y-hidden`;
    return (
      <div css={tw`border border-solid px-4`}>
        <pre css={customTw} className="dch-scrollbar">
          {data}
        </pre>
        {showToggler && renderExpansionToggler()}
      </div>
    );
  };

  return updateCallback ? renderTextArea() : renderPre();
};
