import * as React from "react";
import { Index } from "./components/Index/Index";
import { BuildingId, SiteId } from "./data/brick";
import DataSources from "./components/Data/DataSource/DataSources";
import DataSource from "./components/Data/DataSource/DataSource";
import { NewDataSource } from "./components/Data/DataSource/CreateOrEditDataSource/NewDataSource";
import SiteOrBuilding from "./components/SitesAndBuildings/SiteOrBuilding";
import EditDataSource from "./components/Data/DataSource/CreateOrEditDataSource/EditDataSource";
import CustomerHome from "./components/Home/Home";
import Search from "./components/Search/Search";
import {
  AvailableApplication,
  AvailableApplications,
  InstalledApplication,
  InstalledApplications,
  ManageApplications,
  VendorDetails,
  EditVendorDetails,
  ManageApplication,
  UploadApplication,
} from "./components/Applications";
import SitesAndBuildings from "./components/SitesAndBuildings/SitesAndBuildings";
import DataHealth from "./components/Data/DataHealth/DataHealth";
import PointExplorer from "components/Data/PointExplorer/PointExplorer";
import { IndexPageMode, Page, PageTitle, PageMode } from "components/shared";
import ApiKeys from "components/Settings/ApiKeys/ApiKeys";
import {
  EditOrCreateRoleSet,
  EditOrCreateUserGroup,
  Permissions,
} from "components/Settings/Permissions";
import { EditOrInviteUser } from "components/Settings/Permissions/Users/EditOrInviteUser";
import Documentation from "components/Help/Documentation";
import ModelManagement from "components/Tools/ModelManagement";
import { PointClassifier } from "components/Tools/PointClassifier/PointClassifierPage";
import ModelVisualiser from "components/SitesAndBuildings/Model/Visualiser/ModelVisualiser";
import { Route } from "react-router-dom";
import ComponentLibrary from "components/ComponentLibrary/ComponentLibrary";
import { OrgId } from "data/Enodia";
import {
  CreateDataPool,
  DataPool,
  DataPools,
  EditDataPool,
} from "components/Data/DataPool";

export enum Path {
  Index = "",
  Home = "/home",
  HomeOrgContext = "/home/:orgId?",
  NewSite = "/new/site",
  NewSiteFromOrg = "/new/site/:orgId",
  NewBuilding = "/new/building",
  NewBuildingFromSite = "/new/building/:orgId/:siteId",
  EditSite = "/edit/:orgId/:siteId",
  EditBuilding = "/edit/:orgId/:siteId/:buildingId",
  ViewSite = "/view/:orgId/:siteId",
  ViewBuilding = "/view/:orgId/:siteId/:buildingId",
  ViewModelSite = "/model/:orgId/:siteId",
  ViewModelBuilding = "/model/:orgId/:siteId/:buildingId",
  Login = "/login",
  Signup = "/signup",
  DataSources = "/datasource",
  DataSource = "/datasource/:orgId/:id",
  NewDataSource = "/new/datasource",
  EditDataSource = "/datasource/:orgId/:id/edit",
  Search = "/search",
  Applications = "/applications",
  InstalledApplication = "/applications/:applicationId",
  AvailableApplications = "/available-applications",
  AvailableApplicationView = "/available-applications/:applicationId",
  ManageApplications = "/manage-applications",
  ManageApplication = "/manage-applications/:applicationId",
  UploadApplication = "/manage-applications/upload",
  VendorApplicationVersionDetailView = "/manage-applications/:applicationId/:versionId",
  VendorApplicationOperatorDetailView = "/manage-applications/:applicationId/:versionId/:operatorId",
  ViewVendorDetails = "/vendor/view/:vendorUid",
  EditVendorDetails = "/vendor/edit/:vendorUid",
  SitesAndBuildings = "/sites-and-buildings",
  PointExplorer = "/point-explorer",
  DataHealth = "/data-health",
  ResetPasswordWithToken = "/reset-password-with-token/:token",
  ApiKeys = "/api-keys",
  Permissions = "/permissions",
  ViewRoleSet = "/role-set/:roleSetId/view",
  EditRoleSet = "/role-set/:roleSetId/edit",
  AddRoleSet = "/add-role-set",
  AddUserGroup = "/add-user-group",
  EditUserGroup = "/user-group/:orgId/:userGroupId/edit",
  ViewUserGroup = "/user-group/:orgId/:userGroupId/view",
  InviteNewUser = "/invite-user",
  ViewUser = "/user/:user_id/view",
  EditUser = "/user/:user_id/edit",
  Help = "/help",
  ModelManagement = "/model-management",
  PointClassifierRoot = "/point-classifier",
  PointClassifier = "/point-classifier/:orgId?/:classifierSetId?/:datapoolId?/:classificationPlanId?",
  ComponentLibrary = "/component-library",
  DataPools = "/datapool",
  NewDataPool = "/new/datapool",
  ViewDataPool = "/datapool/:orgId/:id",
  EditDataPool = "/datapool/:orgId/:id/edit",
}

type PathMeta = {
  path: Path;
  children: React.ReactElement<any, any>;
  isPublic?: boolean;
};

export const Paths: Array<PathMeta> = [
  { path: Path.Index, children: <Index />, isPublic: true },
  { path: Path.Home, children: <CustomerHome /> },
  { path: Path.HomeOrgContext, children: <CustomerHome /> },
  { path: Path.Search, children: <Search /> },
  {
    path: Path.Applications,
    children: <InstalledApplications />,
  },
  {
    path: Path.InstalledApplication,
    children: <InstalledApplication />,
  },
  {
    path: Path.AvailableApplications,
    children: <AvailableApplications />,
  },
  {
    path: Path.AvailableApplicationView,
    children: <AvailableApplication />,
  },
  {
    path: Path.ManageApplications,
    children: <ManageApplications />,
  },
  {
    path: Path.ManageApplication,
    children: <ManageApplication />,
  },
  {
    path: Path.UploadApplication,
    children: <UploadApplication />,
  },
  {
    path: Path.ViewVendorDetails,
    children: <VendorDetails />,
  },
  {
    path: Path.EditVendorDetails,
    children: <EditVendorDetails />,
  },
  { path: Path.SitesAndBuildings, children: <SitesAndBuildings /> },
  {
    path: Path.ViewBuilding,
    children: <SiteOrBuilding pageMode={PageMode.edit} />,
  },
  {
    path: Path.ViewModelSite,
    children: <ModelVisualiser />,
  },
  {
    path: Path.ViewModelBuilding,
    children: <ModelVisualiser />,
  },
  { path: Path.ViewSite, children: <SiteOrBuilding /> },
  {
    path: Path.NewSite,
    children: <SiteOrBuilding pageMode={PageMode.create} />,
  },
  {
    path: Path.NewSite,
    children: <SiteOrBuilding pageMode={PageMode.create} />,
  },
  {
    path: Path.NewSiteFromOrg,
    children: <SiteOrBuilding pageMode={PageMode.create} />,
  },
  {
    path: Path.EditSite,
    children: <SiteOrBuilding pageMode={PageMode.edit} />,
  },
  {
    path: Path.NewBuilding,
    children: (
      <SiteOrBuilding pageMode={PageMode.create} isBuildingMetadata={true} />
    ),
  },
  {
    path: Path.NewBuildingFromSite,
    children: (
      <SiteOrBuilding pageMode={PageMode.create} isBuildingMetadata={true} />
    ),
  },
  { path: Path.DataSources, children: <DataSources /> },
  { path: Path.DataSource, children: <DataSource /> },
  { path: Path.DataHealth, children: <DataHealth /> },
  { path: Path.PointExplorer, children: <PointExplorer /> },
  { path: Path.NewDataSource, children: <NewDataSource /> },
  { path: Path.EditDataSource, children: <EditDataSource /> },
  { path: Path.ApiKeys, children: <ApiKeys /> },
  {
    path: Path.Login,
    children: <Index initialPageMode={IndexPageMode.login} />,
    isPublic: true,
  },
  {
    path: Path.Signup,
    children: <Index initialPageMode={IndexPageMode.signup} />,
    isPublic: true,
  },
  { path: Path.Permissions, children: <Permissions /> },
  {
    path: Path.ViewRoleSet,
    children: <EditOrCreateRoleSet pageMode={PageMode.view} />,
  },
  {
    path: Path.EditRoleSet,
    children: <EditOrCreateRoleSet pageMode={PageMode.edit} />,
  },
  {
    path: Path.AddRoleSet,
    children: <EditOrCreateRoleSet pageMode={PageMode.create} />,
  },
  {
    path: Path.AddUserGroup,
    children: <EditOrCreateUserGroup pageMode={PageMode.create} />,
  },
  {
    path: Path.EditUserGroup,
    children: <EditOrCreateUserGroup pageMode={PageMode.edit} />,
  },
  {
    path: Path.ViewUserGroup,
    children: <EditOrCreateUserGroup pageMode={PageMode.view} />,
  },
  {
    path: Path.InviteNewUser,
    children: <EditOrInviteUser pageMode={PageMode.create} />,
  },
  {
    path: Path.ViewUser,
    children: <EditOrInviteUser pageMode={PageMode.view} />,
  },
  {
    path: Path.EditUser,
    children: <EditOrInviteUser pageMode={PageMode.edit} />,
  },
  {
    path: Path.Help,
    children: <Documentation />,
  },
  { path: Path.ModelManagement, children: <ModelManagement /> },
  { path: Path.ComponentLibrary, children: <ComponentLibrary /> },

  { path: Path.PointClassifier, children: <PointClassifier /> },
  { path: Path.DataPools, children: <DataPools /> },
  { path: Path.NewDataPool, children: <CreateDataPool /> },
  { path: Path.EditDataPool, children: <EditDataPool /> },
  { path: Path.ViewDataPool, children: <DataPool /> },
];

const NotFound: React.FunctionComponent = () => (
  <Page>
    <PageTitle
      primaryHeader="Page Not Found"
      subHeader={"Sorry, that page cannot be found"}
    />
  </Page>
);

export const PublicRoutes = Paths.filter((p) => p.isPublic)
  .map((p) => <Route key={p.path} path={p.path} element={p.children} />)
  .concat(<Route path="*" key="404" element={<NotFound />} />);

export const Routes = Paths.map((p) => (
  <Route key={p.path} path={p.path} element={p.children} />
)).concat(<Route path="*" key="404" element={<NotFound />} />);

/* PATH HELPERS */
export const installedApplicationViewPath = (applicationId: string): string =>
  `${Path.Applications}/${applicationId}`;
export const availableApplicationViewPath = (applicationId: string): string =>
  `${Path.AvailableApplications}/${applicationId}`;
export const manageApplicationViewPath = (applicationId: string): string =>
  `${Path.ManageApplications}/${applicationId}`;
export const applicationVersionViewPath = (
  applicationId: string,
  versionId: string,
): string => `${Path.ManageApplications}/${applicationId}/${versionId}`;
export const versionOperatorViewPath = (
  applicationId: string,
  versionId: string,
  operatorId: string,
): string =>
  `${Path.ManageApplications}/${applicationId}/${versionId}/${operatorId}`;
export const viewBuildingPath = (
  o: OrgId,
  s: SiteId,
  b: BuildingId,
  id?: string,
): string => `/view/${o}/${s}/${b}${id != null ? `?id=${id}` : ""}`;
export const masonViewBuildingPath = (
  o: OrgId,
  s: SiteId,
  b: BuildingId,
  id?: string,
): string => `/view/${o}/${s}/${b}${id != null ? `?id=${id}` : ""}`;
export const viewSitePath = (o: OrgId, s: SiteId, id?: string): string =>
  `/view/${o}/${s}${id != null ? `?id=${id}` : ""}`;
export const editSitePath = (o: OrgId, s: SiteId): string => `/edit/${o}/${s}`;
