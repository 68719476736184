/** @jsxImportSource @emotion/react */
import React, { useContext } from "react";
import { Form } from "components/shared";
import {
  hasAsyncError,
  useAsyncValidate,
  useAsyncValidateOnOrgChange,
} from "components/shared/Forms/useAsyncValidate";
import {
  API_CALL_DEBOUNCE_MS,
  DESCRIPTION_CHAR_LIMIT,
  ID_CHAR_LIMIT,
  isValidId,
  isValidLength,
  isValidUrl,
  NAME_CHAR_LIMIT,
} from "data/validation";
import { useFormContext } from "react-hook-form";
import { debounce } from "throttle-debounce";
import { aletheiaApi, DataPoolCompositeId } from "data/Aletheia";
import { EnodiaOrgContext } from "App";
import { CreateOrEditDataPoolFormValues, FormMode } from "./common";

const checkDataSourceId = debounce(
  API_CALL_DEBOUNCE_MS,
  async (
    orgId: string,
    datasourceId: string,
    resolve: (_: string | undefined) => void,
  ) => {
    const errorMsg = `A data source with this ID already exists. Please choose another ID.`;
    const error: string | undefined = await aletheiaApi
      .getDataPoolById(`${orgId}:${datasourceId}` as DataPoolCompositeId)
      .then(
        () => errorMsg,
        () => undefined,
      );
    resolve(error);
  },
);

export const DataPoolFormDetails = ({ formMode }: { formMode: FormMode }) => {
  const { orgId } = useContext(EnodiaOrgContext);
  const { getFieldState, formState } = useFormContext();

  const validateDataPoolID = (s: string): Promise<string | undefined> =>
    new Promise((resolve) => {
      if (orgId && s !== "" && formState.isDirty)
        checkDataSourceId(orgId, s, resolve);
    });

  useAsyncValidate<CreateOrEditDataPoolFormValues>("id", validateDataPoolID);
  useAsyncValidateOnOrgChange<CreateOrEditDataPoolFormValues>(
    "id",
    validateDataPoolID,
  );

  return (
    <>
      <Form.TextInput
        name="id"
        label="ID"
        required
        verticalLayout
        validateOnChange
        isReadOnly={formMode === FormMode.edit}
        rules={{
          validate:
            formMode === FormMode.create
              ? {
                  isValidId: isValidId,
                  hasAsyncError: () => hasAsyncError("id", getFieldState),
                  isValidLength: (s) => isValidLength(s, ID_CHAR_LIMIT),
                }
              : undefined,
        }}
      />
      <Form.TextInput
        name="name"
        label="Name"
        verticalLayout
        maxLength={NAME_CHAR_LIMIT}
      />
      <Form.TextInput
        name="description"
        label="Description"
        verticalLayout
        maxLength={DESCRIPTION_CHAR_LIMIT}
      />
      <Form.TextInput
        name="licenseURL"
        label="License URL"
        rules={{
          validate: isValidUrl,
        }}
        verticalLayout
      />
    </>
  );
};
