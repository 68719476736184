/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { Divider, List, Loader } from "semantic-ui-react";
import tw from "twin.macro";
import { mkUrl } from "data/http";
import { mkServiceApi, Service, SERVICES } from "data/Service";
import {
  DOCUMENTATION_URL,
  externalLinkTo,
  Page,
  PageTitle,
  UIStatus,
} from "components/shared";

const ServiceItem = ({ name, env }: Service) => {
  const [version, setVersion] = useState<string>();
  const [uiStatus, setUiStatus] = useState(new UIStatus());

  useEffect(() => {
    setUiStatus((prev) => prev.setIndeterminate(true));
    mkServiceApi(env)
      .getMetadata()
      .then(
        (metadata) => {
          setVersion(metadata.version);
          setUiStatus((prev) => prev.setSuccessful(true));
        },
        () => {
          setUiStatus((prev) =>
            prev.setError("Unable to fetch current version"),
          );
        },
      );
  }, [env]);

  return (
    <List.Item css={tw`mx-4`}>
      <>
        {externalLinkTo(
          mkUrl(env, "/swagger#/", null),
          `Open ${name} API Docs`,
        )}
        <span css={tw`pl-4`}>
          {uiStatus.indeterminate ? (
            <>
              <span css={tw`pl-4`}>{`Fetching current version`}</span>
              <Loader active inline size="tiny" />
            </>
          ) : version ? (
            `( Current version: ${version} )`
          ) : (
            `Unable to fetch current version`
          )}
        </span>
      </>
    </List.Item>
  );
};

const Documentation = () => {
  return (
    <Page>
      <PageTitle primaryHeader="Documentation" subHeader="Guide - Gitbook" />
      {externalLinkTo(DOCUMENTATION_URL, "Open User Guide")}
      <Divider />
      <PageTitle subHeader="DCH Middleware API" />
      <List>
        {SERVICES.map((service) => (
          <ServiceItem key={service.name} {...service} />
        ))}
      </List>
    </Page>
  );
};
export default Documentation;
