/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

export const Tabs = <T extends string>({
  tabs,
  selectedTab,
  setSelectedTab,
}: {
  tabs: T[];
  selectedTab: T;
  setSelectedTab: (selectTab: T) => void;
}) => {
  return (
    <div css={tw`flex gap-8 mb-2`}>
      {tabs.map((tab) => (
        <div
          key={tab}
          onClick={() => setSelectedTab(tab)}
          css={[
            tw`uppercase px-4`,
            selectedTab === tab
              ? tw`text-lighter border-0 border-b-1 border-solid border-lighter pb-1 `
              : tw`cursor-pointer hover:text-lighter`,
          ]}
          data-test-id="tab-title"
        >
          {tab}
        </div>
      ))}
    </div>
  );
};
