/** @jsxImportSource @emotion/react */

import * as React from "react";
import { subYears } from "date-fns";
import {
  Divider,
  Header,
  Form,
  Dimmer,
  Segment,
  Grid,
  SegmentGroup,
} from "semantic-ui-react";
import tw from "twin.macro";
import {
  sensorApi,
  analysisApi,
  getGraphNodeDocumentId,
  Workflow,
  StreamId,
  Stream,
  Observation,
  GraphNodeSingle,
  getStreamsWithoutLimit,
  getStreamsMinMaxUsingIds,
  getObservationsCustom,
} from "data/senaps";
import {
  DchModal,
  Page,
  PageTitle,
  reducePromises,
  regexFilter,
  SelectInput,
  toOptionTypeBase,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import { Chart } from "components/Data/PointData";
import {
  DataDetailsPane,
  DataInspector,
  gatherAverageResults,
  metricFormatted,
  metricLookup,
  MissingDataLoggerPreviousVectorLookup,
  ReportConfiguration,
  StreamHealthMonitorDoc,
  OutageTable,
} from ".";
import { ChartData } from "../PointData/ChartUtils";

const DataHealth: React.FC = () => {
  const [workflowNamespace, setNamespace] = React.useState<string>("");
  const [disabledNamespaces, setDisabledNamespaces] = React.useState<
    Array<string>
  >([]);
  const [awaitingSelection, setAwaitingSelection] =
    React.useState<boolean>(true);

  const [workflowStreamIDs, setStreamIDs] = React.useState<Array<string>>([]);
  const [workflowGroupIDs, setGroupIDs] = React.useState<Array<string>>([]);
  const [workflowSummaryID, setSummaryID] = React.useState<string>("");
  const [streamData, setStreamData] = React.useState<Array<Observation>>([]);
  const [selectedMetrics, setMetrics] = React.useState<Array<string>>([
    "missing_percent",
    "outlier_percent",
  ]);
  const [streamLastDataHealth, setStreamLastDataHealth] = React.useState<
    Record<string, number>
  >({});
  const [selectedGroupIDs, setSelectedGroupIDs] = React.useState<Array<string>>(
    [],
  );
  const [vectorLookup, setVectorLookup] = React.useState<
    Record<number, string>
  >({});
  const [invalidNamespace, setInvalidNamespace] =
    React.useState<boolean>(false);

  const [outageStreams, setOutageStreams] = React.useState<
    Record<string, Array<string>>
  >({});

  const [getWorkflowsStatus, setGetWorkflowsStatus] = React.useState(
    new UIStatus(),
  );

  const [getStreamsWithoutLimitStatus, setGetStreamsWithoutLimitStatus] =
    React.useState(new UIStatus());

  const [getObservationsStatus, setGetObservationsStatus] = React.useState(
    new UIStatus(),
  );

  const [available_outputs, setAvailableOutputs] = React.useState<
    Array<ReportConfiguration>
  >([]);

  const [streamToAddToInspector, setStreamToAddToInspector] =
    React.useState<string>();

  const chartStyle = tw`h-400`;
  const summaryChartId = "summaryChart";

  /**
   * Memoised function to only re-render the chart when the stream data, selected metrics or metric->index lookup have changed.
   *
   * @requires streamData - Array of observations of the summary stream vector.
   * @requires selectedMetrics - List of metric strings to extract from the summary vector stream.
   * @requires vectorLookup - Mapping of index to metric string.
   * @returns Chart data of the summary vector stream, which will be arrays of <Date, number> for each metric.
   */
  /**
   * Function to generate chart data to be plotted by AmCharts. Currently this is only for the summary vector stream.
   *
   * TODO: Determine how to do this for a variable stream ID!
   *
   * @returns Chart data of the summary vector stream, which will be arrays of <Date, number> for each metric.
   */
  const generateChart = React.useMemo(() => {
    const summaryChartData = (): ChartData<number | null> =>
      !streamData
        ? []
        : selectedMetrics.flatMap((m) => ({
            data: gatherAverageResults(
              streamData,
              vectorLookup,
              selectedGroupIDs,
              m,
            ),
            name: m,
          }));

    return (
      <Chart
        css={chartStyle}
        id={summaryChartId}
        data={summaryChartData}
        showExportMenu={true}
        filename={selectedGroupIDs.join("_")}
      />
    );
  }, [
    streamData,
    selectedMetrics,
    vectorLookup,
    chartStyle,
    summaryChartId,
    selectedGroupIDs,
  ]);

  /**
   * Function to generate dropdown menus for the site and building fields.
   *
   * @param loading - UIStatus to display if the workflow information is being loaded or not.
   * @param namespaces - Report Configurations of all found workflows.
   * @returns Semantic UI Form of the site and building dropdowns.
   */
  const generateSiteDetails = (
    loading: UIStatus,
    namespaces: Array<ReportConfiguration>,
  ) => (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={16}>
          <SelectInput
            options={namespaces.map((d) => toOptionTypeBase(d.namespace))}
            isLoading={loading.indeterminate}
            disabled={loading.indeterminate || namespaces.length === 0}
            noOptionsMessage={
              loading.indeterminate ? "Loading sites..." : "No sites found."
            }
            placeholder={
              loading.indeterminate ? "Loading sites..." : "Select site..."
            }
            value={workflowNamespace}
            onChange={(_, { value }) => setNamespace(value as string)}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );

  /**
   * Function to generate dropdown of the selected metrics to display in the summary plot.
   *
   * @param queryStatus - UIStatus to show that the metrics are being fetched
   * @param selectedMetrics - Array of the metrics that the user has selected.
   * @param vectorLookup - Mapping of vector indices to metric strings.
   * @param setMetrics - Function to update the array of selected metrics.
   * @returns Semantic UI Form including a Dropdown of selected metrics.
   */
  const generateHealthSummary = (
    queryStatus: UIStatus,
    selectedMetrics: Array<string>,
    vectorLookup: Record<number, string>,
    setMetrics: Function,
  ) => (
    <Form inverted>
      <Form.Field>
        <Form.Dropdown
          loading={queryStatus.indeterminate}
          disabled={queryStatus.indeterminate}
          placeholder={
            queryStatus.indeterminate
              ? "Loading metrics ..."
              : "Select metrics ..."
          }
          noResultsMessage="No metrics found."
          fluid
          search
          selection
          clearable
          required
          multiple
          labeled
          value={selectedMetrics}
          options={Object.entries(vectorLookup).flatMap(([index, label]) => {
            const foundFormatted = Object.keys(metricFormatted).includes(label);
            const foundDescription = Object.keys(metricLookup).includes(label);

            if (!foundFormatted) {
              console.warn(
                `Could not find formatted representation for ${label}`,
              );
            }
            if (!foundDescription) {
              console.warn(`Could not find description for ${label}`);
            }

            const text = foundFormatted ? metricFormatted[label] : label;
            const content = foundDescription ? metricLookup[label] : label;

            return {
              key: index,
              text: text,
              content: content,
              value: label,
              label: text,
            };
          })}
          onChange={(_, d) => {
            if (d.value) {
              setMetrics(d.value);
            }
          }}
          label="Metric(s)"
        />
      </Form.Field>
      {generateChart}
    </Form>
  );

  /**
   * React Effect to find all Stream Health Monitor workflows and the related namespaces. Performed once per page load.
   */
  React.useEffect(() => {
    analysisApi
      .getWorkflows({
        name: "*Stream*Health*Monitor*Model*",
        organisation: "dch",
      })
      .then(
        (r) => {
          setGetWorkflowsStatus((prevState) =>
            prevState.setIndeterminate(true),
          );
          const l =
            r._embedded?.workflows?.flatMap<Promise<Workflow>>((w) =>
              w.id != null ? [analysisApi.getWorkflowsId(w.id)] : [],
            ) || [];

          return reducePromises(l);
        },
        () => {
          console.error("Failed to find valid workflows");
          return new Array<Workflow>();
        },
      )
      .then((l) => {
        const namespaces = l.flatMap((wf) => {
          const smn = wf._embedded?.graph?._embedded?.nodes?.filter(
            (x) => x.id === "streams_to_monitor_node",
          );

          if (smn != null && smn.length > 0 && smn[0].id != null) {
            const doc_id = getGraphNodeDocumentId(smn[0]);
            if (!doc_id) {
              return [];
            }

            return analysisApi
              .getDocumentValue(doc_id)
              .then(
                (v) => {
                  return Promise.resolve(
                    (v as StreamHealthMonitorDoc).stream_checks
                      .filter(
                        (x) =>
                          x.missing_data_logger != null &&
                          x.missing_data_logger.report_configuration != null,
                      )
                      .flatMap((x) => {
                        const outages =
                          wf._embedded?.graph?._embedded?.nodes?.filter(
                            (x) => x.id === "outage_node",
                          );
                        if (
                          outages != null &&
                          outages.length > 0 &&
                          outages[0] != null
                        ) {
                          const gn = outages[0] as GraphNodeSingle;
                          if (gn.documentid) {
                            analysisApi
                              .getDocumentValue(gn.documentid)
                              .then((docVal) => {
                                if (docVal !== undefined && docVal !== null) {
                                  setOutageStreams((v) => {
                                    v[
                                      x.missing_data_logger.report_configuration.namespace
                                    ] = docVal
                                      .toString()
                                      .split(",")
                                      .filter(
                                        (outageStreamID) =>
                                          outageStreamID !== "",
                                      );
                                    return v;
                                  });
                                }
                              });
                          }
                        }

                        return x.missing_data_logger.report_configuration;
                      }),
                  );
                },
                () => {
                  console.error("Invalid Missing Data Logger node detected.");
                  return [];
                },
              )
              .then((r) => r);
          }
          return [];
        });

        Promise.all(namespaces).then(
          (vals) => {
            vals = vals.filter((x) => x.length !== 0);
            setAvailableOutputs(([] as ReportConfiguration[]).concat(...vals));
          },
          () => {},
        );
      })
      .finally(() =>
        setGetWorkflowsStatus((prevState) => prevState.setIndeterminate(false)),
      );
  }, []);

  const resetState = () => {
    setStreamIDs([]);
    setGroupIDs([]);
    setSummaryID("");
    setStreamData([]);
    setMetrics(["missing_percent", "outlier_percent"]);
    setSelectedGroupIDs([]);
    setVectorLookup({});
    setInvalidNamespace(false);
    setAwaitingSelection(true);
  };

  /**
   * React effect to get observations for the summary stream and any pre-baked group aggregates.
   * N.B. We don't get all streams here to reduce performance overhead!
   *
   * @requires streamIds - Group IDs and Summary stream ID for the chosen workflow.
   */
  const reloadObservations = React.useCallback((streamIds: StreamId[]) => {
    if (
      streamIds.length === 0 ||
      (streamIds.length === 1 && streamIds[0] === "")
    )
      return;

    setGetObservationsStatus((prevState) => prevState.setIndeterminate(true));

    getStreamsMinMaxUsingIds(streamIds)(
      ({ max }) => {
        // Get observation data for all of the retrieved streams
        // Gather observations for all streams so we can calculate metrics on the frontend
        getObservationsCustom(
          streamIds as StreamId[],
          max && subYears(max, 1).toISOString(),
          max && max.toISOString(),
        ).then(
          (obs: Observation[]) => {
            setStreamData(obs);
            setGetObservationsStatus((prevState) =>
              prevState.setIndeterminate(false),
            );
          },
          () => {
            setStreamData([]);
            setGetObservationsStatus((prevState) =>
              prevState.setError("Unable to load observations."),
            );
          },
        );
      },
      (errorStr) => {
        setGetObservationsStatus((p) => p.setError(errorStr));
      },
    );
  }, []);

  /**
   * React Effect to find all vector streams (+summary and groups). Performed once per change in namespace.
   * @requires workflowNamespace - Namespace selected by the user to find streams and groups for.
   */
  React.useEffect(() => {
    if (
      workflowNamespace === "" ||
      disabledNamespaces.includes(workflowNamespace)
    ) {
      setGroupIDs([]);
      setSelectedGroupIDs([]);
      setStreamIDs([]);
      setAwaitingSelection(true);
      return;
    }
    setAwaitingSelection(false);
    setGetStreamsWithoutLimitStatus((prevState) =>
      prevState.setIndeterminate(true),
    );

    getStreamsWithoutLimit((workflowNamespace + "*") as StreamId, []).then(
      (streams: Array<Stream>) => {
        // Filter our stream results to only display those that are metrics for individual streams
        setStreamIDs(
          regexFilter(
            streams.flatMap((x) => x.id as string),
            `^.*..*.stream.metrics$`,
          ),
        );
        // Filter out groups/aggregates
        const groupIDs = regexFilter(
          streams.flatMap((x) => x.id as string),
          `^.*..*.group.metrics$`,
        ).filter((x) => !x.includes("._summary."));
        setGroupIDs(groupIDs);
        setSelectedGroupIDs(groupIDs);
        // Filter out the summary aggregate
        const summaryIDs = regexFilter(
          streams.flatMap((x) => x.id as string),
          `^.*._summary.group.metrics$`,
        );
        const summaryID =
          summaryIDs.length > 0 ? (summaryIDs[0] as string) : "";
        setInvalidNamespace(false);
        if (summaryIDs.length === 0) {
          console.error("No summary stream found for workflow output.");
          const newDisabledNamespaces = disabledNamespaces.concat([
            workflowNamespace,
          ]);
          setDisabledNamespaces(newDisabledNamespaces);
          setInvalidNamespace(true);
          setAwaitingSelection(true);
        } else {
          setSummaryID(summaryID);

          // Find the layout of metrics within the vector streams and store in our state
          if (summaryID.length > 0) {
            var vectorLookupResponse: Record<string, string>;
            try {
              vectorLookupResponse = (
                streams.find((x) => x.id === summaryID)
                  ?.usermetadata as MissingDataLoggerPreviousVectorLookup
              )._models.missing_logger;
            } catch {
              vectorLookupResponse = (
                streams.find((x) => x.id === summaryID)?.usermetadata as Record<
                  string,
                  any
                >
              )[workflowNamespace]["missing_logger"];
            }

            setVectorLookup(vectorLookupResponse);

            setStreamLastDataHealth((v) => {
              streams.forEach((s) => {
                if (
                  s.id !== undefined &&
                  s.resultsSummary !== undefined &&
                  s.resultsSummary.last !== undefined &&
                  s.resultsSummary.last.t !== undefined
                ) {
                  const lastVal = s.resultsSummary?.last?.v?.v as Array<number>;
                  v[s.id] =
                    100 -
                    lastVal[
                      Object.values(vectorLookupResponse).indexOf(
                        "missing_percent",
                      )
                    ];
                }
              });
              return v;
            });
          }
          setAwaitingSelection(false);
        }

        const streamIds =
          summaryID && summaryID !== "" ? [summaryID, ...groupIDs] : groupIDs;

        reloadObservations(streamIds as StreamId[]);

        setGetStreamsWithoutLimitStatus((prevState) =>
          prevState.setIndeterminate(false),
        );
      },
      (e) =>
        setGetStreamsWithoutLimitStatus((prevState) => prevState.setError(e)),
    );
  }, [workflowNamespace, disabledNamespaces, reloadObservations]);

  /**
   * Function to generate a React Semantic UI Dimmer component when waiting on user input
   *
   * @param active - Flag to set whether the dimmer is active or not.
   * @returns React Semantic UI Dimmer component to use on the parent body.
   */
  const dimmerContent = (active: boolean) => (
    <Dimmer active={active}>
      <Header as="h5" icon inverted>
        <Divider hidden />
        <div>Select a site to load data</div>
      </Header>
    </Dimmer>
  );

  const generateDataInspector = React.useMemo(
    () => (
      <UIStatusWrapper
        status={UIStatus.from([
          getStreamsWithoutLimitStatus,
          getObservationsStatus,
          getWorkflowsStatus,
        ])}
        loadingDataMsg="Loading..."
        css={tw`z-10`}
      >
        <Dimmer.Dimmable
          as={Segment}
          blurring
          inverted
          dimmed={awaitingSelection}
          css={tw`z-0`}
        >
          {dimmerContent(awaitingSelection)}
          <Dimmer.Inner disabled />

          <DataInspector
            workflowNamespace={workflowNamespace}
            streamIDs={workflowStreamIDs}
            streamLastMP={streamLastDataHealth}
            outageStreams={outageStreams}
            vectorLookup={vectorLookup}
            sensorAPI={sensorApi}
            addToInspector={streamToAddToInspector}
          />
        </Dimmer.Dimmable>
      </UIStatusWrapper>
    ),
    [
      getStreamsWithoutLimitStatus,
      getObservationsStatus,
      getWorkflowsStatus,
      awaitingSelection,
      workflowNamespace,
      workflowStreamIDs,
      streamLastDataHealth,
      outageStreams,
      vectorLookup,
      streamToAddToInspector,
    ],
  );

  const mergedStatus = UIStatus.from([
    getStreamsWithoutLimitStatus,
    getObservationsStatus,
    getWorkflowsStatus,
  ]);

  return (
    <Page>
      <DchModal
        header="Invalid Site"
        content="Health data for the site could not be gathered. Ensure that the workflow is setup correctly and that it has run at least once."
        open={invalidNamespace}
        onClose={() => resetState()}
        onConfirm={() => resetState()}
        hideCancel={true}
        confirmText="OK"
      />
      <PageTitle primaryHeader="Data Health" subHeader="Site Details" />
      <div css={tw`z-20`}>
        {generateSiteDetails(getStreamsWithoutLimitStatus, available_outputs)}
      </div>
      <Divider />

      <Segment.Group>
        <h2 css={tw`font-bold text-xl text-core-grey`}>Data details</h2>
        <UIStatusWrapper status={mergedStatus} loadingDataMsg="Loading...">
          <Dimmer.Dimmable
            as={SegmentGroup}
            blurring
            dimmed={awaitingSelection}
            css={tw`z-0`}
          >
            {dimmerContent(awaitingSelection)}

            <Segment inverted css={tw`z-10`}>
              Building(s)
              <SelectInput
                placeholder={
                  mergedStatus.indeterminate
                    ? "Loading building(s)..."
                    : "Select building(s)..."
                }
                noOptionsMessage={
                  mergedStatus.indeterminate
                    ? "Loading building(s)..."
                    : workflowGroupIDs.length === 0
                      ? "No buildings found."
                      : ""
                }
                disabled={
                  workflowNamespace === "" || workflowGroupIDs.length === 0
                }
                options={workflowGroupIDs.flatMap((d) => {
                  return {
                    key: d,
                    text: d,
                    value: d,
                  };
                })}
                isMulti
                value={selectedGroupIDs}
                onChange={(_, d: any) => {
                  if (d !== undefined) {
                    if (d.value.length === 0) {
                      setSelectedGroupIDs(workflowGroupIDs);
                    } else {
                      setSelectedGroupIDs(d.value);
                    }
                  }
                }}
              />
            </Segment>

            <Segment inverted>
              <DataDetailsPane
                invalidNamespace={invalidNamespace}
                streamData={streamData}
                streamLastMP={streamLastDataHealth}
                vectorLookup={vectorLookup}
                workflowGroupIDs={workflowGroupIDs}
                workflowNamespace={workflowNamespace}
                workflowStreamIDs={workflowStreamIDs}
                workflowSummaryID={workflowSummaryID}
                selectedGroupIDs={selectedGroupIDs}
                dataTrendChart={generateHealthSummary(
                  mergedStatus,
                  selectedMetrics,
                  vectorLookup,
                  setMetrics,
                )}
              />
            </Segment>
          </Dimmer.Dimmable>
        </UIStatusWrapper>
      </Segment.Group>

      <Divider />
      <h2 css={tw`font-bold text-xl text-core-grey`}>Stream Health</h2>
      <UIStatusWrapper
        status={mergedStatus}
        loadingDataMsg="Loading..."
        css={tw`z-10`}
      >
        <Dimmer.Dimmable
          as={Segment}
          blurring
          inverted
          dimmed={awaitingSelection}
          css={tw`z-0`}
        >
          {dimmerContent(awaitingSelection)}
          <Dimmer.Inner disabled />
          <OutageTable
            namespace={workflowNamespace}
            outageDataHealth={streamLastDataHealth}
            outageStreams={workflowStreamIDs.map((x) =>
              x
                .replace(workflowNamespace + ".", "")
                .replace(".stream.metrics", ""),
            )}
            addToInspector={setStreamToAddToInspector}
          />
        </Dimmer.Dimmable>
      </UIStatusWrapper>
      <Divider />
      <h2 css={tw`font-bold text-xl text-core-grey`}>Data Inspector</h2>
      {generateDataInspector}
    </Page>
  );
};

export default DataHealth;
