/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import tw from "twin.macro";
import { Form, PageSection } from "components/shared";
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Icon,
} from "semantic-ui-react";
import { TIMESERIES_IMMUTABLE_HELPER_TEXT } from "../helpers";
import { TimeSeriesBackingResourceType } from "data/Aletheia";
import { FormMode } from "./common";

const options = Object.values(TimeSeriesBackingResourceType).map((x) => ({
  value: x,
  text: x,
}));

export const AdminFormDetails = ({ formMode }: { formMode: FormMode }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);
  return (
    <Accordion inverted className="borderless">
      <AccordionTitle
        className="no-indent"
        active={isAccordionOpen}
        index={0}
        onClick={() => setIsAccordionOpen((p) => !p)}
      >
        <Icon name="dropdown" />
        <h4 css={tw`inline`}>Advanced Configuration</h4>
      </AccordionTitle>
      <AccordionContent active={isAccordionOpen} className="default-font-size">
        <PageSection
          header="Timeseries Tracking"
          greyBg={true}
          isSubContent={true}
        >
          <Form.CheckboxInput
            toggle
            name="timeseriesImmutable"
            label="Timeseries Immutable"
            helperText={TIMESERIES_IMMUTABLE_HELPER_TEXT}
            verticalLayout
            required
          />
          {formMode === FormMode.create && (
            <Form.CheckboxInput
              dataTestId="sync-timeseries"
              toggle
              name="syncTimeseries"
              label="Sync Timeseries Data"
              verticalLayout
              helperText="After creating the pool, force a search for timeseries tracked in the DCH backing database referred to by 'Backing Resource ID'. Only required for archived/infrequently updated data."
            />
          )}
          <Form.SelectInput
            required={formMode === FormMode.edit}
            name="backingResourceType"
            label="Backing Resource Type"
            options={options}
            verticalLayout
            isClearable={false}
          />
          <Form.TextInput
            required={formMode === FormMode.edit}
            name="backingResourceId"
            label="Backing Resource ID"
            verticalLayout
          />
        </PageSection>
      </AccordionContent>
    </Accordion>
  );
};
