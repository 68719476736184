export type ObservationData = {
  t: Date;
  p: string;
  n?: number;
  v?: number[];
  a?: string;
  d?: string;
};

export enum SortOrder {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}
export type DownloadPostParams = {
  start?: string;
  end?: string;
  si?: boolean;
  ei?: boolean;
  limit?: number;
  sort?: SortOrder;
};

export type DownloadPostRequest = {
  points: string[];
};

export type DownloadPostResponseMetadata = {
  $schema: string;
  startTime: Date;
  endTime: Date;
  count: number;
  points: { [key: string]: string };
  self: string;
};

type DownloadPostResponseData = ObservationData[];

export type DownloadPostResponse = {
  metadata: DownloadPostResponseMetadata;
  data: DownloadPostResponseData;
};

type UploadPostRequestMetadata = {
  points: { [key: string]: string };
};

type UploadPostRequestData = ObservationData[];

export type UploadPostRequest = {
  metadata: UploadPostRequestMetadata;
  data: UploadPostRequestData;
};
