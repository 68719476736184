/** @jsxImportSource @emotion/react */
import React, { useContext, useState } from "react";

import {
  EMPTY_DESCRIPTION,
  EMPTY_LICENCE,
  PageSection,
  TextInput,
} from "components/shared";
import { DataPoolResponse } from "data/Aletheia";
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Icon,
} from "semantic-ui-react";
import {
  ADMIN_AUTHORITY_ID,
  TIMESERIES_IMMUTABLE_HELPER_TEXT,
} from "../helpers";
import { AuthorityContext } from "App";
import tw from "twin.macro";

type ConfigContentProps = {
  dataPool: DataPoolResponse;
};
export const ConfigContent = ({ dataPool }: ConfigContentProps) => {
  const { assignedAuthorityId } = useContext(AuthorityContext);
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);

  return (
    <>
      <TextInput label="ID" value={dataPool.id} isReadOnly verticalLayout />
      <TextInput label="UID" value={dataPool.uid} isReadOnly verticalLayout />
      <TextInput
        label="Description"
        value={dataPool.description ?? EMPTY_DESCRIPTION}
        isReadOnly
        verticalLayout
      />
      <TextInput
        label="License"
        value={dataPool.license}
        link={dataPool.license}
        isReadOnly
        verticalLayout
        readOnlyEmptyText={EMPTY_LICENCE}
      />

      <TextInput
        label="Timeseries Immutable"
        value={dataPool.timeseriesImmutable ? "Yes" : "No"}
        isReadOnly
        verticalLayout
        helperText={TIMESERIES_IMMUTABLE_HELPER_TEXT}
      />

      {assignedAuthorityId && assignedAuthorityId === ADMIN_AUTHORITY_ID && (
        <Accordion inverted className="borderless">
          <AccordionTitle
            className="no-indent"
            active={isAccordionOpen}
            index={0}
            onClick={() => setIsAccordionOpen((p) => !p)}
          >
            <Icon name="dropdown" />
            <h4 css={tw`inline`}>Advanced Configuration</h4>
          </AccordionTitle>
          <AccordionContent
            active={isAccordionOpen}
            className="default-font-size"
          >
            <PageSection
              header="Timeseries Tracking"
              greyBg={true}
              isSubContent={true}
            >
              <TextInput
                label="Backing Resource Type"
                verticalLayout
                isReadOnly
                value={dataPool.timeseriesTracking.backingResourceType}
              />
              <TextInput
                label="Backing Resource ID"
                verticalLayout
                isReadOnly
                value={dataPool.timeseriesTracking.backingResourceId}
              />
            </PageSection>
          </AccordionContent>
        </Accordion>
      )}
    </>
  );
};
