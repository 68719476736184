import { useEffect, useRef, useContext } from "react";
import {
  FieldPath,
  FieldValues,
  UseFormGetFieldState,
  useFormContext,
} from "react-hook-form";
import { EnodiaOrgContext } from "App";

export const ASYNC_ERROR_SUFFIX = "_asyncError";

export const hasAsyncError = <T extends FieldValues>(
  formFieldName: FieldPath<T>,
  getFieldState: UseFormGetFieldState<T>,
) => {
  const asyncFieldError = getFieldState(
    `${formFieldName}${ASYNC_ERROR_SUFFIX}` as FieldPath<T>,
  );
  return asyncFieldError?.error?.message;
};

export const useAsyncValidate = <T extends FieldValues>(
  formFieldName: FieldPath<T>,
  asyncValidationFxn: (s: string) => Promise<string | undefined>,
) => {
  const { watch, setError, clearErrors, trigger } = useFormContext<T>();
  const watchText = watch(formFieldName);
  const textRef = useRef(watchText);
  if (textRef.current !== watchText) {
    textRef.current = watchText;
    asyncValidationFxn(watchText).then((result: string | undefined) => {
      if (result) {
        setError(`${formFieldName}${ASYNC_ERROR_SUFFIX}` as FieldPath<T>, {
          type: "asyncError",
          message: result,
        });
      } else {
        clearErrors(`${formFieldName}${ASYNC_ERROR_SUFFIX}` as FieldPath<T>);
        trigger(formFieldName);
      }
    });
  }
};

/**
 * if on form and org is changed, async validate the input again in the context of new org
 */
export const useAsyncValidateOnOrgChange = <T extends FieldValues>(
  formFieldName: FieldPath<T>,
  asyncValidationFxn: (s: string) => Promise<string | undefined>,
) => {
  const { watch, setError, clearErrors, trigger } = useFormContext<T>();
  const watchText = watch(formFieldName);
  const { orgId } = useContext(EnodiaOrgContext);
  useEffect(() => {
    if (orgId) {
      asyncValidationFxn(watchText).then((result: string | undefined) => {
        if (result) {
          setError(`${formFieldName}${ASYNC_ERROR_SUFFIX}` as FieldPath<T>, {
            type: "asyncError",
            message: result,
          });
        } else {
          clearErrors(`${formFieldName}${ASYNC_ERROR_SUFFIX}` as FieldPath<T>);
          trigger(formFieldName);
        }
      });
    }
    // only want to track on org change, nothing else
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);
};
