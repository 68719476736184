/** @jsxImportSource @emotion/react */
import React, { useCallback, useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import { Tooltip } from "react-tooltip";
import { Button, Divider, Icon } from "semantic-ui-react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Path } from "Routes";
import { OrgId } from "data/Enodia";
import { BuildingId, SiteId } from "data/brick";
import { DependantsOfDatapoolResponse, masonApi } from "data/Mason";
import {
  createCompositeId,
  Header,
  Page,
  PageActions,
  PageTitle,
  redirectMessage,
  Tabs,
  UIStatus,
  UIStatusWrapper,
  WarningMessage,
} from "components/shared";
import {
  aletheiaApi,
  DataPoolCompositeId,
  DataPoolId,
  DataPoolResponse,
  DataSourceUid,
} from "data/Aletheia";
import {
  PointTablePageState,
  usePointTablePageState,
} from "../../PointTable/usePointTablePageState";
import { PointTable } from "../../PointTable/PointTable";
import { ConfigContent } from "./ConfigurationContent";
import { MANAGED_BY_DS_HELPER_TEXT, resToLinkedModel } from "../helpers";
import { LinkedModelContent } from "./LinkedModelContent";
import OrgContextModal from "context/OrgContextModal";
import { EnodiaOrgContext } from "App";
import DeleteDataPoolButton from "../DeleteDataPoolButton";

type Params = { orgId: string; id: DataPoolId };

enum DataPoolTab {
  Points = "points",
  Configuration = "configuration",
  LinkedModels = "linked models",
}

export type LinkedModel = {
  siteId: SiteId;
  isLinked: boolean;
  buildingIds?: BuildingId[];
};

export const DataPool = () => {
  const { orgId, id } = useParams<Params>();
  const { orgId: contextOrgId } = useContext(EnodiaOrgContext);
  const navigate = useNavigate();
  const location = useLocation();
  const pageState = usePointTablePageState();

  const [isOrgContextModalOpen, setIsOrgContextModalOpen] =
    React.useState(false);
  const [status, setStatus] = useState<UIStatus>(new UIStatus());

  // cant use above status, as it causes race condition between the 2 in terms of displaying the loader
  const [linkedModelStatus, setLinkedModelStatus] = useState<UIStatus>(
    new UIStatus(),
  );
  const [selectedTab, setSelectedTab] = useState<DataPoolTab>(
    DataPoolTab.Points,
  );
  const [dataPool, setDataPool] = useState<DataPoolResponse>();
  const [dataSourceId, setDataSourceId] = useState<string>();
  const [linkedModelResponse, setLinkedModelResponse] =
    useState<DependantsOfDatapoolResponse>();

  useEffect(() => {
    // DCH-5510: Wait until the datapool ID has settled to fetch points
    if (pageState.state.pointSearchParams.datapoolid !== undefined) {
      pageState.getPoints();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageState.state.pointSearchParams.datapoolid,
    pageState.state.pointSearchParams.id,
    pageState.state.pointSearchParams.unit,
  ]);

  const fetchDataPool = useCallback(() => {
    if (id && orgId) {
      setStatus((prev) => prev.setIndeterminate(true));

      aletheiaApi
        .getDataPoolById(createCompositeId(orgId, id) as DataPoolCompositeId)
        .then((dp) => {
          setDataPool(dp);
          pageState.setDataPoolId(dp.compositeId);
          return dp.datasourceId;
        })
        // payload only provides datasource UID, but we need human-readable-id for <Link>
        .then((dsUid) => {
          if (dsUid) {
            aletheiaApi.getDatasource(dsUid as DataSourceUid).then((res) => {
              setDataSourceId(res.id);
            });
          }
        })
        .then(() => setStatus((prev) => prev.setSuccessful(true)))
        .catch((e) => setStatus((p) => p.setError(e.message)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, orgId]);

  const fetchLinkedModels = useCallback(() => {
    if (id && orgId) {
      setLinkedModelStatus((prev) => prev.setIndeterminate(true));
      masonApi
        .getDepedantsOfDatapool(orgId as OrgId)(id)
        .then((r) => setLinkedModelResponse(r))
        .then(() => setLinkedModelStatus((p) => p.setSuccessful(true)))
        .catch((e) => setLinkedModelStatus((p) => p.setError(e.message)));
    }
  }, [id, orgId]);

  useEffect(() => {
    fetchDataPool();
    fetchLinkedModels();
  }, [fetchDataPool, fetchLinkedModels]);

  return (
    <Page backTo={location.state?.["backTo"]} hasGoBack>
      <PageTitle primaryHeader="Data Pools" pageAction={PageActions.View} />
      <OrgContextModal
        open={isOrgContextModalOpen}
        openCondition={orgId !== contextOrgId}
        onConfirm={() => {
          navigate(Path.DataPools);
        }}
        modalMessage={redirectMessage}
        setModalState={setIsOrgContextModalOpen}
      />
      <UIStatusWrapper status={status}>
        {dataPool && (
          <div css={tw`flex flex-col gap-4 bg-core-grey-dark rounded p-4`}>
            <Header
              name={
                dataPool.name !== "" && dataPool.name !== undefined
                  ? dataPool.name
                  : dataPool.id
              }
              subHeader={
                <div css={tw`flex flex-col gap-2`}>
                  <span>
                    {dataPool.compositeId}{" "}
                    <Icon
                      link
                      name="copy"
                      data-tooltip-id={`copy-icon-tooltip`}
                      data-tooltip-content={"Copy to clipboard"}
                      onClick={() => {
                        navigator.clipboard.writeText(dataPool.compositeId);
                      }}
                    />
                    <Tooltip id={`copy-icon-tooltip`} css={tw`z-5`} />
                  </span>
                  {dataSourceId && (
                    <span>
                      <Icon name="linkify" />
                      <Link
                        css={tw`underline`}
                        to={`/datasource/${orgId}/${dataSourceId}`}
                      >
                        Parent Data Source
                      </Link>
                    </span>
                  )}
                </div>
              }
              actions={
                <>
                  {dataPool.datasourceId ? (
                    <WarningMessage
                      css={tw`max-w-80`}
                      content={`${MANAGED_BY_DS_HELPER_TEXT} or deleted.`}
                    />
                  ) : (
                    <Button.Group vertical labeled icon>
                      <Button
                        disabled={!!dataPool.datasourceId}
                        primary
                        onClick={() =>
                          navigate(`/datapool/${orgId}/${id}/edit`, {
                            state: { ...dataPool },
                          })
                        }
                      >
                        <Icon name="pencil" />
                        Edit Data Pool
                      </Button>
                      <DeleteDataPoolButton
                        disabled={!!dataPool.datasourceId}
                        asButton={true}
                        dataPool={dataPool}
                        onRemoved={() => navigate(Path.DataPools)}
                      />
                    </Button.Group>
                  )}
                </>
              }
            />
            <Divider />
            <Tabs<DataPoolTab>
              tabs={Object.values(DataPoolTab)}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            <PageDisplay
              selectedTab={selectedTab}
              pageState={pageState}
              dataPool={dataPool}
              linkedModelResponse={linkedModelResponse}
              linkedModelStatus={linkedModelStatus}
            />
          </div>
        )}
      </UIStatusWrapper>
    </Page>
  );
};

const PageDisplay = ({
  selectedTab,
  pageState,
  dataPool,
  linkedModelResponse,
  linkedModelStatus,
}: {
  selectedTab: DataPoolTab;
  pageState: PointTablePageState;
  dataPool: DataPoolResponse;
  linkedModelResponse?: DependantsOfDatapoolResponse;
  linkedModelStatus: UIStatus;
}) => {
  switch (selectedTab) {
    case DataPoolTab.Configuration:
      return <ConfigContent dataPool={dataPool} />;
    case DataPoolTab.LinkedModels:
      return (
        <UIStatusWrapper status={linkedModelStatus}>
          <LinkedModelContent
            linkedModels={resToLinkedModel(linkedModelResponse)}
            buildingsLength={linkedModelResponse?.linkedBuildings.length ?? 0}
            sitesLength={linkedModelResponse?.linkedSites.length ?? 0}
          />
        </UIStatusWrapper>
      );
    default:
      return <PointTable pageState={pageState} />;
  }
};
