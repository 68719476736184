import React, { useState } from "react";
import { Button, Grid } from "semantic-ui-react";
import { PointListItem, PointResponse } from "data/Aletheia";
import { downloadCsv } from "data/utils";
import { UIStatus } from "components/shared";
import {
  DEFAULT_AGG_RESULTS_LIMIT,
  DOWNLOAD_ALL_LIMIT_CAP,
  fetchPointsDataCsv,
  PointChart,
} from ".";
import { DateRange } from "./ChartUtils";

type DataStreamProps = {
  point: PointListItem | PointResponse;
  mountNodeId?: string;
};

const PointData: React.FC<DataStreamProps> = ({ point, mountNodeId }) => {
  const [downloadUiStatus, setDownloadUiStatus] = useState(new UIStatus());
  const [debouncedLimit, setDebouncedLimit] = useState<number>(
    DEFAULT_AGG_RESULTS_LIMIT,
  );
  const [dateRange, setDateRange] = useState<DateRange>();

  const renderPointChart = React.useCallback(
    () => (
      <PointChart
        points={[point] as PointResponse[]}
        mountNodeId={mountNodeId}
        debouncedLimit={debouncedLimit}
        setDebouncedLimit={setDebouncedLimit}
        setParentDateRange={setDateRange}
      />
    ),
    [point, mountNodeId, debouncedLimit],
  );

  const fetchCsv = () => {
    setDownloadUiStatus((prev) => prev.setIndeterminate(true));
    fetchPointsDataCsv([point] as PointResponse[], {
      limit: debouncedLimit,
      start: dateRange?.start ? dateRange?.start.toISOString() : undefined,
      end: dateRange?.end ? dateRange?.end.toISOString() : undefined,
    })
      .then((res) => {
        downloadCsv(res, point.id);
        setDownloadUiStatus((prev) => prev.setSuccessful(true));
      })
      .catch((e) => setDownloadUiStatus((p) => p.setError(e.message)));
  };

  const downloadAllCsv = () => {
    setDownloadUiStatus((prev) => prev.setIndeterminate(true));
    fetchPointsDataCsv([point] as PointResponse[], {
      limit: DOWNLOAD_ALL_LIMIT_CAP,
    })
      .then((res) => {
        downloadCsv(res, point.id);
        setDownloadUiStatus((prev) => prev.setSuccessful(true));
      })
      .catch((e) => setDownloadUiStatus((p) => p.setError(e.message)));
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>{renderPointChart()}</Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Button
            primary
            onClick={() => fetchCsv()}
            disabled={downloadUiStatus.indeterminate}
            loading={downloadUiStatus.indeterminate}
          >
            Download Selected Range CSV
          </Button>
          <Button
            primary
            onClick={() => downloadAllCsv()}
            disabled={downloadUiStatus.indeterminate}
            loading={downloadUiStatus.indeterminate}
          >
            Download All CSV
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default PointData;
