import React, { useEffect } from "react";
import { FieldValues, useFormContext } from "react-hook-form";
import { DCHModel, ModelRootType } from "data/brick";
import {
  ModelMeta,
  transformSerialisedModelToFormFields,
} from "data/Mason/ModelDraft/ModelDraftUtils";
import { ModelProps, SelectedElement } from "../ModelUtils";
import {
  Equipment,
  EquipmentFields,
  Floor,
  FloorFields,
  ModelDetails,
  ModelDetailsFields,
  Point,
  PointFields,
  Room,
  RoomFields,
  Wing,
  WingFields,
  Zone,
  ZoneFields,
} from "./EntityForms";
import { getCollectionName, getFieldArray } from "./ModelFormUtils";

export type FormFieldArrayType =
  | WingFields
  | FloorFields
  | RoomFields
  | ZoneFields
  | EquipmentFields
  | PointFields;

export interface ModelFormFields extends FieldValues {
  modelDetails: ModelDetailsFields;
  wings: WingFields[];
  floors: FloorFields[];
  rooms: RoomFields[];
  zones: ZoneFields[];
  equipment: EquipmentFields[];
  points: PointFields[];
}

type ModelFormProps = {
  isReadOnly?: boolean;
  modelType: ModelRootType;
  modelMeta: ModelMeta;
  fetchedModel?: DCHModel;
  selectedElement: SelectedElement;
  fieldArrays: Omit<ModelFormFields, "modelDetails">;
  deleteNode: (selectedElement: SelectedElement, index: number) => void;
} & ModelProps;

export const SiteOrBuildingModelForm = (props: ModelFormProps) => {
  const { fetchedModel, fieldArrays, selectedElement, deleteNode } = props;
  const { getValues, reset } = useFormContext<ModelFormFields>();

  useEffect(() => {
    if (fetchedModel) {
      const formValues = transformSerialisedModelToFormFields(fetchedModel);
      reset(formValues);
    }
  }, [fetchedModel, reset]);

  if (selectedElement.type === "model") return <ModelDetails {...props} />;

  const entities = getFieldArray(fieldArrays, selectedElement.type);
  return entities ? (
    <>
      {entities.map((entity, index) => {
        const entityId = getValues(
          `${getCollectionName(selectedElement.type)}.${index}.fieldId`,
        );
        if (selectedElement.id === entityId) {
          const formProps = {
            ...props,
            index,
            deleteNode: () => deleteNode(selectedElement, index),
          };
          switch (selectedElement.type) {
            case "wing":
              return <Wing key={entity.id} {...formProps} />;
            case "floor":
              return <Floor key={entity.id} {...formProps} />;
            case "room":
              return <Room key={entity.id} {...formProps} />;
            case "zone":
              return <Zone key={entity.id} {...formProps} />;
            case "equipment":
              return <Equipment key={entity.id} {...formProps} />;
            case "point":
              return (
                <Point key={entity.id} {...formProps} entityId={entityId} />
              );
          }
        }
        return null;
      })}
    </>
  ) : (
    <></>
  );
};
