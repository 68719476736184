/** @jsxImportSource @emotion/react */
import React, { useContext } from "react";
import { Grid } from "semantic-ui-react";
import { PageSection } from "components/shared";
import Form from "components/shared/Forms/ReactHookForm";
import { isValidId, isValidName } from "data/validation";
import {
  hasAsyncError,
  useAsyncValidate,
  useAsyncValidateOnOrgChange,
} from "components/shared/Forms/useAsyncValidate";
import { checkDataSourceId } from "./CreateDataSourceIDValidation";
import { useFormContext } from "react-hook-form";
import { EnodiaOrgContext } from "App";
import {
  CreateOrUpdateDataSourceFormValues,
  DataSourceFormMode,
} from "./FormHelpers";

type DataSourceDetailProps = {
  formMode: DataSourceFormMode;
};

export const DataSourceDetails = ({ formMode }: DataSourceDetailProps) => {
  const { orgId } = useContext(EnodiaOrgContext);
  const { formState, getFieldState } = useFormContext();

  const validateDataSourceID = (s: string): Promise<string | undefined> =>
    new Promise((resolve) => {
      if (orgId && s !== "" && formState.isDirty)
        checkDataSourceId(orgId, s, resolve);
    });

  useAsyncValidate<CreateOrUpdateDataSourceFormValues>(
    "id",
    validateDataSourceID,
  );
  useAsyncValidateOnOrgChange<CreateOrUpdateDataSourceFormValues>(
    "id",
    validateDataSourceID,
  );

  return (
    <PageSection header="Details">
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Form.TextInput
              name="id"
              label="ID"
              required
              isReadOnly={formMode === DataSourceFormMode.edit}
              rules={{
                validate: {
                  isValidId,
                  hasAsyncError: () => hasAsyncError("id", getFieldState),
                },
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form.TextInput
              name="name"
              label="Name"
              required
              rules={{ validate: isValidName }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form.TextInput name="description" label="Description" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form.TextInput name="type" label="Type" isReadOnly />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form.CheckboxInput
              name="enabled"
              label="Enabled?"
              toggle
              defaultValue={true}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </PageSection>
  );
};
