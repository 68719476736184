/* @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { Button, ButtonProps, Modal, ModalProps } from "semantic-ui-react";
import tw from "twin.macro";

type Props = {
  confirmText?: string | ReactNode;
  cancelText?: string | ReactNode;
  id?: string;
  header: ReactNode;
  content: ReactNode;
  onConfirm?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: ButtonProps,
  ) => void;
  onClose: (
    event: React.MouseEvent<HTMLElement | HTMLButtonElement, MouseEvent>,
    data: ModalProps | ButtonProps,
  ) => void;
  onCancel?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: ButtonProps,
  ) => void;
  disableConfirm?: boolean;
  confirmLoading?: boolean;
  hideCancel?: boolean;
  hideConfirm?: boolean;
  submitFormId?: string;
} & Pick<ModalProps, "content" | "header" | "trigger" | "size" | "open">;

export const DchModal: React.FunctionComponent<Props> = ({
  content,
  header,
  onClose,
  onConfirm,
  onCancel,
  open,
  confirmText = "Submit",
  cancelText = "Cancel",
  id = "DchModal",
  disableConfirm = false,
  confirmLoading = false,
  hideCancel = false,
  hideConfirm = false,
  trigger,
  submitFormId,
  size,
}) => {
  const confirmButtonProps: {} = submitFormId
    ? {
        type: "submit",
        form: submitFormId,
      }
    : {
        onClick: onConfirm,
      };

  return (
    <Modal
      dimmer="blurring"
      closeIcon
      onClose={onClose}
      open={open}
      id={id}
      data-test-id={id}
      trigger={trigger}
      size={size}
      style={{ maxHeight: "95vh", containerType: "inline-size" }}
    >
      <div css={tw`flex flex-col`} style={{ maxHeight: "90cqh" }}>
        <Modal.Header>{header}</Modal.Header>
        <div css={tw`overflow-y-auto flex-1`}>
          <Modal.Content>
            <Modal.Description>{content}</Modal.Description>
          </Modal.Content>
        </div>
        <Modal.Actions>
          {!hideCancel && (
            <Button
              basic
              inverted
              onClick={onCancel ?? onClose}
              disabled={confirmLoading}
            >
              {cancelText}
            </Button>
          )}
          {!hideConfirm && (
            <Button
              data-test-id={"modal-confirm-button"}
              primary
              disabled={disableConfirm}
              loading={confirmLoading}
              {...confirmButtonProps}
            >
              {confirmText}
            </Button>
          )}
        </Modal.Actions>
      </div>
    </Modal>
  );
};
