import { DependantsOfDatapoolResponse } from "data/Mason";
import { LinkedModel } from ".";
import { BuildingId } from "data/brick";

export const ADMIN_AUTHORITY_ID = "91a9ecde-eac6-482e-9ac1-543fde5ff66b";

export const TIMESERIES_IMMUTABLE_HELPER_TEXT =
  "Prevent users from deleting the raw timeseries data from the DCH backing database when they delete a point. Use this to protect data that was shared with you.";

export const MANAGED_BY_DS_HELPER_TEXT =
  "This Data Pool is managed by its parent Data Source and cannot be edited";

export const resToLinkedModel = (
  response: DependantsOfDatapoolResponse | undefined,
) => {
  if (!response) return [];
  const sites = new Map<string, LinkedModel>();
  response.linkedSites.forEach((site) => {
    sites.set(site.first.siteId, {
      isLinked: true,
      siteId: site.first.siteId,
      buildingIds: [],
    });
  });
  response.linkedBuildings.forEach((building) => {
    const siteId = building.first.siteId;
    const buildingId = building.first.buildingId;
    const existingSite = sites.get(siteId);

    if (existingSite) {
      // append the buildings under this site
      sites.set(siteId, {
        ...existingSite,
        buildingIds: [
          ...(existingSite.buildingIds as BuildingId[]),
          buildingId,
        ],
      });
    } else {
      // site is not linked, so we flag as false
      sites.set(building.first.siteId, {
        siteId: siteId,
        isLinked: false,
        buildingIds: [buildingId],
      });
    }
  });
  return Array.from(sites.values());
};
