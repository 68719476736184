/** @jsxImportSource @emotion/react */
import tw, { TwStyle } from "twin.macro";

import React from "react";
import { Checkbox } from "semantic-ui-react";
import { InputValidation } from "../InputValidation";
import {
  InputProps,
  getControlledInputProps,
  getLabelProps,
  getLabelledInputLayoutProps,
} from "./InputUtils";
import { LabelledInput } from "./LabelledInput";

export type CheckboxInputProps = {
  toggle?: boolean;
  checkboxLabel?: string | React.ReactNode;
  dataTestId?: string;
  customCss?: TwStyle;
} & InputProps<boolean>;

export const CheckboxInput = (props: CheckboxInputProps) => {
  const { inputValidation, ...inputProps } = props;
  const labelProps = getLabelProps(inputProps);
  const labelledInputProps = getLabelledInputLayoutProps(props);
  const controlledInputProps = getControlledInputProps(inputProps);

  const checkboxProps = {
    toggle: inputProps.toggle,
    checked:
      controlledInputProps.value !== undefined && !!controlledInputProps.value,
    onChange: (e: any, d: any) => controlledInputProps.onChange?.(e, d),
    disabled: inputProps.disabled,
  };

  const input = (
    <>
      <div css={[tw`flex items-center`, props.customCss]}>
        <Checkbox {...checkboxProps} data-test-id={props.dataTestId} />
        <span className="subtext" css={tw`ml-4`}>
          {inputProps.checkboxLabel}
        </span>
      </div>
      {inputValidation && <InputValidation {...inputValidation} />}
    </>
  );

  return props.label ? (
    <LabelledInput {...labelProps} {...labelledInputProps} input={input} />
  ) : (
    input
  );
};
