/** @jsxImportSource @emotion/react */

import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate, Link, generatePath } from "react-router-dom";
import { Button, Divider, Icon } from "semantic-ui-react";
import { Tooltip } from "react-tooltip";
import tw from "twin.macro";

import {
  DataDecoderResponse,
  DataSourceCompositeId,
  DataSourceId,
  DataSourceResponse,
  DecoderId,
  aletheiaApi,
} from "data/Aletheia";
import { PointTable } from "../PointTable/PointTable";
import { Path } from "Routes";
import OrgContextModal from "context/OrgContextModal";
import {
  UIStatus,
  UIStatusWrapper,
  redirectMessage,
  Page,
  PageTitle,
  ProcessingStatusLabel,
  JobState,
  TextInput,
  TextArea,
  InputLabel,
  ContactEmailLink,
  Header,
  Tabs,
  createCompositeId,
  EMPTY_DESCRIPTION,
  LabelledInput,
} from "components/shared";
import { displayType, InlineCopyButton } from "./DataSourceUtils";
import RemoveDataSource from "./RemoveDataSource";
import {
  PointTablePageState,
  usePointTablePageState,
} from "../PointTable/usePointTablePageState";
import { EnodiaOrgContext } from "App";
import parse from "html-react-parser";

type Params = { orgId: string; id: DataSourceId };

enum DataSourceTab {
  Points = "points",
  Decoder = "decoder",
  Gateway = "connection",
}

const DataSource: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const pageState = usePointTablePageState();

  const { orgId, id } = useParams<Params>();
  const { orgId: contextOrgId } = useContext(EnodiaOrgContext);

  const [dataSourceStatus, setDataSourceStatus] = useState(new UIStatus());
  const [dataSource, setDataSource] = useState<DataSourceResponse>();
  const [confirmationModal, setConfirmationModal] = React.useState(false);
  const [selectedTab, setSelectedTab] = useState<DataSourceTab>(
    DataSourceTab.Points,
  );

  const fetchDataSource = React.useCallback(() => {
    if (id && orgId) {
      setDataSourceStatus((prevState) => prevState.setIndeterminate(true));
      aletheiaApi
        .getDatasource(createCompositeId(orgId, id) as DataSourceCompositeId)
        .then((ds) => {
          setDataSource(ds);
          setDataSourceStatus((prevState) => prevState.setIndeterminate(false));
          pageState.setDataPoolId(
            createCompositeId(orgId, ds.dataPoolRef.dataPoolId),
          );
        })
        .catch((e) => {
          setDataSourceStatus((prevState) =>
            prevState.setError(
              e.message ??
                "Error fetching data source: No data source with requested ID found",
            ),
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, orgId]);

  useEffect(() => {
    // DCH-5510: Wait until the datapool ID has settled to fetch points
    if (pageState.state.pointSearchParams.datapoolid !== undefined) {
      pageState.getPoints();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageState.state.pointSearchParams.datapoolid,
    pageState.state.pointSearchParams.id,
    pageState.state.pointSearchParams.unit,
  ]);

  useEffect(() => {
    fetchDataSource();
  }, [fetchDataSource, id, orgId]);

  return (
    <Page>
      <PageTitle primaryHeader={"Data Source"} />
      <OrgContextModal
        open={confirmationModal}
        openCondition={orgId !== contextOrgId}
        onConfirm={() => {
          navigate(Path.DataSources);
        }}
        modalMessage={redirectMessage}
        setModalState={setConfirmationModal}
      />
      <UIStatusWrapper
        status={dataSourceStatus}
        loadingDataMsg="Loading data source"
      >
        <div css={tw`flex flex-col gap-4 bg-core-grey-dark rounded p-4`}>
          {dataSource && (
            <div>
              <Header
                name={dataSource.name}
                subHeader={
                  <div css={tw`flex flex-col gap-2`}>
                    <div css={tw`flex flex-row gap-x-2`}>
                      <span>{dataSource.description}</span>
                    </div>
                    <div css={tw`flex gap-x-2`}>
                      <ProcessingStatusLabel
                        status={
                          dataSource.enabled
                            ? JobState.Enabled
                            : JobState.Disabled
                        }
                      />
                      <span>
                        <Icon
                          name="exchange"
                          data-tooltip-id={`type-tooltip`}
                          data-tooltip-content={"Transport Configuration Type"}
                          data-tooltip-float={true}
                        />
                        <Tooltip id={`type-tooltip`} css={tw`z-5`} />
                        {displayType(dataSource.transportConfig.type)}
                      </span>
                    </div>
                    <div>
                      <Icon
                        name="database"
                        data-tooltip-id={`point-id-tooltip`}
                        data-tooltip-content={"Data Pool ID"}
                        data-tooltip-float={true}
                      />
                      <Tooltip id={`point-id-tooltip`} css={tw`z-5`} />
                      <Link
                        to={generatePath(Path.ViewDataPool, {
                          orgId: orgId as string,
                          id: dataSource.dataPoolRef.dataPoolId,
                        })}
                        state={{ backTo: "Data Source" }}
                      >
                        {dataSource.dataPoolRef.dataPoolId}
                      </Link>
                    </div>
                  </div>
                }
                actions={
                  <Button.Group vertical labeled icon>
                    <Button
                      primary
                      onClick={() =>
                        navigate(`/datasource/${orgId}/${id}/edit`, {
                          state: { ...dataSource },
                        })
                      }
                    >
                      <Icon name="pencil" />
                      Edit Data Source
                    </Button>
                    <RemoveDataSource
                      asButton={true}
                      dataSource={dataSource}
                      onRemoved={() => navigate(-1)}
                    />
                  </Button.Group>
                }
              />
              <Divider />
              <Tabs<DataSourceTab>
                tabs={Object.values(DataSourceTab)}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
              <PageDisplay
                selectedTab={selectedTab}
                pageState={pageState}
                dataSource={dataSource}
              />
            </div>
          )}
        </div>
      </UIStatusWrapper>
    </Page>
  );
};

export default DataSource;

const DecoderContent = ({ dataSource }: { dataSource: DataSourceResponse }) => {
  const [contentState, setContentState] = useState(new UIStatus());
  const [decoderMetadata, setDecoderMetadata] = useState<DataDecoderResponse>();
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    setContentState((p) => p.setIndeterminate(true));
    aletheiaApi
      .getDataDecoderById(dataSource.decoderConfig.decoderId as DecoderId)
      .then((res) => {
        setContentState((p) => p.setIndeterminate(false));
        setDecoderMetadata(res);
      })
      .catch((e) => {
        setContentState((p) => p.setError(e.message));
      });
  }, [dataSource.decoderConfig.decoderId]);

  return (
    <UIStatusWrapper status={contentState}>
      <TextInput
        label="Decoder Type"
        value={decoderMetadata?.name}
        isReadOnly
        verticalLayout
      />
      <LabelledInput
        label="Description"
        verticalLayout
        isReadOnly
        input={
          decoderMetadata?.description ? (
            (parse(decoderMetadata?.description) as string)
          ) : (
            <i>{EMPTY_DESCRIPTION}</i>
          )
        }
      />
      {dataSource.decoderConfig.configContent && (
        <>
          <div css={tw`flex gap-2 py-2 items-center`}>
            <InputLabel label="Configuration" />
            <InlineCopyButton
              onClick={() => {
                navigator.clipboard
                  .writeText(
                    JSON.stringify(
                      dataSource.decoderConfig.configContent,
                      null,
                      4,
                    ),
                  )
                  .then(() => setIsCopied(true));
              }}
              isCopied={isCopied}
            />
          </div>
          <TextArea
            isReadOnly
            value={JSON.stringify(
              dataSource.decoderConfig.configContent,
              null,
              4,
            )}
          />
        </>
      )}
    </UIStatusWrapper>
  );
};

const GatewayContent = ({ dataSource }: { dataSource: DataSourceResponse }) => {
  const [isCopied, setIsCopied] = useState(false);
  const gatewayConfigUserName = `${dataSource.dataPoolRef.timeseriesTracking.backingResourceId}:${dataSource.transportConfig.userName}`;

  return (
    <>
      <p>
        Please contact the DCH team at <ContactEmailLink /> for help configuring
        your gateway. The username to use when configuring your gateway is:
      </p>
      <InputLabel label="Gateway Configuration Username" />
      <div css={tw`flex items-center gap-2 mt-2 mb-4`}>
        <div css={tw`border border-solid px-4 `}>
          <pre className="dch-scrollbar">{gatewayConfigUserName}</pre>
        </div>
        <div>
          <InlineCopyButton
            onClick={() => {
              navigator.clipboard
                .writeText(gatewayConfigUserName)
                .then(() => setIsCopied(true));
            }}
            isCopied={isCopied}
          />
        </div>
      </div>
    </>
  );
};

const PageDisplay = ({
  selectedTab,
  pageState,
  dataSource,
}: {
  selectedTab: DataSourceTab;
  pageState: PointTablePageState;
  dataSource: DataSourceResponse;
}) => {
  switch (selectedTab) {
    case DataSourceTab.Decoder:
      return <DecoderContent dataSource={dataSource} />;
    case DataSourceTab.Gateway:
      return <GatewayContent dataSource={dataSource} />;

    default:
      return <PointTable pageState={pageState} />;
  }
};
