/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { Button, Grid } from "semantic-ui-react";

import { genStrongPassword } from "data/password";
import { PageSection } from "components/shared";
import Form from "components/shared/Forms/ReactHookForm";
import { DataSourceFormMode } from "./FormHelpers";

type GatewayConfigProps = {
  formMode: DataSourceFormMode;
};

export const GatewayDetails = ({ formMode }: GatewayConfigProps) => {
  const { setValue, getValues, trigger, watch } = useFormContext();

  const watchPassword = watch("gatewayPassword");

  function handleGeneratePassword() {
    const newPassword = genStrongPassword(10, 10); // Replace 10 with your desired password length
    // Set the values of both fields
    setValue("gatewayPassword", newPassword, {
      shouldDirty: true,
    });
    setValue("gatewayConfirmPassword", newPassword, {
      shouldDirty: true,
    });
    trigger();
  }

  // fix validation not triggering after changing password again
  useEffect(() => {
    trigger("gatewayConfirmPassword");
  }, [trigger, watchPassword]);

  return (
    <PageSection header="Gateway authentication">
      <Grid>
        {formMode === DataSourceFormMode.create && (
          <Grid.Row>
            <Grid.Column width={16} verticalAlign="middle">
              <p>
                Define a new set of credentials to let your gateway or other
                device/service authenticate with DCH. Once the data source is
                created, install these credentials in your equipment or software
                service.
              </p>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column width={16}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={13}>
                  <Form.TextInput
                    name="gatewayUsername"
                    label="Username"
                    isReadOnly
                  />
                </Grid.Column>
                <Grid.Column width={3} />
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={13}>
                  <Form.TextInput
                    name="gatewayPassword"
                    type="password"
                    autoComplete={
                      formMode === DataSourceFormMode.create
                        ? "new-password"
                        : "off"
                    }
                    label={
                      formMode === DataSourceFormMode.create
                        ? "Password"
                        : "Change Password"
                    }
                    required={formMode === DataSourceFormMode.create}
                  />
                </Grid.Column>
                <Grid.Column width={3} textAlign="right">
                  <Button
                    type="button"
                    basic
                    inverted
                    onClick={handleGeneratePassword}
                  >
                    Generate password
                  </Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={13}>
                  <Form.TextInput
                    name="gatewayConfirmPassword"
                    type="password"
                    autoComplete={
                      formMode === DataSourceFormMode.create
                        ? "new-password"
                        : "off"
                    }
                    label={
                      formMode === DataSourceFormMode.create
                        ? "Confirm Password"
                        : "Confirm Change Password"
                    }
                    required={formMode === DataSourceFormMode.create}
                    rules={{
                      validate: (s: string) => {
                        return s !== getValues("gatewayPassword")
                          ? `Your password and confirmation password do not match.`
                          : undefined;
                      },
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </PageSection>
  );
};
