import * as _ from "lodash";
import { Buffer } from "buffer";
import { ApplicationVersion } from "data/Metis/metisTypes";
import { DataPoolId, DataSourceId } from "data/Aletheia";

/**
 * Utility function to filter an array of strings by a regex pattern.
 *
 * @param strings - Array of strings to filter.
 * @param pattern - Regex pattern to apply to input streams.
 * @returns Array of strings which meet the requested regex.
 */
export const regexFilter = (
  strings: Array<string>,
  pattern: RegExp | string | undefined
) => {
  return pattern === undefined
    ? []
    : strings.filter((id) => (id === null ? false : id.match(pattern)));
};

export const compareString = (a: string, b: string) => {
  if (a.toLowerCase() > b.toLowerCase()) {
    return 1;
  }
  if (a.toLowerCase() < b.toLowerCase()) {
    return -1;
  }
  return 0;
};

export const compareSemanticVersions = (a: ApplicationVersion, b: ApplicationVersion) => {
  const a1 = a.split(".");
  const b1 = b.split(".");
  const len = Math.min(a1.length, b1.length);
  for (let i = 0; i < len; i++) {
    const a2 = +a1[i] || 0;
    const b2 = +b1[i] || 0;

    if (a2 !== b2) {
      return a2 > b2 ? 1 : -1;
    }
  }
  return b1.length - a1.length;
};

export const capitaliseFirstCharacter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const convertCamelCaseToTitleCase = (camelCaseName: string) =>
  _.startCase(camelCaseName);

export const encodeBase64 = (str: string) => {
  return Buffer.from(str).toString("base64");
}

export const decodeBase64 = (str: string) => {
  return Buffer.from(str, "base64").toString();
}

export const createCompositeId = (
  orgId: string,
  Id: DataPoolId | DataSourceId | string,
): string => {
  return `${orgId}:${Id}`;
};

