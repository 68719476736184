/** @jsxImportSource @emotion/react */

import * as React from "react";
import { Icon } from "semantic-ui-react";
import { DataSourceResponse } from "data/Aletheia";
import { ContactEmailLink, DchModal, FileDisplay } from "components/shared";

type Props = {
  datasource: DataSourceResponse;
  open: boolean;
  onClose: () => void;
  copied: boolean;
  onConfirm: () => void;
  isCreate?: boolean;
};

export const GatewayConfigModal: React.FC<Props> = ({
  datasource,
  open,
  onClose,
  copied,
  onConfirm,
  isCreate,
}) => (
  <DchModal
    header={
      isCreate ? "Successfully Created Data Source" : "Gateway Configuration"
    }
    content={
      <React.Fragment>
        <p>
          Please contact the DCH team at <ContactEmailLink /> for help
          configuring your gateway. The username to use when configuring your
          gateway is:
        </p>
        <FileDisplay
          data={`${datasource.dataPoolRef.timeseriesTracking.backingResourceId}:${datasource.transportConfig.userName}`}
        />

        {isCreate && (
          <p>
            You can find this information again by navigating to this data
            source.
          </p>
        )}
      </React.Fragment>
    }
    open={open}
    onClose={onClose}
    cancelText={"Close"}
    confirmText={
      copied ? (
        <>
          <Icon name="check" />
          Copied
        </>
      ) : (
        "Copy"
      )
    }
    onConfirm={() =>
      navigator.clipboard
        .writeText(
          `${datasource.dataPoolRef.timeseriesTracking.backingResourceId}:${datasource.transportConfig.userName}`,
        )
        .then(() => {
          onConfirm();
        })
    }
  />
);
