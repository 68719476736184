/** @jsxImportSource @emotion/react */

import React, { useState } from "react";
import { Input, Icon, Button } from "semantic-ui-react";
import tw from "twin.macro";
import {
  DataPoolListItem,
  DataPoolResponse,
  DataPoolUid,
  aletheiaApi,
} from "data/Aletheia";
import { DchModal, UIStatus } from "components/shared";
import { MANAGED_BY_DS_HELPER_TEXT } from "./helpers";

type Props = {
  dataPool: DataPoolListItem | DataPoolResponse;
  asButton?: boolean;
  onRemoved?: () => void;
  disabled?: boolean;
};

const DeleteDataPoolButton: React.FC<Props> = (props) => {
  const { dataPool, asButton = false, disabled } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const name =
    dataPool.name && dataPool.name !== ""
      ? dataPool.name
      : (dataPool.id as string);

  return (
    <>
      {asButton ? (
        <Button
          disabled={disabled}
          basic
          inverted
          onClick={() => setIsModalOpen(true)}
        >
          <Icon name="trash" />
          Delete Data Pool
        </Button>
      ) : (
        <Icon
          name="trash"
          onClick={() => setIsModalOpen(true)}
          link={!(dataPool as DataPoolListItem).dataSourceRef?.dataSourceId}
          disabled={
            !!(dataPool as DataPoolListItem).dataSourceRef?.dataSourceId
          }
          data-tooltip-id={
            (dataPool as DataPoolListItem).dataSourceRef?.dataSourceId &&
            `parent-datasource-tooltip`
          }
          data-tooltip-content={MANAGED_BY_DS_HELPER_TEXT}
        />
      )}
      <DeleteDataPoolModal
        open={isModalOpen}
        dpId={dataPool.uid as DataPoolUid}
        dpName={name ?? ""}
        setOpen={setIsModalOpen}
        onDelete={props.onRemoved}
      />
    </>
  );
};

type ModalProps = {
  open: boolean;
  dpId: DataPoolUid;
  dpName: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onDelete?: () => void;
};

const DeleteDataPoolModal: React.FunctionComponent<ModalProps> = (props) => {
  const [inputName, setInputName] = useState("");
  const [confirmStatus, setConfirmStatus] = useState(new UIStatus());

  const onConfirm = () => {
    setConfirmStatus((prev) => prev.setIndeterminate(true));
    inputName !== props.dpName
      ? setConfirmStatus((prev) =>
          prev.setError(
            `Input '${inputName}' does not match data source name '${props.dpName}'`,
          ),
        )
      : aletheiaApi
          .deleteDatapool({ force: true, preserve_backing_resource: false })(
            props.dpId,
          )
          .then(
            () => {
              props.setOpen(false);
              props.onDelete && props.onDelete();
              setConfirmStatus((prev) => prev.setSuccessful(true));
            },
            (e) => {
              setConfirmStatus((p) => p.setError(e.message));
            },
          );
  };

  const onClose = () => {
    setInputName("");
    props.setOpen(false);
  };

  return (
    <DchModal
      header={`Delete Data Pool: ${props.dpName}`}
      content={
        <React.Fragment>
          <h3>
            <Icon
              className="purpleLight"
              size="large"
              name="exclamation circle"
            />
            Caution!
          </h3>
          <p>
            Deleting this Data Pool will delete all associated points and the
            full history of timeseries data associated with them. This is an
            irreversible action.
          </p>

          <div css={tw`my-6`}>
            <Input
              data-test-id={"confirm-name"}
              css={tw`w-full`}
              type="text"
              placeholder={`Enter the name '${props.dpName}' to confirm deletion`}
              value={inputName}
              onChange={(e) => setInputName(e.currentTarget.value)}
            />
          </div>
          {confirmStatus.error && (
            <div css={tw`text-red-error`}>
              <>{confirmStatus.error}</>
            </div>
          )}
        </React.Fragment>
      }
      open={props.open}
      onClose={onClose}
      onConfirm={onConfirm}
      disableConfirm={inputName !== props.dpName}
      confirmText="Delete"
      confirmLoading={confirmStatus.indeterminate}
    />
  );
};

export default DeleteDataPoolButton;
