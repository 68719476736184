import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import { DCHModel } from "data/brick";
import { ModelMeta } from "data/Mason/ModelDraft/ModelDraftUtils";
import { PageMode } from "components/shared";
import { BuildingModelWidths, SubLevelNodeName } from "../Common";
import { ModelFormTree } from "./Form/ModelFormTree";
import {
  ModelFormFields,
  SiteOrBuildingModelForm,
} from "./Form/SiteOrBuildingModelForm";
import { useFieldArrayNodes } from "./Form/useFieldArrayNodes";
import { Breadcrumb, Breadcrumbs } from "./Breadcrumb";
import {
  ModelProps,
  QueryParams,
  SelectedElement,
  findModelSelectedElementById,
} from "./ModelUtils";

export type BaseSiteOrBuildingModelProps = ModelProps & {
  modelMeta: ModelMeta;
  fetchedModel?: DCHModel;
  selectedElement: SelectedElement;
  setSelectedElement: (_: SelectedElement) => void;
  breadcrumbs: Breadcrumb[];
  setBreadcrumbs: React.Dispatch<React.SetStateAction<Breadcrumb[]>>;
  currentCrumb: React.MutableRefObject<Breadcrumb | undefined>;
};

type SiteOrBuildingModelProps = BaseSiteOrBuildingModelProps & {
  pageMode: PageMode;
};

export const SiteOrBuildingModel = (props: SiteOrBuildingModelProps) => {
  const {
    pageMode,
    modelType,
    fetchedModel,
    selectedElement,
    setSelectedElement,
    modelMeta,
    breadcrumbs,
    setBreadcrumbs,
    currentCrumb,
  } = props;

  const {
    wingFields,
    floorFields,
    roomFields,
    zoneFields,
    equipmentFields,
    pointFields,
    addNewNode,
    deleteNode,
  } = useFieldArrayNodes();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  let nodeId = queryParams.get(QueryParams.id);

  //for points, id often includes a hash
  const queryHash = location.hash;
  if (queryHash) nodeId = nodeId + queryHash;

  const { getValues } = useFormContext<ModelFormFields>();

  // to handle back navigation, sync up viewstate according to the url
  useEffect(() => {
    const formValues: ModelFormFields | undefined = getValues();
    //don't set breadcrumbs until we've loaded the model
    if (formValues?.modelDetails?.fieldId) {
      const element = findModelSelectedElementById(formValues, nodeId);
      setSelectedElement(element.selectedElement);
      const newCrumb: Breadcrumb = {
        nodeLabel: element.label,
        element: element.selectedElement,
      };
      if (
        !currentCrumb.current ||
        JSON.stringify(currentCrumb.current.element) !==
          JSON.stringify(newCrumb.element)
      ) {
        currentCrumb.current = newCrumb;
        setBreadcrumbs((prev) => [...prev, currentCrumb.current as Breadcrumb]);
      }
    }
  }, [
    nodeId,
    fetchedModel,
    setSelectedElement,
    getValues,
    currentCrumb,
    setBreadcrumbs,
  ]);

  const handleDelete = (
    elementToDelete: SelectedElement,
    formIndex: number,
  ) => {
    deleteNode(elementToDelete.type as SubLevelNodeName, formIndex);
    setBreadcrumbs((prev) =>
      prev.map((crumb) =>
        JSON.stringify(crumb.element) === JSON.stringify(elementToDelete)
          ? {
              ...crumb,
              deleted: true,
            }
          : crumb,
      ),
    );
    setSelectedElement({ type: "model", id: modelMeta.modelId });
  };

  return (
    <>
      {breadcrumbs.length > 0 && (
        <Grid.Row>
          <Grid.Column>
            <Breadcrumbs
              breadcrumbs={breadcrumbs}
              onClick={(index) => {
                const clickedCrumb = breadcrumbs[index];
                setBreadcrumbs((prev) => [...prev].slice(0, index + 1));
                currentCrumb.current = clickedCrumb;
                setSelectedElement(clickedCrumb.element);
              }}
            />
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row className="sticky-container">
        <Grid.Column {...BuildingModelWidths.tree}>
          <ModelFormTree
            model={fetchedModel}
            modelType={modelType}
            selectedElement={selectedElement}
            setSelectedElement={setSelectedElement}
            pageMode={pageMode}
            addNewNode={addNewNode}
          />
        </Grid.Column>
        <Grid.Column {...BuildingModelWidths.component} className="sticky-col">
          <SiteOrBuildingModelForm
            {...props}
            isReadOnly={pageMode === PageMode.view}
            deleteNode={handleDelete}
            fieldArrays={{
              wings: wingFields.fields,
              floors: floorFields.fields,
              rooms: roomFields.fields,
              zones: zoneFields.fields,
              equipment: equipmentFields.fields,
              points: pointFields.fields,
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </>
  );
};
