/** @jsxImportSource @emotion/react */

import React, { useContext, useEffect, useState } from "react";
import { generatePath, useParams, useNavigate, Link } from "react-router-dom";
import { Button, Divider, Icon } from "semantic-ui-react";
import tw from "twin.macro";
import { EnodiaOrgContext } from "App";
import { Path } from "Routes";
import { BuildingId, ModelRootType, SiteId } from "data/brick";
import { OrgId } from "data/Enodia";
import { ApiError, ApiErrorType } from "data/http";
import { deleteMetadata } from "data/Models/Metadata/ModelMetadataUtils";
import {
  DchModal,
  PageMode,
  Page,
  PageTitle,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import { SiteOrBuildingIcon } from "components/SitesAndBuildings/SiteOrBuildingIcon";
import { SiteOrBuildingParams } from "./Common";
import {
  SiteOrBuildingMetadataAccordion,
  SiteOrBuildingMetadataAccordionProps,
} from "./Metdata/MetadataAccordion";
import { ModelProps } from "./Model/ModelUtils";
import SiteOrBuildingModelAccordion from "./Model/SiteOrBuildingModelAccordion";

type SiteProps = {
  pageMode?: PageMode;
  isBuildingMetadata?: boolean;
};

const VisualiserButton = ({
  orgId,
  siteId,
  buildingId,
  isBuilding,
  isLoading,
  isDisabled,
}: ModelProps & {
  isBuilding: boolean;
  isLoading: boolean;
  isDisabled: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <Button
      type="button"
      style={{ marginLeft: "1rem" }}
      primary
      onClick={() => {
        navigate(
          isBuilding
            ? generatePath(Path.ViewModelBuilding, {
                orgId: orgId as string,
                siteId: siteId!,
                buildingId: buildingId as BuildingId,
              })
            : generatePath(Path.ViewModelSite, {
                orgId: orgId as string,
                siteId: siteId!,
              }),
        );
      }}
      loading={isLoading}
      disabled={isDisabled}
    >
      <Icon name="sitemap" />
      View Model Visualiser
    </Button>
  );
};

const DeleteButton = ({
  orgId,
  siteId,
  buildingId,
  modelType,
  isBuilding,
  deleteStatus,
  setDeleteStatus,
}: {
  isBuilding: boolean;
  deleteStatus: UIStatus;
  setDeleteStatus: React.Dispatch<React.SetStateAction<UIStatus>>;
} & ModelProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();

  const handleDelete = () => {
    setDeleteStatus((prevState: UIStatus) => prevState.setIndeterminate(true));
    deleteMetadata(orgId, siteId!, buildingId).then(
      () => {
        setDeleteStatus((prevState: UIStatus) =>
          prevState.setIndeterminate(false),
        );
        setShowDeleteModal(false);
        isBuilding
          ? navigate(
              generatePath(Path.ViewSite, {
                orgId: orgId,
                siteId: siteId!,
              }),
            )
          : navigate(Path.Home);
      },
      (err: ApiError) => {
        setDeleteStatus((prevState: UIStatus) =>
          prevState.setError(
            err.message ||
              `An error occured when deleting the ${modelType.toLowerCase()}}`,
          ),
        );
      },
    );
  };

  return (
    <React.Fragment>
      <DchModal
        header={
          modelType === ModelRootType.Building
            ? `Delete building: ${buildingId}`
            : `Delete site: ${siteId}`
        }
        content={
          <UIStatusWrapper status={deleteStatus}>
            {modelType === ModelRootType.Building ? (
              <p>Really delete this building? This action cannot be undone.</p>
            ) : (
              <p>
                Really delete this site? This will also delete all the buildings
                listed in it. This action cannot be undone.
              </p>
            )}
          </UIStatusWrapper>
        }
        open={showDeleteModal}
        onConfirm={handleDelete}
        onClose={() => {
          setDeleteStatus(new UIStatus());
          setShowDeleteModal(false);
        }}
        confirmText="Delete"
      />
      <Button
        basic
        inverted
        onClick={(e) => {
          e.stopPropagation();
          setShowDeleteModal(true);
        }}
        loading={deleteStatus.indeterminate}
      >
        <Icon name="trash" />
        Delete {modelType}
      </Button>
    </React.Fragment>
  );
};

const SiteOrBuildingPageHeader = ({
  siteId,
  buildingId,
  modelType,
  pageMode,
  isBuilding,
}: ModelProps & { pageMode: PageMode; isBuilding: boolean }) => (
  <PageTitle
    primaryHeader={
      <span>
        <SiteOrBuildingIcon isBuilding />
        {pageMode === PageMode.create
          ? `New ${modelType}`
          : `View ${modelType}: ${isBuilding ? buildingId : siteId}`}
      </span>
    }
  />
);

const SiteOrBuilding = ({
  pageMode = PageMode.edit,
  isBuildingMetadata = false,
}: SiteProps) => {
  const params = useParams<SiteOrBuildingParams>();
  const { orgId, setOrgId, orgList } = useContext(EnodiaOrgContext);
  const siteId = params.siteId as SiteId;
  const buildingId = params.buildingId as BuildingId;
  const isBuilding = isBuildingMetadata || buildingId !== undefined;
  const modelType = isBuilding ? ModelRootType.Building : ModelRootType.Site;

  const [deleteStatus, setDeleteStatus] = useState(new UIStatus());
  const [modelExists, setModelExists] = useState<boolean>();
  const [hasUnsavedChanges, setHasUnsavedChanges] =
    React.useState<boolean>(false);

  const [pageStatus, setPageStatus] = useState<UIStatus>(new UIStatus());

  const [pageError, setPageError] = useState<ApiError | undefined>();

  const isVizEnabled =
    String(process.env.REACT_APP_SHOW_MODEL_VISUALISER_BUTTON).toLowerCase() ===
      "true" && modelExists;

  const modelProps: ModelProps = {
    orgId: (params.orgId as OrgId) ?? (orgId as OrgId),
    siteId,
    buildingId,
    modelType,
  };

  const metadataHandlerProps: SiteOrBuildingMetadataAccordionProps = {
    ...modelProps,
    isBuilding,
    pageMode,
    hasUnsavedChanges,
    setHasUnsavedChanges,
    setPageError,
    setPageStatus,
  };

  // change orgContext  to match param on first load
  useEffect(() => {
    // check validity of the orgid from params
    if (orgList && orgList.find((org) => org.id === params.orgId)) {
      setOrgId(params.orgId as OrgId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgList]);

  /**
   * Rather than handle bad url's in UIStatus format, we handle it as "Page not found" as
   * we don't have the required context to properly load the contents of the page.
   * If user sees a partially loaded page, users may mistake this for a unexpected backend error rather than url mistake.
   * We only handle unauthorized and not found instances this way.
   */
  if (pageError) {
    const modelStr = isBuilding ? "Building" : "Site";

    if (pageError.type === ApiErrorType.Unauthorized) {
      return (
        <Page>
          <PageTitle
            primaryHeader={`Unauthorized For ${modelStr}`}
            subHeader={`Sorry, you don't have the permissions for ${modelStr.toLowerCase()}`}
          />
          <p>{pageError.message}</p>
          <p>
            <Link to={Path.Home}>
              <u>Back to Home</u>
            </Link>
          </p>
        </Page>
      );
    }

    return (
      <Page>
        <PageTitle
          primaryHeader={`${modelStr} Not Found`}
          subHeader={`Sorry, that ${modelStr.toLowerCase()} cannot be found`}
        />
        <p>{pageError.message}</p>
        <p>
          <Link to={Path.Home}>
            <u>Back to Home</u>
          </Link>
        </p>
      </Page>
    );
  }

  return (
    <Page hasGoBack>
      <UIStatusWrapper status={pageStatus}>
        <SiteOrBuildingPageHeader
          {...modelProps}
          pageMode={pageMode}
          isBuilding={isBuilding}
        />
        {pageMode === PageMode.edit && (
          <div css={tw`flex`}>
            <DeleteButton
              {...modelProps}
              isBuilding={isBuilding}
              deleteStatus={deleteStatus}
              setDeleteStatus={setDeleteStatus}
            />
            {isVizEnabled && (
              <VisualiserButton
                {...modelProps}
                isBuilding={isBuilding}
                isLoading={deleteStatus.indeterminate}
                isDisabled={!modelExists}
              />
            )}
          </div>
        )}
        <Divider />
        <SiteOrBuildingMetadataAccordion {...metadataHandlerProps} />
        <Divider />
        {orgId && pageMode !== PageMode.create && (
          <SiteOrBuildingModelAccordion
            {...modelProps}
            modelExists={modelExists}
            setModelExists={setModelExists}
          />
        )}
      </UIStatusWrapper>
    </Page>
  );
};

export default SiteOrBuilding;
