/** @jsxImportSource @emotion/react */

import React from "react";
import { Icon, Label, SemanticCOLORS, SemanticICONS } from "semantic-ui-react";
import { ModelState } from "data/modelGenerationApi";
import { InstallApplicationState } from "components/Applications/Marketplace";
import { InvitationState } from "components/Index/Authentication/Invitations/InvitationModalHelper";

export enum JobState {
  Created = "Created",
  Queued = "Queued",
  Processing = "Processing",
  Dispatched = "Dispatched",
  Running = "Running",
  Error = "Error",
  Success = "Success",
  Failed = "Failed",
  Blocked = "Blocked",
  NeverRun = "NeverRun",
  Cancelled = "Cancelled",
  Enabled = "Enabled",
  Disabled = "Disabled",
}

const stoppedStates = Object.values([
  JobState.Created,
  JobState.Error,
  JobState.Success,
  JobState.Failed,
  JobState.Blocked,
  JobState.NeverRun,
  JobState.Cancelled,
  JobState.Disabled,
]);

export const inStoppedStates = (state: JobState) =>
  stoppedStates.includes(state);

export enum StateColours {
  Success = "teal",
  Failure = "pink",
  Waiting = "orange",
  Unknown = "grey",
}

const failureStates = Object.values([
  JobState.Error,
  JobState.Failed,
  JobState.Blocked,
  JobState.Cancelled,
  ModelState.Failure,
  ModelState.Ignored,
  ModelState.Revoked,
  InstallApplicationState.Failure,
  InvitationState.Rejected,
  InvitationState.RejectedAll,
]);

const successStates = Object.values([
  JobState.Created,
  JobState.Success,
  JobState.NeverRun,
  JobState.Enabled,
  ModelState.Success,
  InstallApplicationState.Success,
  InvitationState.Accepted,
  InvitationState.AcceptedAll,
]);

const waitingStates = Object.values([
  JobState.Queued,
  JobState.Processing,
  JobState.Dispatched,
  JobState.Running,
  ModelState.Pending,
  ModelState.Retry,
  ModelState.Started,
  InstallApplicationState.Running,
]);

type LabelStatuses =
  | JobState
  | ModelState
  | InstallApplicationState
  | InvitationState;
type WaitingLabelStatuses = JobState | ModelState | InstallApplicationState;

export const isFailureStatus = (status: LabelStatuses) =>
  failureStates.includes(status);

export const isSuccessStatus = (status: LabelStatuses) =>
  successStates.includes(status);

export const isWaitingStatus = (status: WaitingLabelStatuses) =>
  waitingStates.includes(status);

export const ProcessingStatusLabel: React.FunctionComponent<{
  status: LabelStatuses;
}> = ({ status }) => {
  const [icon, setIcon] = React.useState<SemanticICONS>("check");
  const [colour, setColour] = React.useState<SemanticCOLORS>(
    StateColours.Success,
  );
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const waiting = isWaitingStatus(status as WaitingLabelStatuses);
    const success = isSuccessStatus(status);
    const failure = isFailureStatus(status);

    setLoading(waiting);

    setIcon((_) =>
      success
        ? "check"
        : failure
          ? "times"
          : waiting
            ? "sync alternate"
            : "question",
    );
    setColour((_) =>
      success
        ? StateColours.Success
        : failure
          ? StateColours.Failure
          : waiting
            ? StateColours.Waiting
            : StateColours.Unknown,
    );
  }, [status]);

  return (
    <Label color={colour} horizontal data-test-id="jobStatusIndicator">
      <Icon name={icon} loading={loading} />
      {status}
    </Label>
  );
};

export default ProcessingStatusLabel;
