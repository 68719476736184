/** @jsxImportSource @emotion/react */

import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Dimmer, Grid } from "semantic-ui-react";
import tw from "twin.macro";
import { v4 } from "uuid";
import {
  DataSourceCreateRequest,
  DataSourceResponse,
  aletheiaApi,
} from "data/Aletheia";
import { OrgId } from "data/Enodia";
import { ApiError } from "data/http";
import { EnodiaOrgContext } from "App";
import { Path } from "Routes";
import { GatewayConfigModal } from "./GatewayConfig";
import { Page, PageTitle, UIStatus, UIStatusWrapper } from "components/shared";
import Form from "components/shared/Forms/ReactHookForm";
import { DEFAULT_TRANSPORT_CONFIG_TYPE } from "../DataSourceUtils";
import { GatewayDetails } from "./GatewayFormSegment";
import { DataParserFormSegment } from "./ParserFormSegment";
import { DataSourceDetails } from "./DetailFormSegment";
import {
  CreateOrUpdateDataSourceFormValues,
  DataSourceFormMode,
} from "./FormHelpers";

const PREFIX_INSTRUCTION_STR = "<prefix available after creation>:";

export const NewDataSource: React.FC = () => {
  const generatedUUID = v4();
  const navigate = useNavigate();
  const { orgId } = useContext(EnodiaOrgContext);

  const [uiStatus, setUIStatus] = useState(new UIStatus());
  const [copied, setCopied] = React.useState(false);
  const [createSuccessData, setCreateSuccessData] = React.useState<
    DataSourceResponse | undefined
  >();
  const [isFormValid, setIsFormValid] = React.useState(false);

  const mkDataSourceRequest = (
    form: CreateOrUpdateDataSourceFormValues,
  ): DataSourceCreateRequest => ({
    id: form.id,
    name: form.name,
    // We need to send the description field, so set it to null if undefined.
    description: form.description ?? undefined,
    enabled: form.enabled,
    organisationId: orgId as OrgId,
    transportConfig: {
      userName: form.gatewayUsername.replace(PREFIX_INSTRUCTION_STR, ""),
      password: form.gatewayPassword,
      type: DEFAULT_TRANSPORT_CONFIG_TYPE, //only one supported type atm, hardcoded
    },
    // decoder ID is hardcoded for now since we only support one decoder id at the moment
    decoderConfig: {
      decoderId: form.decoderType,
      configContent:
        form.decoderConfigText !== undefined
          ? JSON.parse(form.decoderConfigText)
          : undefined,
    },
  });

  const submitDataSource = async (
    formValues: CreateOrUpdateDataSourceFormValues,
  ) => {
    setUIStatus(uiStatus.setIndeterminate(true));
    const ds = mkDataSourceRequest(formValues);
    aletheiaApi.postDatasource(ds).then(
      (datasource) => {
        setUIStatus(uiStatus.setIndeterminate(false));
        setCreateSuccessData(datasource);
      },
      (e: ApiError) => {
        setUIStatus(uiStatus.setError(e.message));
      },
    );
  };

  return (
    <Page>
      {createSuccessData && (
        <GatewayConfigModal
          datasource={createSuccessData}
          open={createSuccessData !== undefined}
          onClose={() => {
            navigate(Path.DataSources);
            setCopied(false);
          }}
          copied={copied}
          onConfirm={() => setCopied(true)}
          isCreate
        />
      )}

      <PageTitle
        primaryHeader="Add a Data Source"
        heroText={
          <span>
            A <b>Data Source</b> represents the system machinery that is used to
            ingest your data into DCH. It allows a gateway device or a web
            service to connect and send data.
            <br />
            Data sent in via a Data Source will appear in an automatically
            created Data Pool that is associated with the Data Source. Currently
            creation of MQTT based Data Sources are supported in this web
            interface.
          </span>
        }
      />
      <UIStatusWrapper status={uiStatus} clearable={true}>
        <Dimmer.Dimmable dimmed={!orgId} css={tw`z-0`}>
          <Dimmer active={!orgId}>
            <div>Select an organisation to view sites and building list</div>
          </Dimmer>
          <Form<CreateOrUpdateDataSourceFormValues>
            onSubmit={(ds: CreateOrUpdateDataSourceFormValues) =>
              submitDataSource(ds)
            }
            formId="CreateDataSource"
            setFormState={({ isValid }) => setIsFormValid(isValid)}
            defaultValues={{
              gatewayUsername: `${PREFIX_INSTRUCTION_STR}${generatedUUID}@dataclearinghouse.org`,
              type: DEFAULT_TRANSPORT_CONFIG_TYPE,
            }}
          >
            <React.Fragment>
              <DataSourceDetails formMode={DataSourceFormMode.create} />
              <GatewayDetails formMode={DataSourceFormMode.create} />
              <DataParserFormSegment />
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      primary
                      type="submit"
                      loading={uiStatus.indeterminate}
                      disabled={uiStatus.indeterminate || !isFormValid}
                    >
                      Add data source
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </React.Fragment>
          </Form>
        </Dimmer.Dimmable>
      </UIStatusWrapper>
    </Page>
  );
};
