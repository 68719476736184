/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import {
  Link,
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Button, Divider, Icon } from "semantic-ui-react";
import tw from "twin.macro";
import { Path } from "Routes";
import shopSvg from "assets/icons/shop.svg";
import { metisApi, VendorDetailsResponse, VendorUid } from "data/Metis";
import {
  EMPTY_APPLICATIONS_MSG,
  EMPTY_OVERVIEW_MSG,
  Header,
  isApplicationDeveloper,
  MarkdownInput,
  Page,
  PageTitle,
  PrimaryTitle,
  Tabs,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import {
  ApplicationsMode,
  ApplicationRow,
  AppDisplayInfo,
} from "components/Applications/shared";

type Params = { vendorUid: string };

enum VendorTab {
  Overview = "overview",
  Applications = "applications",
}

export const VendorDetails: React.FC<{}> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams<keyof Params>() as Params;
  const [vendor, setVendor] = useState<VendorDetailsResponse>();
  const [selectedTab, setSelectedTab] = useState<VendorTab>(VendorTab.Overview);
  const [applications, setApplications] = useState<Array<AppDisplayInfo>>([]);
  const [status, setStatus] = useState<UIStatus>(new UIStatus());

  // fetch vendor metadata & application list on first load
  useEffect(() => {
    setStatus((prev) => prev.setIndeterminate(true));
    metisApi
      .getVendor(params.vendorUid as VendorUid)
      .then((res) => {
        setVendor(res);
        setStatus((prev) => prev.setIndeterminate(false));
        return res.uid;
      })
      .then((vendorUid: VendorUid) => {
        metisApi
          .getApplicationRecipes({ vendorUid: vendorUid })
          .then((res) => {
            const publishedApps = res.filter(
              (application) => application.published,
            );
            const coreAppList: AppDisplayInfo[] = publishedApps.map((app) => ({
              applicationUid: app.applicationUid,
              applicationName: app.name,
              version: app.version,
              vendorId: app.vendorId,
              description: app.description,
              installedCount: app.installedCount,
              categories: app.categories,
            }));
            setApplications(coreAppList);
          })
          .catch((error) => {
            setStatus((p) => p.setError(error.message));
          });
      })
      .then(() => setStatus((p) => p.setIndeterminate(false)))
      .catch((e) => setStatus((prev) => prev.setError(e.message)));
  }, [params.vendorUid]);

  return (
    <Page hasGoBack backTo={location.state?.["backTo"]}>
      <PageTitle
        primaryTitle={PrimaryTitle.AppMarketplace}
        secondaryTitle={`View Vendor: ${vendor?.name}`}
      />
      <UIStatusWrapper status={status}>
        {vendor && (
          <div css={tw`flex flex-col gap-4 bg-core-grey-dark rounded p-4`}>
            <Header
              name={vendor.name}
              icon={shopSvg}
              subHeader={
                <>
                  <span>{vendor.description}</span>
                  <span css={tw`flex flex-row mt-2 gap-x-2`}>
                    <Link to={`mailto:${vendor.email}`}>
                      <Icon name={"mail"} />
                      {vendor.email}
                    </Link>
                    {vendor.website && (
                      <>
                        <span>|</span>
                        <a
                          href={vendor.website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Icon name="globe" />
                          {vendor.website}
                        </a>
                      </>
                    )}
                  </span>
                </>
              }
              actions={
                isApplicationDeveloper() ? (
                  <div css={tw`flex items-center flex-1`}>
                    <Button
                      primary
                      onClick={() =>
                        navigate(
                          generatePath(Path.EditVendorDetails, {
                            vendorUid: vendor.uid,
                          }),
                          { state: { vendor: vendor, backTo: vendor.name } },
                        )
                      }
                    >
                      <Icon name="pencil" />
                      Edit Vendor
                    </Button>
                  </div>
                ) : (
                  <></>
                )
              }
            />
            <Divider />
            <Tabs<VendorTab>
              tabs={Object.values(VendorTab)}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            {selectedTab === VendorTab.Applications ? (
              <VendorApplications applications={applications} />
            ) : (
              <VendorOverview vendorOverview={vendor?.overview} />
            )}
          </div>
        )}
      </UIStatusWrapper>
    </Page>
  );
};

const VendorOverview = ({ vendorOverview }: { vendorOverview?: string }) => {
  return vendorOverview ? (
    <div css={tw`px-2 pb-4`}>
      <MarkdownInput value={vendorOverview} isReadOnly />
    </div>
  ) : (
    <i>{EMPTY_OVERVIEW_MSG}</i>
  );
};

const VendorApplications = ({
  applications,
}: {
  applications: Array<AppDisplayInfo>;
}) => {
  return (
    <>
      {applications.length > 0 ? (
        applications.map((application) => (
          <ApplicationRow
            key={application.applicationUid}
            app={application}
            mode={ApplicationsMode.available}
          />
        ))
      ) : (
        <i>{EMPTY_APPLICATIONS_MSG}</i>
      )}
    </>
  );
};
