import React from "react";
import {
  Controller,
  FieldValues,
  RegisterOptions,
  useFormContext,
} from "react-hook-form";
import { TextArea, TextAreaProps } from "components/shared";
import { FormInputProps } from "../ReactHookForm";
import { ASYNC_ERROR_SUFFIX } from "../useAsyncValidate";

export type FormTextAreaProps<TFieldValues extends FieldValues> =
  FormInputProps<TFieldValues> & TextAreaProps;

export const FormTextArea = <TFieldValues extends FieldValues>(
  props: FormTextAreaProps<TFieldValues>,
) => {
  let rules = props.rules as Omit<
    RegisterOptions<FieldValues>,
    "setValueAs" | "disabled" | "valueAsNumber" | "valueAsDate"
  >;
  if (props.required) rules = { ...rules, required: "Required" };

  const { control, getFieldState } = useFormContext();

  return (
    <Controller<FieldValues, any>
      name={props.name}
      control={control}
      rules={rules}
      defaultValue={
        props.defaultValue ?? ("" as TFieldValues[keyof TFieldValues])
      }
      render={({ field, fieldState }) => {
        const formProps = {
          ...field,
          onChange: (event: any) => {
            field.onChange(event);
          },
        };

        const asyncFieldState = getFieldState(
          `${props.name}${ASYNC_ERROR_SUFFIX}`,
        );

        const invalidFieldState =
          ((props.validateOnChange && fieldState.isDirty) ||
            fieldState.isTouched) &&
          (!!fieldState.error || !!asyncFieldState.error);

        return (
          <TextArea
            {...props}
            formProps={formProps}
            inputValidation={
              rules
                ? {
                    invalid: invalidFieldState,
                    errorMessage:
                      fieldState.error?.message ??
                      (asyncFieldState.error?.message as string),
                  }
                : undefined
            }
            testId={props.name}
          />
        );
      }}
    />
  );
};
