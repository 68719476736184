import React, { useEffect } from "react";
import {
  DeepPartial,
  DefaultValues,
  FieldValues,
  FormProvider,
  FormState,
  SubmitHandler,
  UseControllerProps,
  UseFormReturn,
  useForm,
  useFormContext,
} from "react-hook-form";
import { FormButton } from "./FormButton";
import {
  FormCheckboxInput,
  FormDateInput,
  FormFileInput,
  FormMarkdownInput,
  FormSelectInput,
  FormTextArea,
  FormTextInput,
} from "./Inputs";

export type FormStateValues<TFieldValues extends FieldValues> = Pick<
  FormState<TFieldValues>,
  "isValid" | "isDirty" | "isSubmitSuccessful" | "errors" | "dirtyFields"
>;
export type FormMethods<TFieldValues extends FieldValues> = Pick<
  UseFormReturn<TFieldValues>,
  | "getValues"
  | "reset"
  | "watch"
  | "setError"
  | "clearErrors"
  | "setValue"
  | "trigger"
  | "resetField"
>;

type FormProps<TFieldValues extends FieldValues> = {
  onSubmit: SubmitHandler<TFieldValues>;
  defaultValues?: DeepPartial<TFieldValues>;
  formId?: string;
  setFormState?: (_: FormStateValues<TFieldValues>) => void;
  setFormMethods?: (_: FormMethods<TFieldValues>) => void;
  formRef?: React.RefObject<HTMLFormElement>;
  resetOnSubmit?: boolean;
};

export type FormInputProps<TFieldValues extends FieldValues> = {
  validateOnChange?: boolean;
} & UseControllerProps<TFieldValues>;

export const Form = <TFieldValues extends FieldValues>({
  onSubmit,
  defaultValues,
  formId,
  formRef,
  resetOnSubmit,
  ...props
}: React.PropsWithChildren<FormProps<TFieldValues>>) => {
  const methods = useForm<TFieldValues>({
    mode: "onTouched",
    defaultValues: defaultValues as DefaultValues<TFieldValues>,
  });

  return (
    <FormProvider {...methods}>
      <form
        id={formId}
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          methods.handleSubmit(onSubmit)();
          if (resetOnSubmit) methods.reset(undefined, { keepValues: true });
        }}
      >
        <FormChildren {...props} />
      </form>
    </FormProvider>
  );
};

const FormChildren = <TFieldValues extends FieldValues>(
  props: React.PropsWithChildren<{
    setFormState?: (_: FormStateValues<TFieldValues>) => void;
    setFormMethods?: (_: FormMethods<TFieldValues>) => void;
  }>,
) => {
  const {
    getValues,
    reset,
    watch,
    resetField,
    clearErrors,
    setError,
    setValue,
    trigger,
    formState: { isValid, isDirty, isSubmitSuccessful, errors, dirtyFields },
  } = useFormContext();

  useEffect(() => {
    props.setFormState?.({
      isValid,
      isDirty,
      isSubmitSuccessful,
      errors,
      dirtyFields,
    } as FormState<TFieldValues>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, isDirty, isSubmitSuccessful]);

  useEffect(() => {
    props.setFormMethods?.({
      getValues,
      reset,
      watch,
      resetField,
      clearErrors,
      setError,
      setValue,
      trigger,
    } as FormMethods<TFieldValues>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues]);

  return <>{props.children}</>;
};

Form.TextInput = FormTextInput;
Form.SelectInput = FormSelectInput;
Form.DateInput = FormDateInput;
Form.CheckboxInput = FormCheckboxInput;
Form.FileInput = FormFileInput;
Form.MarkdownInput = FormMarkdownInput;
Form.TextArea = FormTextArea;

Form.Button = FormButton;

export default Form;
