/** @jsxImportSource @emotion/react */
import { SemanticWIDTHS } from "semantic-ui-react";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { debounce } from "throttle-debounce";
import { isEqual } from "date-fns";
import { DownloadPostResponseMetadata } from "data/Chronos";

export const DATE_RANGE_CONTROL_WIDTHS = (() => {
  const ToDateTime = {
    widescreen: 4 as SemanticWIDTHS,
    largeScreen: 4 as SemanticWIDTHS,
    computer: 16 as SemanticWIDTHS,
    tablet: 16 as SemanticWIDTHS,
    mobile: 16 as SemanticWIDTHS,
  };

  const fromDateTime = {
    widescreen: 2 as SemanticWIDTHS,
    largeScreen: 2 as SemanticWIDTHS,
    computer: 16 as SemanticWIDTHS,
    tablet: 16 as SemanticWIDTHS,
    mobile: 16 as SemanticWIDTHS,
  };

  const limitInput = {
    widescreen: 2 as SemanticWIDTHS,
    largeScreen: 2 as SemanticWIDTHS,
    computer: 16 as SemanticWIDTHS,
    tablet: 16 as SemanticWIDTHS,
    mobile: 16 as SemanticWIDTHS,
  };

  return {
    fromDateTime: fromDateTime,
    toDateTime: ToDateTime,
    limitInput: limitInput,
  };
})();

export type Datum<t> = {
  x: Date;
  y: t;
};

export type ChartData<a> = Array<{
  data: Array<Datum<a>>;
  name?: string;
  metadata?: DownloadPostResponseMetadata;
}>;

const isChartDataEmpty = (d: ChartData<any>) => {
  return d.filter((i) => i.data.length > 0).length === 0;
};

export function setSeries<a>(data: ChartData<a>) {
  return (
    chart: am4charts.XYChart,
    hasScrollbarX: boolean,
    hasScrollbarY: boolean,
  ) => {
    // Add no data found label
    if (isChartDataEmpty(data)) {
      const label = chart.createChild(am4core.Label);
      label.text = "No data found";
      label.fontSize = 20;
      label.isMeasured = false;
      label.x = am4core.percent(50);
      label.y = am4core.percent(50);

      label.horizontalCenter = "middle";
      chart.cursor.behavior = "none";
    } else {
      chart.cursor.behavior = "zoomX";
    }

    // Update chart data
    chart.data = data;
    chart.series.clear();

    let scrollbarX = new am4charts.XYChartScrollbar();
    let scrollbarY = new am4charts.XYChartScrollbar();

    data?.forEach((d) => {
      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = "x";
      series.dataFields.valueY = "y";
      if (data.length > 1) {
        series.tooltipText = "{name}: {valueY}";
      } else {
        series.tooltipText = "{valueY}";
      }
      series.data = d.data;
      if (d.name != null) {
        series.name = d.name;
      }

      if (hasScrollbarX) {
        scrollbarX.series.push(series);
      }

      if (hasScrollbarY) {
        scrollbarY.series.push(series);
      }
    });

    if (hasScrollbarX) {
      chart.scrollbarX = scrollbarX;
    }

    if (hasScrollbarY) {
      chart.scrollbarY = scrollbarY;
    }
  };
}

export type DateRange = {
  start?: Date;
  end?: Date;
};

export function addZoomChangeListener(
  chart: am4charts.XYChart,
  dateAxis: am4charts.DateAxis,
  onChange: () => void,
  setDateRange: React.Dispatch<React.SetStateAction<DateRange | undefined>>,
) {
  let update = debounce(1000, (ev) => {
    const axis = ev.target;
    const fromDate = new Date(axis.minZoomed);
    const toDate = new Date(axis.maxZoomed);

    setDateRange((d) => {
      if (
        d &&
        isEqual(d?.start ?? new Date(), fromDate) &&
        isEqual(d?.end ?? new Date(), toDate)
      ) {
        return d;
      } else {
        return {
          start: fromDate,
          end: toDate,
        } as DateRange;
      }
    });
  });

  const onRangeChange = (ev: any) => {
    if (!chart.isReady()) {
      return;
    }
    onChange();
    update(ev);
  };

  try {
    dateAxis.events.on("startchanged", onRangeChange);
    dateAxis.events.on("endchanged", onRangeChange);
  } catch (e) {}
}
