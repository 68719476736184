/** @jsxImportSource @emotion/react */
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import { max as maxDate, min as minDate, isEqual } from "date-fns";
import { constant, Endomorphism } from "fp-ts/lib/function";
import { Button, Grid } from "semantic-ui-react";
import { debounce } from "throttle-debounce";
import {
  UIStatus,
  UIStatusWrapper,
  DATE_TIME_FORMAT,
  TextInput,
  DateInput,
  formatDateAsString,
  isValidDate,
  LabelledInput,
  InfoMessage,
} from "components/shared";
import styles from "./Chart.module.css";
import { AGG_LIMIT_CAP, DEFAULT_AGG_RESULTS_LIMIT } from "./PointDataUtils";
import {
  addZoomChangeListener,
  ChartData,
  DATE_RANGE_CONTROL_WIDTHS,
  DateRange,
  Datum,
  setSeries,
} from "./ChartUtils";
import tw from "twin.macro";
import { API_CALL_DEBOUNCE_MS } from "data/validation";

const am4chartsDchTheme = (target: unknown) => {
  if (target instanceof am4core.Scrollbar) {
    target.background.fill = am4core.color("white");
  }
  if (target instanceof am4core.ColorSet) {
    target.list = [am4core.color("#AC4DBB")];
  }
};

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_dark);
am4core.useTheme(am4chartsDchTheme);

export type ChartProps<a> = {
  id: string;
  className?: string;
  data?: (f: (now: Date) => Endomorphism<Array<Datum<a>>>) => ChartData<a>;
  header?: string;
  getDataForRange?: (
    dataLimit: number,
  ) => (start?: Date, end?: Date) => Promise<ChartData<a>>;
  hasDataLimit?: boolean;
  min?: Date;
  max?: Date;
  showExportMenu?: boolean;
  filename?: string;
  mountNodeId?: string;
  setDebouncedLimit?: Dispatch<SetStateAction<number>>;
  setParentDateRange?: Dispatch<SetStateAction<DateRange | undefined>>;
};

// Auto-disposing charts
// https://www.amcharts.com/docs/v4/tutorials/chart-was-not-disposed/#Auto_disposing_charts
am4core.options.autoDispose = true;

export function Chart<a>({
  id,
  max,
  getDataForRange,
  // true if the response of getDataForRange might not be the full
  // date in the period
  hasDataLimit,
  showExportMenu,
  filename,
  data,
  header,
  className,
  mountNodeId,
  setDebouncedLimit,
  setParentDateRange,
}: ChartProps<a>): JSX.Element {
  const chart = React.useRef<am4charts.XYChart | null>(null);
  const idRef = React.useRef<string>(id);
  const [uiStatus, setUiStatus] = React.useState<UIStatus>(new UIStatus());
  const [dateRange, setDateRange] = React.useState<DateRange | undefined>();

  const [dataLimit, setDataLimit] = useState<number>(DEFAULT_AGG_RESULTS_LIMIT);
  const [includeStartDate, setIncludeStartDate] = useState<boolean>(true);
  const [endDateInput, setEndDateInput] = useState<Date>();
  const [originalDateRange, setOriginalDateRange] = React.useState<
    DateRange | undefined
  >();
  // update parent daterange everytime dateRange is set
  useEffect(() => {
    setParentDateRange && setParentDateRange(dateRange);
  }, [dateRange, setParentDateRange]);

  useEffect(() => {
    const debounced = debounce(API_CALL_DEBOUNCE_MS, (value: number) => {
      setDebouncedLimit &&
        value <= AGG_LIMIT_CAP &&
        setDebouncedLimit(value ?? 0);
      setDataLimit(value); // Propagate the debounced value to the parent component
    });

    // Call debounce function whenever limit changes
    dataLimit > 0 && debounced(dataLimit);

    // Cleanup function to cancel debounce if limit changes before timeout
    return () => {
      dataLimit > 0 && debounced.cancel();
    };
  }, [dataLimit, setDataLimit, setDebouncedLimit]); // Re-run effect whenever limit or setLimit changes
  // flag to avoid unnecessary reload when setting the date range to the data range received from
  // backend

  // on each endDate input change, update the current dateRange
  // we dont want to directly manipulate the dateRange as we want input to be tracked as a separate dependency
  useEffect(() => {
    if (endDateInput)
      setDateRange((p) => ({
        start: p?.start ?? undefined,
        end: endDateInput,
      }));
  }, [endDateInput]);

  React.useEffect(() => {
    if (max) {
      setDateRange((p) => {
        if (p?.end && max && isEqual(p?.end, max)) {
          return p;
        }

        return {
          end: max,
        } as DateRange;
      });

      setEndDateInput((p) => {
        if (p && max && isEqual(p, max)) {
          return p;
        }
        return max;
      });
    }
  }, [max]);

  React.useEffect(() => {
    if (originalDateRange) {
      setDateRange(originalDateRange);
    }
  }, [originalDateRange]);

  React.useEffect(
    () => {
      const amChart = am4core.create(idRef.current, am4charts.XYChart);
      chart.current = amChart;

      // Add an export menu for image and data exports
      if (showExportMenu) {
        chart.current.exporting.menu = new am4core.ExportMenu();
        if (filename) {
          chart.current.exporting.filePrefix = filename;
        }
        chart.current.exporting.adapter.add("data", function () {
          let d: { data: Array<any> } = { data: [] };
          if (chart.current !== null) {
            chart.current.series.each(function (series) {
              for (let ii = 0; ii < series.data.length; ii++) {
                series.data[ii].name = series.name;
                d.data.push(series.data[ii]);
              }
            });
          }
          return d;
        });
      }

      let dateAxis = chart.current.xAxes.push(new am4charts.DateAxis());
      dateAxis.title.text = "Date";
      dateAxis.groupData = false;
      dateAxis.dateFormats.setKey("day", "d MMM");
      dateAxis.dateFormats.setKey("hour", "H:mm");
      dateAxis.tooltipDateFormat = DATE_TIME_FORMAT;

      let yAxes = new am4charts.ValueAxis();
      chart.current.yAxes.push(yAxes);

      // disable autoscaling of the y axis
      chart.current.events.on("ready", () => {
        yAxes.min = yAxes.minZoomed;
        yAxes.max = yAxes.maxZoomed;
      });

      chart.current.cursor = new am4charts.XYCursor();
      chart.current.legend = new am4charts.Legend();
      chart.current.zoomOutButton.disabled = true;

      if (getDataForRange) {
        setUiStatus((prevState) => prevState.setIndeterminate(true));
        getDataForRange(dataLimit)(
          includeStartDate ? dateRange?.start : undefined,
          dateRange?.end,
        ).then(
          (d) => {
            setUiStatus((prevState) => prevState.setIndeterminate(false));
            if (chart.current) {
              if (d[0]?.metadata) {
                const startTime = new Date(d[0].metadata?.startTime);
                const endTime = new Date(d[0].metadata?.endTime);
                setDateRange(
                  (p) =>
                    ({
                      end: endTime,
                      start: startTime,
                    }) as DateRange,
                );

                if (!originalDateRange) {
                  setOriginalDateRange({
                    start: startTime,
                    end: endTime,
                  });
                }
              }

              if (hasDataLimit) {
                const dates = d.flatMap(({ data }) => data.map(({ x }) => x));
                const from = minDate(dates);
                const to = maxDate(dates);

                if (
                  (dateRange?.start &&
                    !isEqual(dateRange.start ?? new Date(), from)) ||
                  (dateRange?.end && !isEqual(dateRange.end, to))
                ) {
                  setDateRange({
                    start: from,
                    end: to,
                  });
                }
              }

              setSeries(d)(chart.current, false, true);
              addZoomChangeListener(
                chart.current,
                chart.current.xAxes.getIndex(0) as am4charts.DateAxis,
                () => {
                  setIncludeStartDate(true);
                },
                setDateRange,
              );
            }
          },
          (e) => {
            setUiStatus((prevState) =>
              prevState.setError(
                e.error || e.message || "Could not load chart data",
              ),
            );
          },
        );
      } else if (data) {
        const chartData = data(constant((l: Array<Datum<a>>) => l));
        setSeries(chartData)(chart.current, true, true);
      }
    },

    // excluding "skipNextDataRangeReload" to skip the reload on setting date range with the
    // actual date range received
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      data,
      showExportMenu,
      filename,
      hasDataLimit,
      getDataForRange,
      endDateInput,
    ],
  );

  const isZoomed =
    originalDateRange &&
    (dateRange?.start !== originalDateRange.start ||
      dateRange?.end !== originalDateRange.end);

  const resetZoom = () => {
    if (isZoomed) setDateRange(originalDateRange);
  };

  return (
    <div css={tw`border border-solid p-4`}>
      <Grid>
        {header != null && (
          <Grid.Row>
            <Grid.Column>
              <b>{header}</b>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column>
            <InfoMessage>
              Request up to {dataLimit} historical observations in time
              occurring prior to the End Date. On first load, the most recent
              observations within the data limit are shown.
            </InfoMessage>
          </Grid.Column>
        </Grid.Row>
        {getDataForRange ? (
          <Grid.Row
            verticalAlign="bottom"
            centered
            className={styles["control-row"]}
          >
            {dateRange?.start && (
              <Grid.Column {...DATE_RANGE_CONTROL_WIDTHS.fromDateTime}>
                <LabelledInput
                  label="First Date"
                  verticalLayout
                  input={<div>{formatDateAsString(dateRange?.start)}</div>}
                />
              </Grid.Column>
            )}

            <Grid.Column {...DATE_RANGE_CONTROL_WIDTHS.toDateTime}>
              <DateInput
                placeholder="End date time"
                label="End Date"
                verticalLayout
                value={
                  dateRange?.end ? formatDateAsString(dateRange.end) : undefined
                }
                onChange={(endDate: string | null) => {
                  if (!endDate) {
                    setDateRange((d) => ({ ...d, end: undefined }));
                    setEndDateInput(undefined);
                    setIncludeStartDate(false);
                  }
                  if (endDate && isValidDate(endDate)) {
                    const newEnd = new Date(endDate);
                    setDateRange((d) => {
                      return {
                        start: d?.start ?? undefined,
                        end: newEnd,
                      };
                    });
                    setEndDateInput(newEnd);
                    setIncludeStartDate(false);
                  }
                }}
                mountNodeId={mountNodeId}
              />
            </Grid.Column>
            <Grid.Column {...DATE_RANGE_CONTROL_WIDTHS.limitInput}>
              <TextInput
                label="Data Limit"
                verticalLayout
                required
                type="number"
                inputValidation={
                  dataLimit
                    ? {
                        invalid: dataLimit > AGG_LIMIT_CAP,
                        errorMessage: `Max data limit of ${AGG_LIMIT_CAP} is exceeded`,
                      }
                    : undefined
                }
                value={dataLimit > 0 ? dataLimit?.toString() : ""}
                onChange={(_, { value }) => {
                  let n = Number(value);
                  setDataLimit(n);
                }}
              />
            </Grid.Column>
          </Grid.Row>
        ) : undefined}

        <Grid.Row>
          <Grid.Column>
            <UIStatusWrapper
              status={uiStatus}
              loadingDataMsg="Loading chart data"
              clearable
            >
              <div id={idRef.current} className={className} />
              {isZoomed && (
                <div
                  style={{ position: "absolute", top: "1rem", right: "6rem" }}
                >
                  <Button
                    circular
                    basic
                    inverted
                    icon="zoom-out"
                    onClick={() => resetZoom()}
                  />
                </div>
              )}
            </UIStatusWrapper>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}
