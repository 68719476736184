/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { Container } from "semantic-ui-react";
import {
  getObservationsCustom,
  getStreamsMinMaxUsingIds,
  SenapsAPI,
  StreamId,
} from "data/senaps";
import { SelectInput, UIStatus, UIStatusWrapper } from "components/shared";
import { Chart } from "components/Data/PointData";
import { gatherAverageResults } from "./GatherResults";
import { metricFormatted } from "./InfoBoxes";
import { ChartData } from "../PointData/ChartUtils";

const GET_OBSERVATIONS_LIMIT = 2000;

export const SearchDetails: React.FC<{
  pattern: RegExp;
  streamIDs: Array<string>;
  vectorLookup: Record<number, string>;
  sensorAPI: SenapsAPI;
}> = (props) => {
  const [dateRange, setDateRange] = useState<{
    min?: Date;
    max?: Date;
  }>({});

  const [selectedMetrics, setMetrics] = React.useState<Array<string>>([
    "missing_percent",
    "outlier_percent",
  ]);

  const [queryStatus, setQueryStatus] = React.useState(new UIStatus());
  const summaryChartId = props.pattern.source;

  const streamIDsStr = JSON.stringify(props.streamIDs);

  /**
   * Effect to get min and max date of the relevant streams, populating into local state
   * observations.
   *
   * @requires props.sensorAPI - Senaps Sensor API connection to gather observations from
   * @requires props.streamIDs - List of Stream IDs to gather observations for
   */
  useEffect(
    () => {
      setQueryStatus((prevState) => prevState.setIndeterminate(true));

      getStreamsMinMaxUsingIds(props.streamIDs as StreamId[])(
        ({ min, max }) => {
          setDateRange({
            min,
            max,
          });
          setQueryStatus((prevState) => prevState.setIndeterminate(false));
        },
        (errorStr) => {
          setQueryStatus((p) => p.setError(errorStr));
        },
      );
    },
    // "streamIDsStr" is to avoid unnecessary reload when comparing raw arrays
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.sensorAPI, streamIDsStr],
  );

  /**
   * Function to generate dropdown of the selected metrics to display in the summary plot.
   *
   * @param queryStatus - UIStatus to show that the metrics are being fetched
   * @param selectedMetrics - Array of the metrics that the user has selected.
   * @param vectorLookup - Mapping of vector indices to metric strings.
   * @param setMetrics - Function to update the array of selected metrics.
   * @returns Semantic UI Form including a Dropdown of selected metrics.
   */
  const generateMetricSelector = (
    queryStatus: UIStatus,
    selectedMetrics: Array<string>,
    vectorLookup: Record<number, string>,
    setMetrics: Function,
  ) => (
    <div css={tw`mb-3`}>
      <SelectInput
        isLoading={queryStatus.indeterminate}
        disabled={queryStatus.indeterminate}
        placeholder={
          queryStatus.indeterminate
            ? "Loading metrics ..."
            : "Select metrics ..."
        }
        noOptionsMessage="No metrics found."
        isMulti={true}
        label="Metric(s)"
        value={selectedMetrics}
        options={Object.entries(vectorLookup).flatMap(([, label]) => {
          const text = Object.keys(metricFormatted).includes(label)
            ? metricFormatted[label]
            : label;

          return {
            text: text,
            value: label,
          };
        })}
        onChange={(value) => {
          setMetrics(value as string[]);
        }}
      />
      {generateChart}
    </div>
  );

  /**
   * Memoised function generate a chart of the regex search results
   *
   * @requires observations - Observations gathered for the requested regex pattern
   * @requires selectedMetrics - Metrics that the user has selected to be visualised
   * @requires props.vectorLookup - Mapping of metric IDs to vector indices
   * @requires summaryChartId - Unique identifier for the chart.
   * @requires props.streamIDs - List of stream IDs found via the regex search to aggregate and plot
   * @returns Chart of the timeseries data from the filtered streams
   */
  const generateChart = React.useMemo(
    () => {
      /**
       * Effect to gather observations of the relevant streams, populating into local state observations.
       *
       * @requires props.sensorAPI - Senaps Sensor API connection to gather observations from
       * @requires props.streamIDs - List of Stream IDs to gather observations for
       */
      const getDataForRange =
        (limit: number) =>
        (start?: Date, end?: Date): Promise<ChartData<number>> => {
          return new Promise((resolve, reject) => {
            getObservationsCustom(
              props.streamIDs as StreamId[],
              start ? start.toISOString() : undefined,
              end ? end.toISOString() : undefined,
              GET_OBSERVATIONS_LIMIT,
            ).then(
              (observations) => {
                const chartData = !observations
                  ? []
                  : selectedMetrics.map((m) => ({
                      data: gatherAverageResults(
                        observations,
                        props.vectorLookup,
                        props.streamIDs,
                        m,
                      ),
                      name: m,
                    }));

                resolve(chartData);
              },
              (e) => reject(e),
            );
          });
        };

      return (
        <Chart
          css={tw`h-400`}
          id={summaryChartId}
          showExportMenu={true}
          filename={props.pattern.source}
          getDataForRange={getDataForRange}
          hasDataLimit={true}
          min={dateRange.min}
          max={dateRange.max}
        />
      );
    },
    // "streamIDsStr" is to avoid unnecessary reload when comparing raw arrays
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dateRange,
      streamIDsStr,
      selectedMetrics,
      props.vectorLookup,
      summaryChartId,
      props.pattern.source,
      props.sensorAPI,
    ],
  );

  return (
    <Container fluid css={tw`p-5`}>
      <UIStatusWrapper status={queryStatus} loadingDataMsg="Loading...">
        {generateMetricSelector(
          queryStatus,
          selectedMetrics,
          props.vectorLookup,
          setMetrics,
        )}
      </UIStatusWrapper>
    </Container>
  );
};
