/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

type AppHeaderData = {
  name: string;
  icon?: string;
  subHeader: string | React.ReactNode;
  actions: string | React.ReactNode;
};

export const Header = ({ name, icon, subHeader, actions }: AppHeaderData) => {
  return (
    <div css={tw`flex gap-4 items-stretch`} data-test-id="application-header">
      <div>
        {icon && (
          <img src={icon} alt="application icon" width="75" height="75" />
        )}
      </div>

      <div css={tw`flex-1 flex flex-col`}>
        <h3 css={tw`mb-0`}>{name}</h3>
        {subHeader}
      </div>
      <div css={tw`flex flex-col gap-2 items-end justify-between`}>
        {actions}
      </div>
    </div>
  );
};
