import React from "react";
import {
  Controller,
  FieldValues,
  RegisterOptions,
  useFormContext,
} from "react-hook-form";
import { TextInput, TextInputProps } from "components/shared";
import { FormInputProps } from "../ReactHookForm";
import { ASYNC_ERROR_SUFFIX } from "../useAsyncValidate";

export type FormTextInputProps<TFieldValues extends FieldValues> =
  FormInputProps<TFieldValues> & TextInputProps;

export const FormTextInput = <TFieldValues extends FieldValues>(
  props: FormTextInputProps<TFieldValues>,
) => {
  let rules = props.rules as Omit<
    RegisterOptions<FieldValues>,
    "setValueAs" | "disabled" | "valueAsNumber" | "valueAsDate"
  >;
  if (props.required) rules = { ...rules, required: "Required" };
  if (props.maxLength) rules = { ...rules, maxLength: props.maxLength };

  const { control, trigger, getFieldState } = useFormContext();

  return (
    <Controller<FieldValues, any>
      name={props.name}
      control={control}
      rules={rules}
      defaultValue={
        props.defaultValue ?? ("" as TFieldValues[keyof TFieldValues])
      }
      render={({ field, fieldState }) => {
        const formProps = {
          ...field,
          onChange: (event: any) => {
            // controller doesnt have valueAsNumber functionality - see thread https://github.com/orgs/react-hook-form/discussions/8068
            // hence, we do conditional on change
            field.onChange(
              props.type === "number" ? Number(event.target.value) : event,
            );
            if (
              props.validateOnChange &&
              !fieldState.isTouched &&
              fieldState.isDirty
            )
              trigger(props.name);
          },
        };

        const asyncFieldState = getFieldState(
          `${props.name}${ASYNC_ERROR_SUFFIX}`,
        );

        const invalidFieldState =
          ((props.validateOnChange && fieldState.isDirty) ||
            fieldState.isTouched) &&
          (!!fieldState.error || !!asyncFieldState.error);

        return (
          <TextInput
            {...props}
            formProps={formProps}
            inputValidation={
              rules
                ? {
                    invalid: invalidFieldState,
                    errorMessage:
                      fieldState.error?.message ??
                      (asyncFieldState.error?.message as string),
                  }
                : undefined
            }
            testId={props.name}
          />
        );
      }}
    />
  );
};
