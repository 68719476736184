/** @jsxImportSource @emotion/react */
import React, { useCallback, useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { AuthorityContext, EnodiaOrgContext } from "App";
import {
  createCompositeId,
  Form,
  FormStateValues,
  Page,
  PageActions,
  PageTitle,
  redirectMessage,
  UIStatus,
  UIStatusWrapper,
} from "components/shared";
import {
  aletheiaApi,
  DataPoolCompositeId,
  DataPoolId,
  DataPoolResponse,
  DataPoolUpdateRequest,
} from "data/Aletheia";
import { CreateOrEditDataPoolFormValues, FormMode } from "./common";
import { ADMIN_AUTHORITY_ID } from "../helpers";
import { DataPoolFormDetails } from "./DataPoolFormDetails";
import { AdminFormDetails } from "./AdminFormDetails";
import { Path } from "Routes";
import OrgContextModal from "context/OrgContextModal";

type Params = { orgId: string; id: string };

export const EditDataPool = () => {
  const { state } = useLocation();
  const { orgId: contextOrgId } = useContext(EnodiaOrgContext);
  const { assignedAuthorityId } = useContext(AuthorityContext);
  const navigate = useNavigate();
  const params = useParams<Params>();

  const [isOrgContextModalOpen, setIsOrgContextModalOpen] =
    React.useState(false);
  const [status, setStatus] = useState<UIStatus>(new UIStatus());
  const [formState, setFormState] =
    useState<FormStateValues<CreateOrEditDataPoolFormValues>>();

  const [dataPool, setDataPool] = useState<DataPoolResponse>();

  const fetchDataPool = useCallback(() => {
    if (params.orgId && params.id) {
      setStatus((prev) => prev.setIndeterminate(true));

      aletheiaApi
        .getDataPoolById(
          createCompositeId(params.orgId, params.id) as DataPoolCompositeId,
        )
        .then((dp) => {
          setDataPool(dp);
        })

        .then(() => setStatus((prev) => prev.setSuccessful(true)))
        .catch((e) => setStatus((p) => p.setError(e.message)));
    }
  }, [params.id, params.orgId]);

  useEffect(() => {
    if (state) setDataPool(state as DataPoolResponse);
    else {
      fetchDataPool();
    }
  }, [fetchDataPool, state]);

  const onSubmit = (formValues: any) => {
    const request: DataPoolUpdateRequest = {
      name: formValues.name ? formValues.name.trim() : undefined,
      description: formValues.description,
      license: formValues.licenseURL,
      timeseriesImmutable: formValues.timeseriesImmutable,
      // below fields doesnt render in FE for platform user, but it is required field for payload
      timeseriesTrackingWriteDto: {
        backingResourceId: formValues.backingResourceId,
        backingResourceType: formValues.backingResourceType,
      },
    };

    setStatus((p) => p.setIndeterminate(true));

    if (params.orgId && params.id) {
      aletheiaApi
        .putDatapool(
          createCompositeId(
            params.orgId,
            params.id as DataPoolId,
          ) as DataPoolCompositeId,
        )(request)
        .then((res) => {
          setStatus((p) => p.setSuccessful(true));
          navigate(
            generatePath(Path.ViewDataPool, {
              orgId: params.orgId as string,
              id: params.id as string,
            }),
          );
        })
        .catch((e) => setStatus((p) => p.setError(e.message)));
    }
  };

  return (
    <Page>
      <PageTitle primaryHeader="Data Pools" pageAction={PageActions.Edit} />
      <UIStatusWrapper status={status}>
        <OrgContextModal
          open={isOrgContextModalOpen}
          openCondition={params.orgId !== contextOrgId}
          onConfirm={() => {
            navigate(Path.DataPools);
          }}
          modalMessage={redirectMessage}
          setModalState={setIsOrgContextModalOpen}
        />
        {dataPool && (
          <div css={tw`rounded bg-core-grey-dark p-4`}>
            <Form
              onSubmit={onSubmit}
              formId="EditDataPool"
              setFormState={setFormState}
              defaultValues={{
                id: dataPool.id as string,
                name: dataPool.name,
                description: dataPool.description,
                licenseURL: dataPool.license,
                timeseriesImmutable: dataPool.timeseriesImmutable,
                backingResourceType:
                  dataPool.timeseriesTracking.backingResourceType,
                backingResourceId:
                  dataPool.timeseriesTracking.backingResourceId,
              }}
            >
              <DataPoolFormDetails formMode={FormMode.edit} />
              {assignedAuthorityId &&
                assignedAuthorityId === ADMIN_AUTHORITY_ID && (
                  <AdminFormDetails formMode={FormMode.edit} />
                )}

              <Form.Button
                loading={status.indeterminate}
                disabled={
                  status.indeterminate ||
                  !formState?.isValid ||
                  (formState?.isValid && !formState?.isDirty)
                }
                label="Update Data Pool"
                submit={true}
              />

              <Form.Button
                submit={false}
                onClick={() =>
                  navigate(`/datapool/${params.orgId}/${params.id}`)
                }
                label={"Cancel"}
              />
            </Form>
          </div>
        )}
      </UIStatusWrapper>
    </Page>
  );
};
