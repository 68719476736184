/** @jsxImportSource @emotion/react */
import React from "react";
import { Tooltip } from "react-tooltip";
import { Icon } from "semantic-ui-react";
import tw from "twin.macro";

/* NOTES */
//by default, label is a string presented in bold, unless provided as a ReactNode

export class LabelProps {
  label?: string | React.ReactNode;
  description?: string | React.ReactNode;
  required?: boolean;
  isReadOnly?: boolean;
  helperText?: string;
}

export const InputLabel = function (props: LabelProps) {
  const { label, description, required, isReadOnly, helperText } = props;

  if (!label) return <React.Fragment />;
  return (
    <React.Fragment>
      {typeof label === "string" ? <strong>{label}</strong> : label}
      {required && !isReadOnly && (
        <span css={tw`text-red-error whitespace-nowrap`}> *</span>
      )}
      {helperText && (
        <>
          {" "}
          <Icon
            data-tooltip-id={`helper-text-tooltip`}
            data-tooltip-content={helperText}
            name="question circle"
          />
          <Tooltip
            id="helper-text-tooltip"
            css={tw`max-w-80 truncate z-5 whitespace-pre-wrap`}
          />
        </>
      )}
      {description && (
        <div>
          {typeof description === "string" ? (
            <small css={tw`italic text-core-grey`}>{description}</small>
          ) : (
            description
          )}
        </div>
      )}
    </React.Fragment>
  );
};
